<template>
  <div :style="getBodyStyle">
    <TableLayout
      :layoutParams="getLaytouParams"
      :hideHeader="true"
      :hideFooter="true"
    >
      <template #tableHeader="{ updateHeader }">
        <TableHeader
          ref="tableHeader"
          pageTitle=""
          :multiRemoveStatus="false"
          :updateHeader="updateHeader"
        >
        </TableHeader>
      </template>
      <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
      <template #tableBody="{ tableHeight }">
        <div v-if="isDirector && IS_OBAYASHI && NOT_TREE_PAST">
          <v-btn
            depressed
            class="ma-2"
            color="primary"
            @click="isShowPopupSetPlanManager=true"
            :disabled="selectedItems.length === 0"
          >
            編集
          </v-btn>
        </div>
        <v-data-table
          :headers="TABLE_LABELS"
          :items="items"
          v-model="selectedItems"
          :items-per-page="itemsPerPage"
          :height="isDirector && IS_OBAYASHI && NOT_TREE_PAST?tableHeight-52:tableHeight"
          fixed-header
          hide-default-footer
          disable-sort
          :show-select="isDirector && IS_OBAYASHI && NOT_TREE_PAST"
          class="elevation-0 v-data-table__wrapper"
          sort-by="updatedAt"
          :noDataText="NO_DATA_MESSAGE"
          @click:row="openItemForm"
        >
          <template v-slot:[`item.type`]="{ item }">
            {{ convertType(item.type) }}
          </template>
          <template v-slot:[`item.icon`]="{ item }">
            <button @click.stop="confirmDowload(item, PORTAL_DOCUMENT_TYPE.EXCEL)">
              <MarkExcel class="mr-4"
              />
            </button>
            <button @click.stop="confirmDowload(item, PORTAL_DOCUMENT_TYPE.PDF)">
              <MarkPdf
              />
            </button>
          </template>
        </v-data-table>
      </template>
     
    </TableLayout>
    <!-- 現場作業員情報 -->
    <Popup :dialog="popups.isShowItemForm">
      <FieldWorkerInfoForm
        :checkFieldTreeDate="checkFieldTreeDate"
        :dataDetail="dataDetail"
        :state_handling_flag="state_handling_flag"
        :isNewItem="isNewItem"
        :IS_OBAYASHI="IS_OBAYASHI"
        @cancel="closeItemForm"
        @resetForm="$emit('resetForm')"
      />
    </Popup>
    <Popup width="480px" :dialog="isShowOptionLanguageForm">
      <OptionLanguage
        @close="isShowOptionLanguageForm = false"
        @submit="setDataDowload"
      />
    </Popup>
    <Popup width="400px" :dialog="isShowPopupSetPlanManager">
      <SetPlanManager
        :flagError="flagError"
        :isManager="isManager"
        @close="closeFormSetPlanManager"
        @submit="updatePlanManager"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Popup from "@/components/common/Popup";
import FieldWorkerInfoForm from "./FieldWorkerInfoForm/index.vue";
import OptionLanguage from "./components/OptionLanguage.vue";
import SetPlanManager from "./components/SetPlanManager.vue";

import { 
  FORM_WORKERS_TABLE_LABELS, 
  PORTAL_DOCUMENT_TYPE,
  PORTAL_ENTRANCE_QUESTIONAIRE,
  STATE_HANDLING_FLAG,
  OPTION_LANGUAGE,
  FOREIGNER_FLAG,
  } from "@/constants/PORTAL_CHART";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import MarkExcel from "../../components/MarkExcel.vue";
import MarkPdf from "../../components/MarkPdf.vue";
import { format } from "date-fns";
//テーブルヘッダーラベル
const URL = `${process.env.VUE_APP_API_BASE_URL}`;
const DOWNLOAD_URL = `${URL}/reports/entrance_questionnaire`;
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  data() {
    return {
      /**
       * (共通)
       * 一覧データ
       */
      items: [],
      itemsPerPage: 0,
      TABLE_LABELS: FORM_WORKERS_TABLE_LABELS,
      NO_DATA_MESSAGE,
      popups: {
        isShowItemForm: false,
      },
      isNewItem: false,
      PORTAL_DOCUMENT_TYPE,
      FORM_WORKERS_TABLE_LABELS,
      dataDetail: null,
      isShowOptionLanguageForm: false,
      isShowPopupSetPlanManager: false,
      OPTION_LANGUAGE,
      FOREIGNER_FLAG,
      paramsDowload: {},
      isDirector: false,
      selectedItems: [],
      flagError: false,
    };
  },

  components: {
    //共通のコンポーネ
    TableLayout,
    TableHeader,
    Popup,
    FieldWorkerInfoForm,
    MarkExcel,
    MarkPdf,
    OptionLanguage,
    SetPlanManager,
  },

  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    item: Object,
    chartId: Number,
    checkFieldTreeDate: Boolean,
    state_handling_flag: Number,
    fieldContructionId: Number,
    fieldTreeDate: String,
  },

  async mounted() {
    this.$watch(
      () => this.mainHeight,
      (newVal) => {
        console.log("meinheight", newVal);
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => this.checkFieldTreeDate,
      (newVal) => {
        if(newVal) {
          const table = [...FORM_WORKERS_TABLE_LABELS];
          table.splice(-1);
          this.TABLE_LABELS = table;
        }
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => this.state_handling_flag,
      (newVal) => {
        if(newVal == STATE_HANDLING_FLAG.FINISHED) {
          const table = [...FORM_WORKERS_TABLE_LABELS];
          table.splice(-1);
          this.TABLE_LABELS = table;
        }
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => this.item,
      (data) => {
        let items = data?.field_tree_users;
        items?.forEach((item) => {
          item["name"] = item["name_sei"] + " " + item["name_mei"];
          item["notification"] = this.convertNotifications(item);
          let admission_time = item["site_admission_time"] ? item["site_admission_time"] : "";
          let exit_time = item["site_exit_time"] ? item["site_exit_time"] : "";
          item["timeout"] = (admission_time || exit_time) ? admission_time + "～" + exit_time : "";
        });
        this.itemsPerPage = items?.length;
        this.items = items || [];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters["File/getData"], Store.getters["File/getTypeDoc"]],
      (data) => {
        if (data && data[0] && data[1]) {
          const date = new Date();
          const url = window.URL.createObjectURL(new Blob([data[0]]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              data[1] == PORTAL_DOCUMENT_TYPE.PDF
                ? PORTAL_ENTRANCE_QUESTIONAIRE.title_eng
                : PORTAL_ENTRANCE_QUESTIONAIRE.title_ja
            }_${format(date, "yyyyMMddHHmmss")}.${
              data[1] == PORTAL_DOCUMENT_TYPE.PDF ? "pdf" : "xlsx"
            }`
          );
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Employees/getIsDirector"],
      (data) => {
        this.isDirector = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    getLaytouParams() {
      return { pageHeight: this.mainHeight };
    },
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    NOT_TREE_PAST() {
      return format(new Date(this.fieldTreeDate), "yyyy/MM/dd") === format(new Date(), "yyyy/MM/dd");
    },
    isManager() {
      let _isManager = 0;
      let listSelectedUser = [];
      for (let index = 0; index < this.selectedItems.length; index++) {
        let id = this.selectedItems[index].id;
        let user = this.items.find(item => item.id == id && item.is_manager == 1);
        if(user) listSelectedUser.push(user)
      }

      if(listSelectedUser.length == this.selectedItems.length) _isManager = 1;
      return _isManager;
    }
  },

  methods: {
    convertNotifications(item) {
      let flags = [];
      if (item["site_agent_flag"] == 1) flags.push("現");
      if (item["work_chief_flag"] == 1) flags.push("作");
      if (item["female_worker_flag"] == 1) flags.push("女");
      if (item["age_under_18_flag"] == 1) flags.push("未");
      if (item["lead_engineer_flag"] == 1) flags.push("主");
      if (item["foreman_flag"] == 1) flags.push("職");
      if (item["health_safety_manager_flag"] == 1) flags.push("安");
      if (item["ability_improvement_education_flag"] == 1) flags.push("能");
      if (item["risk_recurrence_prevention_education_flag"] == 1) flags.push("再");
      if (item["foreigner_technical_intern_flag"] == 1) flags.push("習");
      if (item["foreigner_worker_flag"] == 1) flags.push("就");
      if (item["foreigner_best_specific_skill_flag"] == 1) flags.push("1特");
      return flags.join("、");
    },
    async openItemForm(item) {
      this.isNewItem = false;
      this.popups.isShowItemForm = true;
      if(this.checkFieldTreeDate) {
        this.dataDetail = item;
      } else {
        const field_tree_user_id = item.id;
        await Store.dispatch("PortalChart/getDetailUserPartner", field_tree_user_id);
      }
    },

    /**
     * close 現場作業員情報
     */
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        // TODO
      });
    },
    confirmDowload(item, typeDoc) {
      this.paramsDowload.user_id = item.user_id;
      this.paramsDowload.typeDoc = typeDoc;
      this.paramsDowload.language = this.OPTION_LANGUAGE[0].id;
      // if user is foreigner show popup choose language
      if (item.foreigner_flag == FOREIGNER_FLAG.IS_FOREIGNER) {
        this.isShowOptionLanguageForm = true;
      // else dowload
      } else {
        this.downloadDocument();
      }
    },
    setDataDowload(language) {
      this.isShowOptionLanguageForm = false;
      this.paramsDowload.language = language;
      this.downloadDocument();
    },
    /**
     * Call api set users is plan manager
     */
    async updatePlanManager(value) {
      let user_ids = this.selectedItems.map(e => e.user_id);
      let params = {
        field_construction_id: this.fieldContructionId,
        user_ids,
        is_manager: value
      };
      const result = await Store.dispatch("PortalChart/updatePlanManager", params);
      if (result.hasError) {
        this.flagError = !this.flagError;
      } else {
        this.$emit('resetForm');
        this.closeFormSetPlanManager();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
      }
    },
    closeFormSetPlanManager() {
      this.isShowPopupSetPlanManager = false;
      this.selectedItems = [];
    },
    async downloadDocument() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, 1000);

      const params = {
        work_date: format(new Date(), "yyyy-MM-dd"),
        field_tree_id: this.chartId,
        file_code: this.paramsDowload.typeDoc,
        user_id: this.paramsDowload.user_id,
        language: this.paramsDowload.language
      };
      const configParams = { 
        url: DOWNLOAD_URL,
        params: params
      }
      await Store.dispatch("File/download", configParams);
    },
  },
};
</script>