const SITE_INITIAL_ITEM = {
  entries: {
    company_fields: {
      company_id: null,
      company_branch_id: null,
    },
    fields: {
      name: null,
      postal_code: null,
      field_prefectures_id: null,
      ccus_site_id: null,
      city: null,
      address: null,
      northern_boundary_latitude: null,
      southern_boundary_latitude: null,
      western_boundary_longitude: null,
      eastern_boundary_longitude: null,
      center_latitude: null,
      center_longitude: null,
      field_map_file: null,
      groundbreaking_date: null,
      complete_schedule_date: null,
      contract_due_date: null,
      new_admission_education_implementation_report: null,
      order_party_name: null,
      order_party_postal_code: null,
      order_party_field_prefectures_id: null,
      order_party_city: null,
      order_party_address: null,
      order_supervisor_name: null,
      order_supervisor_authority_and_request_method: null,
      supervisor_name: null,
      authority_and_request_method: null,
      site_agent_name: null,
      site_agent_authority_and_request_method: null,
      chief_engineer_category: null,
      chief_engineer_exam_id: null,
      supervising_engineer_category: null,
      supervising_engineer_exam_id: null,
      chief_engineer_name: null,
      chief_engineer_fulltime_flag: 1,
      supervising_engineer_assistant_name: null,
      chairman_name: null,
      vice_chairman_company_name: null,
      vice_chairman_name: null,
      health_safety_manager_name: null,
      secretary_name: null,
      document_submission_address: null,
      document_person_in_charge_name: null,
      foreigner_worker_specific_skill_status: 1,
      foreigner_worker_status: 1,
      foreigner_worker_intern_status: 1,
      field_map_file_url: null,
      new_admission_education_implementation_report_url: null,
    },
    field_technicians: [
      {
        technician_name: null,
        exam_id: null,
        exam_category: null,
        work_details: null,
      },
    ],
    fileld_approvals: [
      {
        id: null,
        type: null,
        count: null,
        level: null,
      }
    ],
  },
};

/**
 * (共通)
 * タイトル
 */
const FORM_TITLE = "現場情報";
const SITE_FORM_TABS = {
  SiteFormPage1: { id: 1, title: "基本情報" },
  SafetyRulesForm: { id: 2, title: "安全ルール" },
  SiteFormPage2: { id: 3, title: "工事請負契約情報" },
  SiteFormPage3: { id: 4, title: "監理技術者・主任技術者" },
  SiteFormPage4: { id: 5, title: "外国人の従事の状況" },
  SiteFormPage5: { id: 6, title: "作業場所" },
  SiteFormPage6: { id: 7, title: "図面" },
};

const MASTER_FORM_TABS = {
  SiteFormPage1: { id: 1, title: "基本情報" },
  SiteFormPage2: { id: 3, title: "工事請負契約情報" },
  SiteFormPage3: { id: 4, title: "監理技術者・主任技術者" },
  SiteFormPage4: { id: 5, title: "外国人の従事の状況" },
};

const DASHBOARD_TABS = {
  SiteFormPage1: { id: 1, title: "基本情報" },
  SiteApprovalAuthoritPage : { id : 2 , title : "承認権限"},
  SiteEntranceManagementPage : { id : 3 , title : "入退場管理"},
  SafetyRulesForm: { id: 4, title: "安全ルール" },
  SiteFormPage5: { id: 6, title: "作業場所" },
  SiteFormPage6: { id: 7, title: "図面" },
  SiteFormPage7: { id: 8, title: "建機レンタル会社"},
  SiteFormPage8: { id: 9, title: "現場資料"},
};

const SITE_TABLE_LABELS = [
  {
    text: "支店",
    align: "left",
    sortable: true,
    value: "company_name",
  },
  {
    text: "現場",
    value: "field_name",
    align: "left",
    sortable: false,
  },
  {
    text: "住所",
    value: "full_address",
    align: "left",
    sortable: false,
  },
  {
    text: "請負",
    value: "nest_no",
    align: "left",
    sortable: false,
  },
  {
    text: "担当工事",
    value: "construction_details",
    align: "left",
    sortable: false,
  },
  {
    text: "工期",
    value: "build_date",
    align: "left",
    sortable: true,
  },
];
const SITE_SORT_ITEMS = [
  {
    id: "field_name",
    name: "現場名",
  },
  {
    id: "company_branch_name",
    name: "支店名",
  },
  {
    id: "nest_no",
    name: "請負",
  },
  {
    id: "date_field",
    name: "工期",
  },
];
const CHIEF_ENGINEER_FULLTIME_FLAG = [
  { id: 1, name: "専任", value: 1 },
  { id: 2, name: "非専任", value: 2 },
];
const FOREIGN_WORKER_STATUS_ITEMS = [
  { id: 1, name: "無", value: 1 },
  { id: 2, name: "有", value: 2 },
];
const FOREIGN_WORKER_SPECIFIC_SKILL_STATUS_ITEMS = [
  { id: 1, name: "無", value: 1 },
  { id: 2, name: "有", value: 2 },
];
const FOREIGN_WORKER_INTERN_STATUS_ITEMS = [
  { id: 1, name: "無", value: 1 },
  { id: 2, name: "有", value: 2 },
];

const FIELDS_TITLE = "fields";
const COMPANY_FIELDS_TITLE = "company_fields";

const MINS_ON_HOUR = ["00", "15", "30", "45"];
const getProhibitTimes = (isAll, isEnd) => {
  let prohibitTimes = [];
  let i = 0;
  while (i < 24) {
    for (let m of MINS_ON_HOUR) {
      if (isEnd && i === 0 && m === "00") {
        continue;
      }
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: hour + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd || isAll) {
    prohibitTimes.push({ id: "24:00", name: "24:00" });
  }
  return prohibitTimes;
};
const getTimes = () => {
  let times = [];
  let i = 0;
  while (i < 24) {
    let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":00",
        name: hour + ":00",
      };
      times.push(element);
    i++;
  }
  return times;
};

const PROHIBIT_TIME_START = getProhibitTimes(false, false);
const PROHIBIT_TIME_END = getProhibitTimes(false, true);
const PROHIBIT_TIMES = getProhibitTimes(false, false);
const TIME_HOUR = getTimes();

const createWorkTime = () => {
  let result = [];
  for (let i = 0.25; i <= 24; i += 0.25) {
    result.push({ id: Number(i).toFixed(2), name: (i < 10)? `0${Number(i).toFixed(2)}` : `${Number(i).toFixed(2)}`});
  }
  return result;
};

const WORKING_TIME_ARRAY = createWorkTime();

const createEnterDayTime = () => {
  let result = [];
  let i = 0;
  while (i < 12) {
    for (let m of MINS_ON_HOUR) {
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: hour + ":" + m,
      };
      result.push(element);
    }
    i++;
  }
  result.push({ id: "12:00", name: "12:00" });
  return result;
};
const ENTER_DAY_TIME_ARRAY = createEnterDayTime();
const ENTRANCE_STATUS = [
  { name: "入場用", value: 0 },
  { name: "退場用", value: 1 },
  { name: "手動シャッター", value: 2 },
];

const ARR_NUMBERS_OF_STAMPS = [
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "3", value: 3 },
  { name: "4", value: 4 },
  { name: "5", value: 5 },
];

const ARR_LEVEL = [
  { name: "A", value: 1 },
  { name: "B", value: 2 },
  { name: "C", value: 3 },
  { name: "D", value: 4 },
];

const FILELD_APPROVALS = [
  { id: null, type: "所長", count: 5, level: 1 },
  { id: null, type: "副所長", count: 5, level: 2 },
  { id: null, type: "工事長", count: 5, level: 3 },
  { id: null, type: "主任・係員", count: 5, level: 4 },
];

export {
  FORM_TITLE,
  SITE_TABLE_LABELS,
  SITE_SORT_ITEMS,
  SITE_INITIAL_ITEM,
  CHIEF_ENGINEER_FULLTIME_FLAG,
  FOREIGN_WORKER_STATUS_ITEMS,
  FOREIGN_WORKER_SPECIFIC_SKILL_STATUS_ITEMS,
  FOREIGN_WORKER_INTERN_STATUS_ITEMS,
  SITE_FORM_TABS,
  FIELDS_TITLE,
  COMPANY_FIELDS_TITLE,
  PROHIBIT_TIME_START,
  PROHIBIT_TIME_END,
  PROHIBIT_TIMES,
  WORKING_TIME_ARRAY,
  ENTER_DAY_TIME_ARRAY,
  DASHBOARD_TABS,
  MASTER_FORM_TABS,
  TIME_HOUR,
  ENTRANCE_STATUS,
  ARR_NUMBERS_OF_STAMPS,
  ARR_LEVEL,
  FILELD_APPROVALS
};
