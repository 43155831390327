import { render, staticRenderFns } from "./FormWorkNavi.vue?vue&type=template&id=5c7e1fb6&scoped=true&"
import script from "./FormWorkNavi.vue?vue&type=script&lang=js&"
export * from "./FormWorkNavi.vue?vue&type=script&lang=js&"
import style0 from "./FormWorkNavi.vue?vue&type=style&index=0&id=5c7e1fb6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c7e1fb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VIcon,VSpacer,VTab,VTabs,VTabsSlider,VToolbarTitle})
