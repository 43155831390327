<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col>
                      <!-- 姓 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="姓" :editable="editable" required>
                            <InputText
                              name="name_sei"
                              :values="userValue"
                              :editable="editable"
                              placeholder="山田"
                              validation_label="姓"
                              validation_rules="required|max:64"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <!-- 名 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="名" :editable="editable" required>
                            <InputText
                              name="name_mei"
                              :values="userValue"
                              :editable="editable"
                              placeholder="太郎"
                              validation_label="名"
                              validation_rules="required|max:64"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- セイ -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="セイ" :editable="editable" required>
                            <InputText
                              name="kana_sei"
                              :values="userValue"
                              :editable="editable"
                              placeholder="ヤマダ"
                              validation_label="セイ"
                              validation_rules="required|max:128|kana"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <!-- メイ -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="メイ" :editable="editable" required>
                            <InputText
                              name="kana_mei"
                              :values="userValue"
                              :editable="editable"
                              placeholder="タロウ"
                              validation_label="メイ"
                              validation_rules="required|max:128|kana"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- CCUS技能者ID -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="CCUS技能者ID" :editable="editable">
                            <InputText
                              name="ccus_user_id"
                              :values="userValue"
                              :editable="editable"
                              placeholder="00001234567890"
                              validation_label="CCUS技能者ID"
                              validation_rules="max:20|digit"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!-- 本人確認番号 -->
                    <v-col v-if="isObayashi">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="確認番号" :editable="editable">
                            <InputText
                              name="confirm_code"
                              :values="userValue"
                              :editable="editable"
                              placeholder=""
                              validation_label="確認番号"
                              validation_rules="digit"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                          <span v-if="editable">CCUS技能者カード裏面</span
                        >
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 管理者権限 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="管理者権限" :editable="editable">
                            <v-switch
                              v-model="roleName"
                              :label="userRoleValue['role_name']"
                              @change="
                                onInput(USER_EVENT_TARGET.USER_ROLES, {
                                  name: 'role_name',
                                  value: roleName,
                                })
                              "
                              :disabled="!editable"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <!-- メールアドレス -->
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="メールアドレス" :editable="editable" :required="roleName">
                            <InputText
                              name="email"
                              :values="userValue"
                              :editable="editable"
                              placeholder="example@example.com"
                              validation_label="メールアドレス"
                              :validation_rules="emailRule"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <!-- 生年月日 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="生年月日" :editable="editable">
                            <InputDatepicker
                              name="birthday"
                              :values="userValue"
                              :editable="editable"
                              placeholder="yyyy/mm/dd"
                              :max="max"
                              :flagNull="true"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 性別 -->
                          <Label label="性別" :editable="editable">
                            <Select
                              name="cmn_mst_gender_id"
                              :values="userValue"
                              :items="EMPLOYEE_GENDER"
                              item_text="gender"
                              :editable="editable"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- 郵便番号 -->
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="郵便番号" :editable="editable">
                            <InputText
                              name="postal_code"
                              :values="userValue"
                              :editable="editable"
                              placeholder="001-0010"
                              validation_label="郵便番号"
                              validation_rules="postcode"
                              @onInput="onChangePostalCode"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <!-- 都道府県 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="都道府県" :editable="editable">
                            <Select
                              name="cmn_mst_prefecture_id"
                              :values="userValue"
                              :items="PREFECTURES"
                              item_text="prefecture"
                              :editable="editable"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <!-- 市区町村 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="市区町村" :editable="editable">
                            <InputText
                              name="city"
                              :values="userValue"
                              :editable="editable"
                              placeholder="千代田区"
                              validation_label="市区町村"
                              validation_rules="max:255"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <!-- 番地・建物名 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="番地・建物名" :editable="editable">
                            <InputText
                              name="address"
                              :values="userValue"
                              :editable="editable"
                              placeholder="番地・建物名"
                              validation_label="番地・建物名"
                              validation_rules="max:255"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <!-- 電話番号 -->
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="電話番号" :editable="editable">
                            <InputText
                              name="tel"
                              :values="userValue"
                              :editable="editable"
                              placeholder="03-1234-5678"
                              validation_label="電話番号"
                              validation_rules="phone"
                              @onInput="onInput(USER_EVENT_TARGET.USERS, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import InputDatepicker from "../elements/InputDatepicker.vue";
import Select from "../elements/Select.vue";
import { ROLE, USER_EVENT_TARGET } from "@/constants/EMPLOYEE";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
import { kuroshiro } from "@/utils/convertKana";
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";



export default {
  data: () => {
    return {
      formValues: {},
      EMPLOYEE_GENDER: [{ id: null, gender: "" },...Store.state.CmnMst.constants.entries.cmn_mst_gender],
      PREFECTURES: [{ id: null, prefecture: "" },...Store.state.CmnMst.constants.entries.cmn_mst_prefectures],
      ROLE,
      USER_EVENT_TARGET,
      roleName: false,
      userValue: {},
      userRoleValue: {},
      max: new Date().toISOString().slice(0, 10)
    };
  },
  components: {
    Label,
    InputText,
    InputDatepicker,
    Select,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        const formValues = { ...newValue };
        this.formValues = { ...formValues };
        this.userValue = { ...formValues[USER_EVENT_TARGET.USERS] };
        this.userRoleValue = { ...formValues["user_roles"] };
        if (this.userRoleValue.role_name == ROLE.MANAGER) {
          this.roleName = true;
        } else {
          this.roleName = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    emailRule(){
      return `email|max:255${this.roleName ? '|required':''}`
    },
    isObayashi() {
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    onInput(parents_name, { name, value }) {
      const formValues = { ...this.formValues };
      if (name == "name_sei" || name == "name_mei") {
        this.getNameKana(name,value.trim());
      }

      if (parents_name == "user_roles") {
        let role = { ...this.roleName };
        role = value;
        this.roleName = role;
        formValues["user_roles"]["role_name"] = role
          ? ROLE.MANAGER
          : ROLE.GENERAL;
        formValues["user_roles"]["code"] = role ? 2 : 1;
      } else {
        formValues[parents_name][name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getNameKana(name,value) {
      let result = await kuroshiro.convert(value, {
        mode: "normal",
        to: "katakana",
      });
      const formValues = { ...this.formValues };
      if ( name == "name_sei"){
        formValues["users"]["kana_sei"] = result;
      } else{
        formValues["users"]["kana_mei"] = result;
      }
      this.$emit("formUpdate", formValues);
    },

    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalCode({ value }) {
      const formValues = { ...this.formValues };
      formValues.users.postal_code = value;
      if (value == "") {
        formValues.users.cmn_mst_prefecture_id = "";
        formValues.users.city = "";
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if (result) {
          formValues.users.cmn_mst_prefecture_id = result.prefecture.id;
          formValues.users.city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
