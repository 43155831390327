import Vue from "vue";
import Vuex from "vuex";
import { scheduleGates } from "../../../api/modules/scheduleGates";
import { DEVICE_EMPTY } from "@/constants/SCHEDULE_GATE";

Vue.use(Vuex);

/**
 * apiを指定
 * 共通のapiは、Store.ScheduleWorksを参照する
 */
const ENTITY = scheduleGates;

export const ScheduleGates = {
  namespaced: true,

  state: {
    data: {},
    pagination: {},
    details: {},
    listGatePassageFieldTreeUserHistory: [],
    listGatePassageManagerHistory: [],
    listGatePassageHistory: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CHILD_DATA(state, payload) {
      const parent = state.data.devices.find(
        (item) => item.device_id == Number(payload.entries[0].device_id)
      );
      const children = [...parent.children, ...payload.entries];
      const data = { ...state.data };
      const devices = data.devices.map((item) => {
        if (item.device_id == Number(payload.entries[0].device_id)) {
          const _item = { ...item };
          _item.children = children;
          return _item;
        }
        return item;
      });
      data.devices = devices;
      state.data = data;
    },
    SET_SCHEDULE_GATE(state, payload) {
      state.details = payload;
    },
    SET_LIST_GATE_PASSAGE_FIELD_TREE_USER_HISTORY(state, payload) {
      state.listGatePassageFieldTreeUserHistory = payload;
    },
    SET_LIST_GATE_PASSAGE_MANAGER_HISTORY(state, payload) {
      state.listGatePassageManagerHistory = payload;
    },
    SET_LIST_GATE_PASSAGE_HISTORY(state, payload) {
      state.listGatePassageHistory = payload;
    },
    RESET_DATA(state) {
      state.data = {}
      state.pagination = {}
    }
  },

  actions: {
    //機材予定一覧
    async get({ commit }, payload) {
      const response = await ENTITY.getGates(payload);
      if (response.hasError) {
        return response;
      }
      let { entries, pagination } = response.data.contents;
      if(!entries.devices) {
        entries = {devices: DEVICE_EMPTY, ...entries}
      }
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async loadChildren({ commit }, payload) {
      const response = await ENTITY.getScheduleGatesChildren(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        device_id: payload.device_id,
      });
      return response;
    },
    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      const entries =  response.data.contents.entries;
      commit("SET_SCHEDULE_GATE", entries);
      return response
    },
    // 承認
    async approve(context, payload) {
      const response = await ENTITY.postScheduleGatesApprove(payload);
      return response;
    },

    //承認解除
    async reject(context, payload) {
      const response = await ENTITY.postScheduleGatesReject(payload);
      return response;
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async getGatePassageLastTime(_context, payload) {
      return await ENTITY.getGatePassageLastTime(payload);
    },
    async getListGatePassageFieldTreeUserHistory({ commit }, payload) {
      const response = await ENTITY.getListGatePassageFieldTreeUserHistory(payload);
      const entries =  response.data.contents.entries;
      commit("SET_LIST_GATE_PASSAGE_FIELD_TREE_USER_HISTORY", entries);
      return response;
    },
    async getListGatePassageManagerHistory({ commit }, payload) {
      const response = await ENTITY.getListGatePassageManagerHistory(payload);
      const entries =  response.data.contents.entries;
      commit("SET_LIST_GATE_PASSAGE_MANAGER_HISTORY", entries);
      return response;
    },
    async getListGatePassageHistorys({ commit }, payload) {
      const response = await ENTITY.getListGatePassageHistorys(payload);
      const entries =  response.data.contents.entries;
      commit("SET_LIST_GATE_PASSAGE_HISTORY", entries);
      return response;
    },
    resetData({ commit }) {
      commit("RESET_DATA");
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getDetail : (state) => {
      return state.details;
    },
    getListGatePassageFieldTreeUserHistory : (state) => {
      return state.listGatePassageFieldTreeUserHistory;
    },
    getListGatePassageManagerHistory: (state) => {
      return state.listGatePassageManagerHistory;
    },
    getListGatePassageHistorys: (state) => {
      return state.listGatePassageHistory;
    },
  },
};
