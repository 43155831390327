import Vue from "vue";
import Vuex from "vuex";
import { scheduleCrane } from "../../../api/modules/scheduleCrane";
import { DEVICE_EMPTY } from "@/constants/SCHEDULE_CRANE";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = scheduleCrane;

export const ScheduleCrane = {
  namespaced: true,

  state: {
    data: {},
    pagination: {},
    partnerCompany: [],
    foreman: [],
    workPlace: [],
    workContent: [],
    persons: [],
    listGroup: [],
    liftingScheduleDetail: {},
    workingTimeZone: [],
    crane: [],
    listCraneByField: [],
    workHoursZone: null,
    listCraneUserHistory: [],
    listCraneManagerHistory: [],
    listCraneUseHistory: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_PARTNER_COMPANY(state, payload) {
      state.partnerCompany = payload;
    },
    SET_FOREMAN(state, payload) {
      state.foreman = payload;
    },
    SET_WORK_PLACE(state, payload) {
      state.workPlace = payload;
    },
    SET_WORK_CONTENT(state, payload) {
      state.workContent = payload;
    },
    SET_PERSON(state, payload) {
      state.persons = payload;
    },
    SET_LIFTING_SCHEDULE_DETAIL(state, payload) {
      state.liftingScheduleDetail = payload;
    },
    SET_LIST_GROUP(state, payload) {
      state.listGroup = payload;
    },
    SET_WORKING_TIME_ZONE(state, payload) {
      state.workingTimeZone = payload;
    },
    SET_CRANE(state, payload) {
      state.crane = payload;
    },
    SET_CHILD_DATA(state, payload) {
      const parent = state.data.devices.find(
        (item) => item.device_id === payload.device_id
      );
      const children = [...parent.children, ...payload.entries];
      const data = { ...state.data };
      const devices = data.devices.map((item) => {
        if (item.device_id === payload.device_id) {
          const _item = { ...item };
          _item.children = children;
          return _item;
        }
        return item;
      });
      data.devices = devices;
      state.data = data;
    },
    SET_LIST_CRANE_BY_FIELD(state, payload) {
      state.listCraneByField = payload;
    },
    SET_WORK_HOURS_ZONE(state, payload) {
      state.workHoursZone = payload;
    },
    SET_LIST_CRANE_USER_HISTORY(state, payload) {
      state.listCraneUserHistory = payload;
    },
    SET_LIST_CRANE_MANAGER_HISTORY(state, payload) {
      state.listCraneManagerHistory = payload;
    },
    SET_LIST_CRANE_USE_HISTORY(state, payload) {
      state.listCraneUseHistory = payload;
    },
    RESET_DATA(state) {
      state.data = {}
      state.pagination = {}
    }
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.getCranes(payload);
      if (response.hasError) {
        return response;
      }
      let { entries, pagination } = response.data.contents;
      if(!entries.devices) {
        entries = {devices: DEVICE_EMPTY, ...entries}
      }
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async loadChildren({ commit }, payload) {
      const response = await ENTITY.getScheduleCranesChildren(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        device_id: payload['crane_id'],
      });
      return response;
    },
    // 承認
    async approve(context, payload) {
      const response = await ENTITY.postScheduleCranesApprove(payload);
      return response;
    },

    //承認解除
    async disapprove(context, payload) {
      const response = await ENTITY.postScheduleCranesReject(payload);
      return response;
    },
    async getCrane({ commit }, payload) {
      const response = await ENTITY.getCrane(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      entries.unshift({ id: null, name: "" });
      commit("SET_CRANE", entries);
      return response;
    },
    async getListCraneByField({ commit }, payload) {
      const response = await ENTITY.getListCraneByField(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_CRANE_BY_FIELD", entries);
      return response;
    },
    async getWorkingTimeZone({ commit }, payload) {
      const response = await ENTITY.getWorkingTimeZone(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      entries.unshift({ id: null, name: "" });
      commit("SET_WORKING_TIME_ZONE", entries);
      return response;
    },
    async getScheduleCraneDetail({ commit }, payload) {
      const response = await ENTITY.getScheduleCraneDetail(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIFTING_SCHEDULE_DETAIL", entries);
      return response;
    },
    async getListGroup({ commit }, payload) {
      const response = await ENTITY.getListGroup(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      entries.unshift({ id: null, name: "" });
      commit("SET_LIST_GROUP", entries);
      return response;
    },

    setWorkHouseZone({ commit }, payload) {
      commit("SET_WORK_HOURS_ZONE", payload);
    },

    async getPerson({ commit }, payload) {
      const response = await ENTITY.getPerson(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      entries.unshift({ id: null, name: "" });
      commit("SET_PERSON", entries);
      return response;
    },
    async getWorkContent({ commit }, payload) {
      const response = await ENTITY.getWorkContent(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_WORK_CONTENT", entries);
      return response;
    },
    async getPartnerCompany({ commit }, payload) {
      const response = await ENTITY.getPartnerCompany(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      entries.unshift({ id: null, name: "" });
      commit("SET_PARTNER_COMPANY", entries);
      return response;
    },
    async getForeMan({ commit }, payload) {
      const response = await ENTITY.getForeMan(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      entries.forEach(item => item['name'] = `${item['user_name_sei']} ${item['user_name_mei']}`)
      entries.unshift({ id: null, name: "" });
      commit("SET_FOREMAN", entries);
      return response;
    },
    async getWorkPlace({ commit }, payload) {
      const response = await ENTITY.getWorkPlace(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      entries.unshift({ id: null, name: "" });
      commit("SET_WORK_PLACE", entries);
      return response;
    },
    
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async getCraneUseLastTime(_context, payload) {
      return await ENTITY.getCraneUseLastTime(payload);
    },

    async getListCranUseHistory({ commit }, payload) {
      const response = await ENTITY.getListCranUseHistory(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_CRANE_USE_HISTORY", entries);
      return response;
    },
    async getListCraneUserHistory({ commit }, payload) {
      const response = await ENTITY.getListCraneUserHistory(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_CRANE_USER_HISTORY", entries);
      return response;
    },

    async getListCraneUseManagerHistory({ commit }, payload) {
      const response = await ENTITY.getListCraneUseManagerHistory(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_LIST_CRANE_MANAGER_HISTORY", entries);
      return response;
    },

    clearDataWorkHourZone({ commit }) {
      commit("SET_WORK_HOURS_ZONE", null);
    },
    resetData({ commit }) {
      commit("RESET_DATA");
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getPartnerCompany: (state) => {
      return state.partnerCompany;
    },
    getForeMan: (state) => {
      return state.foreman;
    },
    getScheduleCraneDetail: (state) => {
      return state.liftingScheduleDetail;
    },
    getListGroup: (state) => {
      return state.listGroup;
    },
    getWorkingTimeZone: (state) => {
      return state.workingTimeZone;
    },
    getCrane: (state) => {
      return state.crane;
    },
    getListCraneByField: (state) => {
      return state.listCraneByField;
    },
    getWorkHouseZone: (state) => {
      return state.workHoursZone;
    },
    getListCranUseHistory: (state) => {
      return state.listCraneUseHistory;
    },
    getListCraneUserHistory: (state) => {
      return state.listCraneUserHistory;
    },
    getListCraneUseManagerHistory: (state) => {
      return state.listCraneManagerHistory;
    },
  },
};

