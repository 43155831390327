// テーブル設定
export const SAFETY_TABLE_LABELS = [
  {
    text: "帳票名",
    value: "report_name_jp",
    align: "left",
  },
  {
    text: "出力頻度",
    value: "type",
    align: "left",
  },
  {
    text: "",
    value: "icon",
    align: "right",
  },
];

export const LIST_TYPE_EXPORT = [
  { id: 1, name_pdf: "All_Adjust_Work_and_Safety", name_excel: "作業間連絡調整及び安全衛生指示書", name: "Grn_All_Adjust_Work_and_Safety", url: "/all_adjust_work_and_safety" },
  { id: 2, name_pdf: "All_Result_Work", name_excel: "作業実施記録", name: "Grn_All_Result_Work", url: "/all_result_work" },
  { id: 3, name_pdf: "Diary", name_excel: "安全衛生日誌", name: "Grn_Diary", url: "/diary" },
  { id: 4, name_pdf: "Deignation_Safety", name_excel: "安全指摘事項", name: "Grn_Deignation_Safety", url: "/deignation_safety" },
  { id: 5, name_pdf: "ATKY", name_excel: "ATKY", name: "Grn_ATKY", url: "/atky" },
  { id: 6, name_pdf: "All_Hot_Work_Manager", name_excel: "火気使用届", name: "Grn_All_Hot_Work_Manager", url: "/all_hot_work_manager" },
  { id: 7, name_pdf: "Early_Time_Work", name_excel: "早出一覧", name: "Grn_Early_Time_Work", url: "/early_time_work" },
  { id: 8, name_pdf: "Over_Time_Work", name_excel: "残業一覧", name: "Grn_Over_Time_Work", url: "/over_time_work" },
  { id: 9, name_pdf: "Work_Total", name_excel: "作業人員集計", name: "Grn_Work_Total", url: "/work_total" },
  { id: 10, name_pdf: "All_Work_Detailed_Statement_Payment", name_excel: "出面及支払内訳書", name: "Grn_All_Work_Detailed_Statement_Payment", url: "/all_work_detailed_statement_payment" },
  { id: 11, name_pdf: "Earlytime_Notify", name_excel: "早出届", name: "Grn_Earlytime_Notify", url: "/earlytime_notify" },
  { id: 12, name_pdf: "Overtime_Notify", name_excel: "残業届", name: "Grn_Overtime_Notify", url: "/overtime_notify" },
  { id: 13, name_pdf: "Crane_Use_Daily", name_excel: "日次揚重予定一覧表", name: "Grn_Crane_Use_Daily", url: "/crane_use_daily" },
  { id: 14, name_pdf: "Gate_Passage_Daily", name_excel: "日次ゲート予定一覧", name: "Grn_Gate_Passage_Daily", url: "/gate_passage_daily" },
  { id: 15, name_pdf: "Hot_Work", name_excel: "火気使用届・許可・終了確認書（一覧）", name: "Grn_Hot_Work", url: "/hot_work" },
];

export const ARR_GATE_CRANE_REPORT = [
  'Grn_Crane_Use_Daily',
  'Grn_Gate_Passage_Daily',
  'Grn_Overtime_Notify',
  'Grn_Earlytime_Notify',
  'Grn_Over_Time_Work',
  'Grn_Early_Time_Work',
  'Grn_All_Hot_Work_Manager',
  'Grn_ATKY',
  'Grn_Deignation_Safety',
  'Grn_Diary',
  'Grn_All_Result_Work',
  'Grn_All_Adjust_Work_and_Safety',
  'Grn_Hot_Work',
];

export const USER_ROLE = {
  OWNERCOMPANY: { id: 1 },
  PARTNERCOMPANY: { id: 2 }
};

import { format } from "date-fns";

export const REPORT_INITIAL_ITEM = {
  work_date: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
  to_date: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
  field_construction_id: null,
  field_tree_group_id: null,
  field_tree_id: null,
  work_hour_zone: null,
  gate_ids: null,
  cranes_ids: null,
};

export const TEXT_LABEL = {
  DAILY: { id: 1, text: "日次" },
  MONTHLY: { id: 1, text: "月次" }
};

export const SAFETY_DOCUMENT_TYPE = {
  EXCEL: 1,
  PDF: 2,
};
