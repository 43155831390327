<template>
  <div ref="self">
    <!-- PCメニュー -->
    <v-toolbar v-if="!$sp" flat>
      <v-toolbar-title class="worksHeader-title primary--text" v-if="isTitle">
        {{ pageTitle }}
      </v-toolbar-title>
      <!-- 現場選択ダイアログ -->
      <div class="ml-5" v-if="!isOperational">
        <v-btn
          class="ma-2"
          outlined
          color="success"
          @click="beforeDay()"
        >
        &lt; 前日
        </v-btn>
      </div>
      <div class="inputDatepicker mt-5">
        <InputDatepicker
          name="date"
          :editable="true"
          :values="searchParams"
          validation_label="日付（終了)"
          :validation_rules="getStartDateRules"
          @onInput="onChangeSearchParams"
        />
        <div class="button-group" v-if="isCustomCalendar">
          <v-btn
            class="ma-3 prev"
            outlined
            color="success"
            @click="setDateByType(typeButtonThree)"
          >
            <v-icon color="primary"> mdi-chevron-left</v-icon>
            <span class="mg-c">前日</span>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            @click="setDateByType(typeButtonOne)"
          >
            <span class="ct-b">今日</span>
          </v-btn>
          <v-btn
            class="ma-3 next"
            outlined
            color="success"
            @click="setDateByType(typeButtonTwo)"
          >
            <span class="mg-c">翌日</span>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            class="ma-3 prev"
            outlined
            color="success"
            @click="setDateByType(typeButtonSix)"
          >
            <v-icon color="primary">mdi-chevron-left</v-icon>
            <span class="mg-c">前週</span>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            @click="setDateByType(typeButtonFour)"
          >
            <span class="ct-b">今週</span>
          </v-btn>
          <v-btn
            class="ma-3 next"
            outlined
            color="success"
            @click="setDateByType(typeButtonFive)"
          >
            <span class="mg-c">翌週</span>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            class="ma-3 prev"
            outlined
            color="success"
            @click="setDateByType(typeButtonNine)"
          >
            <v-icon color="primary">mdi-chevron-left</v-icon>
            <span class="mg-c">前月</span>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            @click="setDateByType(typeButtonSeven)"
          >
            <span class="ct-b">今月</span>
          </v-btn>
          <v-btn
            class="ma-3 next"
            outlined
            color="success"
            @click="setDateByType(typeButtonEight)"
          >
            <span class="mg-c">翌月</span>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="ml-5" v-if="!isOperational">
        <v-btn
          class="ma-2"
          outlined
          color="success"
          @click="nextDay()"
        >
        翌日 &gt;
        </v-btn>
      </div>
      <div class="textBold ml-5" v-if="isOperational">～</div>
      <div class="inputDatepicker ml-5 mt-5" v-if="isOperational" style="min-width: 150px">
        <InputDatepicker
          name="end_date"
          :editable="true"
          :values="searchParams"
          validation_label="日付（開始)"
          :validation_rules="getEndDateRules"
          @onInput="onChangeSearchParams"
        />
        <div class="button-group" v-if="isCustomCalendar">
          <v-btn
            class="ma-3 prev"
            outlined
            color="success"
            @click="setDateByType(typeButtonThree)"
          >
            <v-icon color="primary"> mdi-chevron-left</v-icon>
            <span class="mg-c">前日</span>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            @click="setDateByType(typeButtonOne)"
          >
            <span class="ct-b">今日</span>
          </v-btn>
          <v-btn
            class="ma-3 next"
            outlined
            color="success"
            @click="setDateByType(typeButtonTwo)"
          >
            <span class="mg-c">翌日</span>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            class="ma-3 prev"
            outlined
            color="success"
            @click="setDateByType(typeButtonSix)"
          >
            <v-icon color="primary">mdi-chevron-left</v-icon>
            <span class="mg-c">前週</span>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            @click="setDateByType(typeButtonFour)"
          >
            <span class="ct-b">今週</span>
          </v-btn>
          <v-btn
            class="ma-3 next"
            outlined
            color="success"
            @click="setDateByType(typeButtonFive)"
          >
            <span class="mg-c">翌週</span>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            class="ma-3 prev"
            outlined
            color="success"
            @click="setDateByType(typeButtonNine)"
          >
            <v-icon color="primary">mdi-chevron-left</v-icon>
            <span class="mg-c">前月</span>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            @click="setDateByType(typeButtonSeven)"
          >
            <span class="ct-b">今月</span>
          </v-btn>
          <v-btn
            class="ma-3 next"
            outlined
            color="success"
            @click="setDateByType(typeButtonEight)"
          >
            <span class="mg-c">翌月</span>
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-btn
        @click="$emit('onOpenChart')"
        v-if="isShowGraphButton"
        class="ma-2"
        outlined
        color="primary"
        :disabled="isOperational && !checkStartEndDate"
      >
        全人工表示
      </v-btn>
      <v-spacer></v-spacer>
      <Setting
        style="margin-right: 9px;"
        v-if="isShowSettingPlanManager"
        @click.native="$emit('showSettingPlan')"
      />
      <!-- search history -->
      <DropdownSearchHistory
        v-if="isObayashiAndContractorsMain"
        class="mx-2 mt-2"
        :disableBtnCreateSearchHistory="disableBtnCreateSearchHistory"
        :listSearchHistory="listSearchHistory"
        @openPopupCreateSearchHistory="$emit('openPopupCreateSearchHistory')"
        @seachByItemHistory="seachByItemHistory"
        @deleteItemsSeachHistory="(val) => $emit('deleteItemsSeachHistory', val)"
       />
      <v-btn class="mx-2" small icon @click="openSearch">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        v-if="isDelete && !isTableTypeAll"
        class="mx-2"
        small
        icon
        @click="$emit('openRemoveDialog')"
        :disabled="multiSelectStatus"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
      <div>
        <v-btn
          v-if="isDirector && !isTableTypeAll"
          class="ma-2"
          depressed
          color="warning"
          :disabled="multiSelectStatus"
          @click="$emit('onReject')"
        >
          承認解除
        </v-btn>
        <v-btn
          v-if="isDirector && !isTableTypeAll"
          depressed
          class="ma-2"
          color="primary"
          :disabled="multiSelectStatus"
          @click="$emit('onApprove')"
        >
          承認
        </v-btn>
      </div>

      <v-btn
        v-if="isCopyRecord"
        depressed
        class="ma-2"
        color="primary"
        :disabled="multiSelectStatus"
        @click="$emit('onCopyRecord')"
      >
        複製
      </v-btn>

      <v-btn
        v-if="!isCompany && !isTableTypeAll && !isShowTypeAdd"
        depressed
        class="ma-2"
        color="primary"
        @click="$emit('openItemForm')"
        :disabled="isOperational && !checkStartEndDate"
      >
        新規作成
      </v-btn>
      <div v-if="!isCompany && !isTableTypeAll && isShowTypeAdd">
        <DropdownList
          label="新規作成"
          :menu="TYPE_ADD"
          @typeAdd="openNewItemByType"
        />
      </div>
    </v-toolbar>

    <!-- SPメニュー -->
    <v-bottom-navigation class="footer" v-if="$sp">
      <v-btn text color="primary" @click="openSearch">
        <span class="label">絞り込み</span>
        <v-icon color="primary">mdi-magnify</v-icon>
      </v-btn>

      <v-btn
        v-if="isDelete && !isTableTypeAll"
        text
        color="primary"
        :disabled="multiSelectStatus"
        @click="$emit('openRemoveDialog')"
      >
        <span class="label">削除</span>
        <v-icon color="primary">mdi-delete</v-icon>
      </v-btn>

      <v-btn
        v-if="isDirector && !isTableTypeAll"
        text
        color="primary"
        :disabled="multiSelectStatus"
        @click="$emit('onApprove')"
      >
        <span class="label">承認</span>
        <v-icon color="primary">mdi-check-all</v-icon>
      </v-btn>

      <v-btn
        v-if="isDirector && !isTableTypeAll"
        text
        color="warning"
        class="footer__btn"
        :class="multiSelectStatus ? 'enable' : ''"
        :disabled="multiSelectStatus"
        @click="$emit('onReject')"
      >
        <span class="label">承認解除</span>
        <v-icon class="footer__btn-icon" color="warning">$removeCheck</v-icon>
      </v-btn>

      <v-btn
        v-if="!isCompany"
        text
        color="primary"
        @click="$emit('openItemForm')"
      >
        <span class="label">新規作成</span>
        <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <div v-show="isShowSearch">
      <slot :openSearch="openSearch" />
    </div>
  </div>
</template>
<script>
import InputDatepicker from "@/components/forms/elements/InputDatepicker"; //絞り込みフォームで使用
import DropdownList from "./DropdownList.vue";
import DropdownSearchHistory from "./DropdownSearchHistory.vue";
import Setting from "@/components/icon/Setting.vue";
import { Store } from "@/store/Store.js";
import { format } from "date-fns";
import { compareStartEndDate } from "@/utils/timeUtil.js";
import { TYPE_ADD } from "@/constants/SCHEDULE_USAGE";

export default {
  data: () => {
    return {
      isShowSearch: false,
      TYPE_ADD,
      typeButtonOne : 1,
      typeButtonTwo : 2,
      typeButtonThree: 3,
      typeButtonFour: 4,
      typeButtonFive: 5,
      typeButtonSix: 6,
      typeButtonSeven: 7,
      typeButtonEight: 8,
      typeButtonNine: 9,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    multiSelectStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    // タイトルを表示
    isTitle: {
      type: Boolean,
      default: true,
    },
    //検索パラメータ
    searchParams: {
      type: Object,
      defauly: null,
    },
    //テーブルが全体かを判定
    isTableTypeAll: {
      type: Boolean,
      default: false,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
    isShowTypeAdd: {
      type: Boolean,
      default: false,
    },
    //全人工ボタン表示
    isShowGraphButton: {
      type: Boolean,
      default: true,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isDirector: {
      type: Boolean,
      default: false,
    },
    isOperational: {
      type : Boolean,
      default : false
    },
    isShowSettingPlanManager: {
      type : Boolean,
      default : false
    },
    isCustomCalendar: {
      type : Boolean,
      default : false
    },
    isObayashiAndContractorsMain: {
      type : Boolean,
      default : false
    },
    disableBtnCreateSearchHistory: {
      type : Boolean,
      default : false
    },
    listSearchHistory: {
      type: Array,
      default: () => []
    },
    isCopyRecord: {
      type : Boolean,
      default : false
    }
  },
  components: {
    InputDatepicker,
    DropdownList,
    DropdownSearchHistory,
    Setting,
  },

  computed: {
    getStartDateRules() {
      return this.searchParams.end_date ? `compare-end-date:${this.searchParams.end_date?.replaceAll("-", "/")}|compare-schedule-end-date:${this.searchParams.end_date?.replaceAll(
        "-",
        "/"
      )}` : "";
    },
    getEndDateRules() {
      return this.searchParams.date ? `compare-start-date:${this.searchParams.date?.replaceAll("-", "/")}|compare-schedule-start-date:${this.searchParams.date?.replaceAll(
        "-",
        "/"
      )}` : "";
    },
    checkStartEndDate() {
      let startDate = new Date(this.searchParams.date?.replaceAll("-", "/"));
      let endDate = new Date(this.searchParams.end_date?.replaceAll("-", "/"));
      return (format(new Date(this.searchParams.date), "yyyy/MM/dd") <= format(new Date(this.searchParams.end_date), "yyyy/MM/dd") && compareStartEndDate(startDate,endDate));
    }
  },

  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);

    //検索フォームの開閉
    this.$watch(
      () => Store.getters["ScheduleSearch/getActive"],
      (value) => {
        this.isShowSearch = value;
        this.$nextTick(() => {
          this.updateHeaderHeight();
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    //検索パラメータを更新
    onChangeSearchParams({ name, value }) {
      let date = value.replaceAll('-', '/');
      this.searchParams.date = this.searchParams.date?.replaceAll("-", "/");
      this.searchParams.end_date = this.searchParams.end_date?.replaceAll("-", "/");
      let start_date = this.searchParams.date?.replaceAll("-", "/");
      let end_date = this.searchParams.end_date?.replaceAll("-", "/");
      if (date > end_date )  {
        this.searchParams.end_date = date ;
      }
      if (date < start_date )  {
        this.searchParams.date = date ;
      }
      this.$emit("onInput", { name, value });
    },
    beforeDay() {
      let date = new Date(this.searchParams.date);
      date.setDate(date.getDate() - 1);
      let value = format(date, "yyyy/MM/dd");
      let name = 'date';
      this.$emit("onInput", { name, value });
    },
    nextDay() {
      let date = new Date(this.searchParams.date);
      date.setDate(date.getDate() + 1);
      let value = format(date, "yyyy/MM/dd");
      let name = 'date';
      this.$emit("onInput", { name, value });
    },
    openSearch() {
      //検索フォームを開く
      Store.dispatch("ScheduleSearch/show", !this.isShowSearch);
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
    setDateByType(type) {
      let startDate;
      let endDate;
      let value;
      let name = 'end_date';
      let starDateSelect = new Date(this.searchParams.date);
      let current = new Date();
      if (type == this.typeButtonOne) {
        let now = format(new Date(Date.now()), "yyyy/MM/dd");
        startDate = now;
        endDate = now;
      }
      if (type == this.typeButtonTwo) {
        let starDateSelect = new Date(this.searchParams.date);
        starDateSelect.setDate(starDateSelect.getDate() + 1);
        startDate = format(new Date(starDateSelect), "yyyy/MM/dd");
        endDate = format(new Date(starDateSelect), "yyyy/MM/dd");
      }
      if (type == this.typeButtonThree) {
        starDateSelect.setDate(starDateSelect.getDate() - 1);
        startDate = format(new Date(starDateSelect), "yyyy/MM/dd");
        endDate = format(new Date(starDateSelect), "yyyy/MM/dd");
      }
      if (type == this.typeButtonFour) {
        startDate = new Date(current.setDate(current.getDate() - current.getDay()));
        endDate = new Date(current.setDate(current.getDate() - current.getDay() + 6));
        startDate = format(startDate, "yyyy-MM-dd");
        endDate = format(endDate, "yyyy-MM-dd");
      }
      if (type == this.typeButtonFive) {
        starDateSelect.setDate(starDateSelect.getDate() + 7);
        startDate = new Date(starDateSelect .setDate(starDateSelect.getDate() - starDateSelect.getDay()));
        endDate = new Date(starDateSelect.setDate(starDateSelect.getDate() - starDateSelect.getDay() + 6));
        startDate = format(startDate, "yyyy-MM-dd");
        endDate = format(endDate, "yyyy-MM-dd");
      }
      if (type == this.typeButtonSix) {
        starDateSelect.setDate(starDateSelect.getDate() - 7);
        startDate = new Date(starDateSelect .setDate(starDateSelect.getDate() - starDateSelect.getDay()));
        endDate = new Date(starDateSelect.setDate(starDateSelect.getDate() - starDateSelect.getDay() + 6));
        startDate = format(startDate, "yyyy-MM-dd");
        endDate = format(endDate, "yyyy-MM-dd");
      }
      if (type == this.typeButtonSeven) {
        startDate = new Date(current.getFullYear(), current.getMonth(), 1);
        endDate = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        startDate = format(startDate, "yyyy-MM-dd");
        endDate = format(endDate, "yyyy-MM-dd");
      }
      if (type == this.typeButtonEight) {
        starDateSelect.setMonth(starDateSelect.getMonth() + 1)
        startDate = new Date(starDateSelect.getFullYear(), starDateSelect.getMonth(), 1);
        endDate = new Date(starDateSelect.getFullYear(), starDateSelect.getMonth() + 1, 0);
        startDate = format(startDate, "yyyy-MM-dd");
        endDate = format(endDate, "yyyy-MM-dd");
      }
      if (type == this.typeButtonNine) {
        starDateSelect.setMonth(starDateSelect.getMonth() - 1)
        startDate = new Date(starDateSelect.getFullYear() , starDateSelect.getMonth(), 1);
        endDate = new Date(starDateSelect.getFullYear() , starDateSelect.getMonth() + 1, 0);
        startDate = format(startDate, "yyyy-MM-dd");
        endDate = format(endDate, "yyyy-MM-dd");
      }
      value = endDate;
      this.searchParams.date = startDate;
      this.searchParams.end_date = endDate;
      this.$emit("onInput", { name, value });
    },
    openNewItemByType (type) {
      this.$emit('openNewItemFormByType',type);
    },
    seachByItemHistory(value) {
      this.isShowSearch = true
      this.$emit('seachByItemHistory', value)
      Store.dispatch("ScheduleSearch/show", this.isShowSearch);
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateDateSelected(value) {
      this.searchParams.date = value.date;
      this.searchParams.end_date = value.end_date;
      this.$emit("onInput", { name: 'date', value: value.date });
      this.$emit("onInput", { name: 'end_date', value: value.end_date });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.v-application--is-ltr .v-btn__content .v-icon--right,
.v-application--is-rtl .v-btn__content .v-icon--left {
  margin-left: 0px;
  margin-right: 0px;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 0 8px;
  box-sizing: border-box;
}

.footer__btn {
  .footer__btn-icon {
    fill: $color_warning;
  }
  &.enable {
    .footer__btn-icon {
      fill: rgba(0, 0, 0, 0.26);
    }
  }
}

.label {
  font-size: 8px;
}

.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.textBold {
  font-size: 24px !important;
  font-weight: bold !important;
}
.button-group button {
  flex: 0 0 90px;
  max-width: 90px;
}
.button-group button.prev, .button-group button.next {
  flex: 0 0 113px;
  max-width: 113px;
}
span.ct-b {
  margin: 0 12px;
}
.mg-c{
  margin: 0 20px;
}
.v-toolbar__content {
  position: relative;
}
.button-group {
  display:none;
}
.button-group {
  flex-wrap: wrap;
  position: absolute;
  width: 25%;
  left: 8%;
  margin-left: 0;
  z-index: 9999999;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
  top: 54px;
  justify-content: space-between;
  border-radius: 8px;
}
header {
  position: relative;
  z-index: 3;
}
.inputDatepicker:hover .button-group{
  display:flex;
}
@media (max-width: 2560px) and (min-width:2450px) {
  .button-group {
    width: 18.5%;
    left: 6%;
  }
}
@media  (max-width:2400px) and (min-width:2122px) {
  .button-group {
    width: 18.5%;
    left: 7.5%;
  }
}
@media (width: 2133px) {
  .button-group {
    width: 18.5%;
    left: 6%;
  }
}
@media  (min-width:2880px) {
  .button-group {
    width: 17%;
    left: 5.5%;
  }
}
@media  (min-width:3000px) {
  .button-group {
    width: 12.5%;
    left: 4%;
  }
}
@media (max-width: 1746px) {
  .button-group {
    width: 27.5%;
    left: 9%;
  }
}
@media (max-width: 1536px) {
  .button-group {
    width: 31.5%;
    left: 10%;
  }
}
@media (max-width: 1280px){
  .button-group {
    width: 37%;
    left: 12%;
  }
}
</style>
