import Api, { Mock } from "../api";
import mock_detail from "./mock/liftingSchedule_detail.json";
import schedule_cranes from "./mock/schedule_cranes.json";
import schedule_cranes_children from "./mock/schedule_cranes_children.json";
import crane_use_last_time from "./mock/crane_use_last_time.json";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

const COMPANY_BRANCHES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/crane_uses`;
const GET_PARTNER_COMPANY_URL = `${COMPANY_BRANCHES_BASE_URL}/partner_company/`;
const GET_FOREMAN_URL = `${COMPANY_BRANCHES_BASE_URL}/foreman/`;
const GET_WORK_PLACE_URL = `${COMPANY_BRANCHES_BASE_URL}/work_place/`;
const GET_WORK_CONTENT_URL = `${COMPANY_BRANCHES_BASE_URL}/work_content/`;
const GET_PERSON_URL = `${COMPANY_BRANCHES_BASE_URL}/person/`;
const GET_LIFTING_SCHEDULE_DETAIL_URL = `${COMPANY_BRANCHES_BASE_URL}/detail`;
const GET_LIST_GROUP_URL = `${COMPANY_BRANCHES_BASE_URL}/list_group/`;
const GET_WORKING_TIME_ZONE_URL = `${COMPANY_BRANCHES_BASE_URL}/working_time_zone/`;
const GET_CRANE_URL = `${COMPANY_BRANCHES_BASE_URL}/get_crane/`;
const CREATE_CRANE_URL = `${COMPANY_BRANCHES_BASE_URL}/create`;
const UPDATE_CRANE_URL = `${COMPANY_BRANCHES_BASE_URL}/update`;
const GET_CRANE_LIST_URL = `${COMPANY_BRANCHES_BASE_URL}/list`;
const GET_CRANES_CHILDREN_SCHEDULE = `${COMPANY_BRANCHES_BASE_URL}/get_crane_use_by_crane`;
const POST_CRANES_APPROVE = `${COMPANY_BRANCHES_BASE_URL}/approve`; //承認
const POST_CRANES_REJECT = `${COMPANY_BRANCHES_BASE_URL}/disapprove`; //承認解除
const DELETE_URL = `${COMPANY_BRANCHES_BASE_URL}/delete`; //承認解除
const GET_LIST_CRANE_BY_FIELD_URL = `${COMPANY_BRANCHES_BASE_URL}/list_crane_by_field`; //承認解除
const GET_CRANE_USE_LASTTIME_URL = `${COMPANY_BRANCHES_BASE_URL}/get_crane_use_last_time`;
const URL_GET_LIST_CRANE_USER_HISTORY = `${COMPANY_BRANCHES_BASE_URL}/list_field_tree_user_history`;
const URL_GET_LIST_CRANE_MANAGER_HISTORY = `${COMPANY_BRANCHES_BASE_URL}/list_manager_history`;
const GET_CRANE_USE_HISTORY_URL = `${COMPANY_BRANCHES_BASE_URL}/list_history`;
// CHECK IS OBAYASHI
const IS_OBAYASHI = process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI;
// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIFTING_SCHEDULE_DETAIL_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_detail,
  });
  Mock.onGet(GET_PERSON_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { id: 1, name: "玉掛者" },
        { id: 2, name: "合図者" },
        { id: 3, name: "元請担当者" },
      ],
    },
  });
  Mock.onGet(GET_WORK_CONTENT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { id: 1, name: "作業内容１" },
        { id: 2, name: "作業内容２" },
        { id: 3, name: "作業内容5" },
      ],
    },
  });
  Mock.onGet(GET_PARTNER_COMPANY_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { id: 1, name: "YASHIMA建設 元請会社１" },
        { id: 2, name: "本店２" },
        { id: 3, name: "本店5" },
      ],
    },
  });
  Mock.onGet(GET_FOREMAN_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          user_name_sei: "田中",
          user_name_mei: "太郎",
          user_experiences: [
            {
              cmn_mst_skills_id: 1,
              experience: 1,
            },
            {
              cmn_mst_skills_id: 2,
              experience: 2,
            },
          ],
        },
        {
          id: 2,
          user_name_sei: "大谷",
          user_name_mei: "慎治",
          user_experiences: [
            {
              cmn_mst_skills_id: 3,
              experience: 3,
            },
            {
              cmn_mst_skills_id: 4,
              experience: 4,
            },
          ],
        },
      ],
    },
  });
  Mock.onGet(GET_WORK_PLACE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { id: 1, name: "作業場所１" },
        { id: 2, name: "作業場所２" },
        { id: 3, name: "作業場所５" },
      ],
    },
  });
  Mock.onGet(GET_WORKING_TIME_ZONE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          name: "昼間",
          start_time: "06:00",
          end_time: "12:00",
        },
        {
          id: 2,
          name: "夜",
          start_time: "12:00",
          end_time: "18:00",
        },
        {
          id: 3,
          name: "深夜",
          start_time: "18:00",
          end_time: "24:00",
        },
      ],
    },
  });
  Mock.onGet(GET_CRANE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { id: 1, name: "揚重機１", company_name: "株式会社１" },
        { id: 2, name: "揚重機２", company_name: "株式会社２" },
        { id: 3, name: "揚重機５", company_name: "株式会社５" },
      ],
    },
  });
  Mock.onGet(GET_LIST_GROUP_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { id: 1, name: "グループ１" },
        { id: 2, name: "グループ２" },
        { id: 3, name: "グループ５" },
      ],
    },
  });
  Mock.onPost(CREATE_CRANE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_CRANE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(GET_CRANE_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_cranes,
  });
  Mock.onGet(GET_CRANES_CHILDREN_SCHEDULE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: schedule_cranes_children,
  });
  Mock.onPost(POST_CRANES_APPROVE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(POST_CRANES_REJECT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_LIST_CRANE_BY_FIELD_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          crane_name: "Aクレーン",
        },
        {
          id: 2,
          crane_name: "Bクレーン",
        },
      ],
    },
  });
  Mock.onGet(new RegExp(`${GET_CRANE_USE_LASTTIME_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: crane_use_last_time,
  });
  Mock.onGet(URL_GET_LIST_CRANE_USER_HISTORY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { field_tree_user_id: 1, field_tree_id: 1, user_name: "株式会社１", field_tree_user_tel: "001989999"},
        { field_tree_user_id: 2, field_tree_id: 2, user_name: "株式会社2", field_tree_user_tel: "001989999"},
        { field_tree_user_id: 3, field_tree_id: 3, user_name: "株式会社3", field_tree_user_tel: "001989999"},
      ],
    },
  });
  Mock.onGet(URL_GET_LIST_CRANE_MANAGER_HISTORY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        { user_id: 1, user_name: "株式会社１"},
        { user_id: 2, user_name: "株式会社 2"},
        { user_id: 3, user_name: "株式会社 3"},
      ],
    },
  });
  Mock.onGet(GET_CRANE_USE_HISTORY_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        "株式会社１",
        "株式会社 2",
        "株式会社 3",
      ],
    },
  });
}

export const scheduleCrane = {
  getCranes: (params) => {
    return Api.get(GET_CRANE_LIST_URL,  params);
  },
  getScheduleCranesChildren: (params) => {
    return Api.get(GET_CRANES_CHILDREN_SCHEDULE, { params });
  },
  postScheduleCranesApprove: (params) => {
    return Api.post(POST_CRANES_APPROVE, params);
  },
  postScheduleCranesReject: (params) => {
    return Api.post(POST_CRANES_REJECT, params);
  },
  post: (params) => {
    return Api.post(CREATE_CRANE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_CRANE_URL,  params );
  },
  getPartnerCompany: (params) => {
    return Api.get(GET_PARTNER_COMPANY_URL, { params });
  },
  getForeMan: (params) => {
    return Api.get(GET_FOREMAN_URL, { params });
  },
  getWorkPlace: (params) => {
    return Api.get(GET_WORK_PLACE_URL, { params });
  },
  getWorkContent: (params) => {
    return Api.get(GET_WORK_CONTENT_URL, { params });
  },
  getPerson: (params) => {
    return Api.get(GET_PERSON_URL, { params });
  },
  getScheduleCraneDetail: (id) => {
    if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
      let params = {
        id,
        is_oba: true
      };
      return Api.get(GET_LIFTING_SCHEDULE_DETAIL_URL, { params });
    } else {
      let params = {id};
      return Api.get(GET_LIFTING_SCHEDULE_DETAIL_URL, { params });
    }
  },
  getListGroup: (params) => {
    return Api.get(GET_LIST_GROUP_URL, { params });
  },
  getWorkingTimeZone: (params) => {
    return Api.get(GET_WORKING_TIME_ZONE_URL, { params });
  },
  getCrane: (params) => {
    return Api.get(GET_CRANE_URL, { params });
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getListCraneByField: async (params) => {
    return Api.get(`${GET_LIST_CRANE_BY_FIELD_URL}/${params}`);
  },
  getCraneUseLastTime: async (id) => {
    if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
      let params = {
        is_oba: true,
        field_id: id
      };
      return Api.get(GET_CRANE_USE_LASTTIME_URL, {params});
    } else {
      let params = {field_id: id};
      return Api.get(GET_CRANE_USE_LASTTIME_URL, {params});
    }
  },
  getListCranUseHistory: (params) => {
    params.params.is_oba = IS_OBAYASHI ? 1 : null;
    return Api.get(GET_CRANE_USE_HISTORY_URL,  params );
  },
  getListCraneUserHistory: async (params) => {
    return Api.get(URL_GET_LIST_CRANE_USER_HISTORY, { params });
  },
  getListCraneUseManagerHistory: async (params) => {
    params.is_oba = IS_OBAYASHI ? 1 : null;
    return Api.get(URL_GET_LIST_CRANE_MANAGER_HISTORY, { params });
  },
};
