<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">作業責任者選択</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList
      placeHolder="氏名"
      @search="searchData"
      @formUpdate="formUpdate"
    >
      <v-list>
        <template v-for="(item, index) in items" v-if="items.length > 0">
          <v-list-item :key="index" ripple>
            <v-list-item-content class="text-break" style="cursor: pointer" @click="onClick(item)">
              {{ item.user_name }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div style="display: flex;justify-content: center" v-if="isNoDataMessage">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "./SearchSelectList.vue";
import { Store } from "@/store/Store.js";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import _ from "lodash";

export default {
  components: {
    SearchSelectList
  },

  props: {
    field_construction_id: Number,
    field_tree_id_1: Number,
    job_id: Number,
    storeName: String
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE,
      items: [],
      isNoDataMessage : false,
    };
  },
  async mounted() {
    await this.getDataItems();

    this.$watch(
      () => Store.getters[`PortalChart/getListUserFromCompanyPartnerLevel1`],
      (data) => {
        if (data) {
          let _data = _.cloneDeep(data);
          _data.forEach(e => {
            if (e.company_name) {
              e.user_name = e.user_name + "（" + e.company_name + "）";
            }
          });
          this.items = [..._data];
          this.isNoDataMessage = this.items?.length == 0;
        }
      },
      {
        deep: true
      }
    );
  },
  methods: {
    formUpdate(values) {
      this.keyword = values.keyword;
    },
    async getDataItems() {
      if (this.field_construction_id && this.field_tree_id_1 && this.job_id) {
        let params = {
          field_construction_id: this.field_construction_id,
          field_tree_id_1: this.field_tree_id_1,
          job_id: this.job_id
        };
        let rs = await Store.dispatch(`${this.storeName}/getListFieldTreeUserHistory`, { params });
        this.items = [...rs.data.contents.entries];
        this.isNoDataMessage = this.items?.length == 0;
      } else {
        this.items = [];
        this.isNoDataMessage = true;
      }
    },
    async searchData() {
      if (this.field_construction_id && this.field_tree_id_1) {
        let params = {
          field_construction_id: this.field_construction_id,
          field_tree_id_1: this.field_tree_id_1,
          state_handling_flag : 0,
          user_name: this.keyword
        };
        await Store.dispatch("PortalChart/getListUserFromCompanyPartnerLevel1", { params });
      } else {
        this.items = [];
        this.isNoDataMessage = true;
      }
    },
    onClick(item) {
      if(item.field_tree_user_id) {
        this.$emit("updateData", item, this.items);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #1B9C4F;
}

.dialog {
  padding-bottom: 20px;

  .header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}
</style>
