<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 日付 -->
                          <Label label="日付" :editable="editable">
                            <InputDatepicker
                              name="admission_date"
                              :values="formValues"
                              :editable="false"
                              :flagNull="true"
                              placeholder="yyyy/mm/dd"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="11" md="11" v-if="isNewItem">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="工事" :editable="editable" required>
                            <Select
                              class="select-position"
                              name="field_construction_id"
                              :values="formValues"
                              :items="FIELD_CONSTRUCTION_ARRAY"
                              :editable="editable"
                              @onInput="onInput"
                              validation_rules="required"
                              validation_label="工事"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="11" md="11" v-if="isNewItem">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="作業グループ"
                            :editable="editable"
                          >
                            <Select
                              class="select-position"
                              name="group_id"
                              :values="formValues"
                              :items="FIELD_TREE_GROUP_ARRAY"
                              item_text="group_name"
                              :editable="editable"
                              @onInput="onInput"
                              validation_label="作業グループ"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="11" md="11">
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label
                            label="協力会社"
                            :editable="isNewItem"
                            required
                          >
                            <Select
                              class="select-position"
                              name="field_tree_id"
                              :values="formValues"
                              :items="COMPANY_PARTNER_ARRAY"
                              item_text="company_name"
                              item_value="field_tree_id"
                              :editable="isNewItem"
                              :label="formValues.field_tree_conpany_name"
                              @onInput="onInput"
                              validation_rules="required"
                              validation_label="協力会社"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" v-if="isNewItem">
                      <Occupation
                        name="user_ids"
                        :values="formValues"
                        :editable="isNewItem"
                        :users="FOREMAN_ARRAY"
                        @onInput="onInput"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="11" md="11" v-if="!isNewItem">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業員">
                            <Select
                              name="user_id"
                              :values="formValues"
                              :items="FOREMAN_ARRAY"
                              :editable="isNewItem"
                              :label="formValues.user_name"
                              @onInput="onInput({ index, value: $event })"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <StudyTime
                        name="field_entrance_times"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                        :enterDayTime="enterDayTime"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select";
import Occupation from "./components/Occupation.vue";
import StudyTime from "./components/StudyTime.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
const STORE = "Sites";

export default {
  data: () => {
    return {
      formValues: {},
      FIELD_CONSTRUCTION_ARRAY: [],
      FIELD_TREE_GROUP_ARRAY: [],
      COMPANY_PARTNER_ARRAY: [],
      FOREMAN_ARRAY: [],
      enterDayTime: "",
    };
  },
  components: {
    Label,
    InputText,
    Select,
    Occupation,
    StudyTime,
    InputDatepicker,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && newValue) {
          this.getEnterDayTime();
          this.getWorkTypes();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getEnterDayTime`],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && newValue) {
          this.enterDayTime = newValue.enter_day_time;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      async (data) => {
        this.FIELD_CONSTRUCTION_ARRAY = [
          this.getDefaultArrays("id", "name"),
          ...data,
        ];
        if (!this.formValues["field_construction_id"]) {
          this.formValues["field_construction_id"] = this.FIELD_CONSTRUCTION_ARRAY[1].id;
          this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY = [];
        }
        await this.getListGroups();
        await this.getCompanyPartners();
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListGroup"],
      (data) => {
        this.FIELD_TREE_GROUP_ARRAY = [
          this.getDefaultArrays("id", "group_name"),
          ...data,
        ];
        if (this.editable) {
          this.COMPANY_PARTNER_ARRAY = [
            this.getDefaultArrays("field_tree_id", "company_name"),
          ];
        } else {
          this.getCompanyPartners();
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartner"],
      (data) => {
        this.COMPANY_PARTNER_ARRAY = [
          this.getDefaultArrays("field_tree_id", "company_name"),
          ...data,
        ];
        if (this.editable) {
          this.FOREMAN_ARRAY = [];
        } else {
          this.getListFieldTree();
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListCompanyPartnerUser"],
      (data) => {
        const foremans = [];
        data.forEach((item) => {
          foremans.push({
            id: item.user_id,
            name: item.user_name_sei + " " + item.user_name_mei,
            user_experiences: item.user_experiences,
          });
        });
        this.FOREMAN_ARRAY = foremans;
      },
      {
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      const headerHeight = 48;
      let style = `height:${this.mainHeight - headerHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.getDataByName(name);
      this.$emit("formUpdate", formValues);
    },

    async getWorkTypes() {
      if (this.CURRENT_SITE.field_id) {
        await Store.dispatch(
          "PortalChart/getWorkTypes",
          this.CURRENT_SITE.field_id
        );
      }
    },

    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },

    async getListGroups() {
      await Store.dispatch(
        "PortalChart/getListGroups",
        {
          field_construction_id: this.formValues["field_construction_id"],
          user_has_foreman: 1,
        }
      );
    },

    async getCompanyPartners() {
      let params = {
        company_for_user_flag: 1,
        user_has_foreman: 1,
        state_handling_flag: 0,
        display_branch_name_flag: 1
      };
      if (this.formValues["group_id"]) params.field_tree_group_id = this.formValues?.group_id;
      else if (this.formValues["field_construction_id"]) params.field_construction_id = this.formValues.field_construction_id;
      else return;
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
          params['company_prime_flag']=1;
      }
      await Store.dispatch("PortalChart/getListCompanyPartner", {params});
    },

    async getListFieldTree() {
      if (this.COMPANY_PARTNER_ARRAY.length > 0) {
        const params = {
          id: this.formValues["field_tree_id"],
          state_handling_flag: 0
        };
        await Store.dispatch("PortalChart/getListCompanyPartnerUser", params);
      }
    },

    getDataByName(name) {
      switch (name) {
        case "field_construction_id": {
          this.formValues.group_id = null;
          this.formValues.field_tree_id = null;
          this.formValues.user_ids = [];
          this.FIELD_TREE_GROUP_ARRAY = [this.getDefaultArrays("id", "group_name")];
          this.COMPANY_PARTNER_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.FOREMAN_ARRAY = [];
          if (this.formValues[name]) {
            this.getListGroups();
            this.getCompanyPartners();
          }
          break;
        }
        case "group_id": {
          this.formValues.field_tree_id = null;
          this.formValues.user_ids = [];
          this.COMPANY_PARTNER_ARRAY = [
            this.getDefaultArrays("field_tree_id", "company_name"),
          ];
          this.FOREMAN_ARRAY = [];
          this.getCompanyPartners();
          break;
        }
        case "field_tree_id": {
          if (!this.formValues['group_id'] && this.formValues['field_tree_id']) {
            let groupId = this.getGroupdIdByFieldTree(this.formValues['field_tree_id']);
            let hasGroup = this.hasGroupById(groupId);
            if (hasGroup) {
              this.formValues['group_id'] = groupId;
              this.getCompanyPartners();
            }
          }
          this.formValues.user_ids = [];
          this.FOREMAN_ARRAY = [];
          if (this.formValues[name]) {
            this.getListFieldTree();
          }
          break;
        }
        default:
          break;
      }
    },

    async getEnterDayTime() {
      if (this.CURRENT_SITE.field_id) {
        await Store.dispatch(
          `${STORE}/getEnterDayTime`,
          this.CURRENT_SITE.field_id
        );
      }
    },

    getGroupdIdByFieldTree(fieldTreeId) {
      return this.COMPANY_PARTNER_ARRAY.find(e => e.field_tree_id === fieldTreeId)?.field_tree_group_id || null;
    },

    hasGroupById(groupId) {
      if (!groupId) return false;
      let group = this.FIELD_TREE_GROUP_ARRAY.find(e => e.id === groupId);
      if (group) return true;
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/forms.scss";
.row-position {
  margin-top: -20px;
  height: 150px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>
