const STATE = [
  {
    id: 1,
    name: "北海道",
    en: "Hokkaidô",
  },
  {
    id: 2,
    name: "青森県",
    en: "Aomori",
  },
  {
    id: 3,
    name: "岩手県",
    en: "Iwate",
  },
  {
    id: 4,
    name: "宮城県",
    en: "Miyagi",
  },
  {
    id: 5,
    name: "秋田県",
    en: "Akita",
  },
  {
    id: 6,
    name: "山形県",
    en: "Yamagata",
  },
  {
    id: 7,
    name: "福島県",
    en: "Hukusima",
  },
  {
    id: 8,
    name: "茨城県",
    en: "Ibaraki",
  },
  {
    id: 9,
    name: "栃木県",
    en: "Totigi",
  },
  {
    id: 10,
    name: "群馬県",
    en: "Gunma",
  },
  {
    id: 11,
    name: "埼玉県",
    en: "Saitama",
  },
  {
    id: 12,
    name: "千葉県",
    en: "Tiba",
  },
  {
    id: 13,
    name: "東京都",
    en: "Tôkyô",
  },
  {
    id: 14,
    name: "神奈川県",
    en: "Kanagawa",
  },
  {
    id: 15,
    name: "新潟県",
    en: "Niigata",
  },
  {
    id: 16,
    name: "富山県",
    en: "Toyama",
  },
  {
    id: 17,
    name: "石川県",
    en: "Isikawa",
  },
  {
    id: 18,
    name: "福井県",
    en: "Hukui",
  },
  {
    id: 19,
    name: "山梨県",
    en: "Yamanasi",
  },
  {
    id: 20,
    name: "長野県",
    en: "Nagano",
  },
  {
    id: 21,
    name: "岐阜県",
    en: "Gihu",
  },
  {
    id: 22,
    name: "静岡県",
    en: "Sizuoka",
  },
  {
    id: 23,
    name: "愛知県",
    en: "Aiti",
  },
  {
    id: 24,
    name: "三重県",
    en: "Mie",
  },
  {
    id: 25,
    name: "滋賀県",
    en: "Siga",
  },
  {
    id: 26,
    name: "京都府",
    en: "Kyôto",
  },
  {
    id: 27,
    name: "大阪府",
    en: "Ôsaka",
  },
  {
    id: 28,
    name: "兵庫県",
    en: "Hyôgo",
  },
  {
    id: 29,
    name: "奈良県",
    en: "Nara",
  },
  {
    id: 30,
    name: "和歌山県",
    en: "Wakayama",
  },
  {
    id: 31,
    name: "鳥取県",
    en: "Tottori",
  },
  {
    id: 32,
    name: "島根県",
    en: "Simane",
  },
  {
    id: 33,
    name: "岡山県",
    en: "Okayama",
  },
  {
    id: 34,
    name: "広島県",
    en: "Hirosima",
  },
  {
    id: 35,
    name: "山口県",
    en: "Yamaguti",
  },
  {
    id: 36,
    name: "徳島県",
    en: "Tokusima",
  },
  {
    id: 37,
    name: "香川県",
    en: "Kagawa",
  },
  {
    id: 38,
    name: "愛媛県",
    en: "Ehime",
  },
  {
    id: 39,
    name: "高知県",
    en: "Kôti",
  },
  {
    id: 40,
    name: "福岡県",
    en: "Hukuoka",
  },
  {
    id: 41,
    name: "佐賀県",
    en: "Saga",
  },
  {
    id: 42,
    name: "長崎県",
    en: "Nagasaki",
  },
  {
    id: 43,
    name: "熊本県",
    en: "Kumamoto",
  },
  {
    id: 44,
    name: "大分県",
    en: "Ôita",
  },
  {
    id: 45,
    name: "宮崎県",
    en: "Miyazaki",
  },
  {
    id: 46,
    name: "鹿児島県",
    en: "Kagosima",
  },
  {
    id: 47,
    name: "沖縄県",
    en: "Okinawa",
  },
];

//１ページあたりのテーブル件数
const TABLES_PER_PAGE = [25, 50, 75, 100];

const TABLE_SORT_ORDERS = [
  { id: "asc", name: "昇順" },
  { id: "dec", name: "降順" },
];

const SWITCH_VALUE = {
  OFF: 0,
  ON: 1,
};

const MENU_SCHEDULE_ITEMS = [
  { id: 0, name: "作業予定", path: "/schedule/works" },
  { id: 1, name: "作業実績", path: "/schedule/records" },
  { id: 2, name: "ゲート予定", path: "/schedule/gates" },
  { id: 3, name: "揚重予定", path: "/schedule/cranes" },
  { id: 4, name: "使用予定", path: "/schedule/usage" },
  { id: 5, name: "機材予定", path: "/schedule/machines" },
  { id: 6, name: "自社管理", path: "/schedule/company" },
];

const MENU_SAFETY_ITEMS = [
  { id: 0, name: "巡回記録", path: "/schedule/patrols" },
  { id: 1, name: "帳票印刷", path: "/schedule/reports" },
];

const MENU_ENTRANCE_ITEMS = [
  { id: 0, name: "顔画像登録", path: "/entrance/register_face" },
  { id: 1, name: "入退場履歴", path: "/entrance/history" },
  { id: 2, name: "入退場実績", path: "/entrance/records" },
  { id: 3, name: "新規入場者", path: "/entrance/new_worker" },
  { id: 4, name: "集計", path: "/entrance/summary" },
];

const MENU_MASTER_ITEMS = [
  { id: 0, name: "ゲート", path: "/master/gates" },
  { id: 1, name: "揚重機", path: "/master/cranes" },
];

const MENU_SP_ITEMS = [
  { id: 0, name: "入場/退場", path: "/sp/faceauth" },
  { id: 1, name: "現場選択", path: "/sp/siteSelect" },
  { id: 2, name: "作業予定", path: "/sp/schedule/works" },
  { id: 3, name: "作業実績", path: "/sp/schedule/records" },
  { id: 4, name: "チャット", path: "/room" },
];

const MENU_DEVICE_ITEMS = [
  { id: 0, name: "調達", path: "/device/coordinator" },
  { id: 1, name: "在庫", path: "/device/stock" },
  { id: 2, name: "持込機械届", path: "/device/carry_on" },
];

const MENU_DOCUMENT_ITEMS = [{ id: 0, name: "書類", path: "" }];

const MENU_CHAT_ITEMS = [
  { id: 0, name: "現場チャット", path: "/portal/chatmanage/siterooms" },
  { id: 1, name: "ユーザチャット", path: "/portal/chatmanage/userrooms" },
];
const MENU_OTHER_ITEMS = [
  { id: 0, name: "ゲートマスタ", path: "/master/gates" },
  { id: 1, name: "揚重機マスタ", path: "/master/cranes" },
  { id: 2, name: "書類", path: "" , isForm: true},
];
const NO_DATA_MESSAGE = "データがありません。";

const SUBMIT_DELAY_TIMEOUT = 3000; // millisecond
const EXCEL_TEMPLATE_NAME = "社員マスタ一括登録.xlsx";
const ANEMOTROPIC = [
  "北",
  "北北東",
  "北東",
  "東北東",
  "東",
  "東南東",
  "南東",
  "南南東",
  "南",
  "南南西",
  "南西",
  "西南西",
  "西",
  "西北西",
  "北西",
  "北北西",
  "北",
];
const IMAGE_NO_FACE = "/images/no_face.png";
const PASSWORD_DEFAULT = "●●●●●●●●●●";

const ROLE_SUPERVISOR = 3;
const ROLE_FOREMAN = 2;
const ROLE_USER_RENTAL_COMPANY = 4;
const ROLE_LEVEL_2 = 1;
const FILE_CODE = {
  EXCEL: 1,
  PDF: 2,
};

const FILELD_APPROVALS_TYPE = [
  { id: 1, name: "所長" },
  { id: 2, name: "副所長" },
  { id: 3, name: "工事長" },
  { id: 4, name: "主任・係員" },
];

const IS_CHILD_EDIT_TABLE = 0
const IS_PARENT_EDIT_TABLE = 1
const NUMBER_MIN_ADD = 15

const addMinutesToTime = (time, minutes = NUMBER_MIN_ADD) => {
  if (time) {
    let date = new Date();
    date.setHours(time.split(':')[0]);
    date.setMinutes(parseInt(time.split(':')[1]) + minutes);
    let endHours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let endMinutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    if(time=="23:45")
      return "24:00";
    return endHours + ':' + endMinutes;
  }
  return time;
}

export {
  STATE,
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  SWITCH_VALUE,
  MENU_SCHEDULE_ITEMS,
  MENU_MASTER_ITEMS,
  NO_DATA_MESSAGE,
  MENU_SP_ITEMS,
  SUBMIT_DELAY_TIMEOUT,
  MENU_ENTRANCE_ITEMS,
  EXCEL_TEMPLATE_NAME,
  ANEMOTROPIC,
  IMAGE_NO_FACE,
  PASSWORD_DEFAULT,
  MENU_DEVICE_ITEMS,
  ROLE_SUPERVISOR,
  ROLE_USER_RENTAL_COMPANY,
  FILE_CODE,
  MENU_SAFETY_ITEMS,
  MENU_DOCUMENT_ITEMS,
  MENU_CHAT_ITEMS,
  MENU_OTHER_ITEMS,
  FILELD_APPROVALS_TYPE,
  ROLE_FOREMAN,
  IS_CHILD_EDIT_TABLE,
  IS_PARENT_EDIT_TABLE,
  ROLE_LEVEL_2,
  NUMBER_MIN_ADD,
  addMinutesToTime
};
