<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.page_count >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <RecordHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :isDirector="isDirector"
              :multiSelectStatus="disableApproveBtn"
              :searchParams="searchParams"
              :updateHeader="updateHeader"
              @onReject="openConfirmDialog(MODE_ACTION.REJECT)"
              @openRemoveDialog="openConfirmDialog(MODE_ACTION.DELETE)"
              @onApprove="openConfirmDialog(MODE_ACTION.APPROVED)"
              @onApproveAll ="openConfirmDialog(MODE_ACTION.APPROVE_ALL)"
              @onInput="onChangeSearchParams"
              @openNewItemForm="openNewItemForm"
            >
              <v-row>
                <v-col sm="11" md="11">
                  <SearchFormWrapper>
                    <Label label="協力会社" width="200px">
                      <Select
                        name="field_tree_id"
                        :items="COMPANY_PARTNER_ARRAY"
                        :editable="true"
                        item_text="company_name"
                        item_value="field_tree_id"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <Label label="氏名" width="200px">
                      <InputText
                        name="user_name"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <TabSelect
                        v-show="!IS_OBAYASHI"
                        class="mt-7"
                        name="approval_status"
                        :items="SWITCH_APPROVAL_STATUS"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                    />
                    <SwitchInput
                        v-show="!IS_OBAYASHI"
                        class="mt-4"
                        name="invalid_data_only_flag"
                        :label="`不正データのみ表示`"
                        :values="searchParams"
                        :editable="true"
                        @onInput="onChangeSearchParams"
                      />
                  </SearchFormWrapper>
                </v-col>
                <v-col sm="1" md="1" class="text-right">
                  <v-btn
                    class="mr-6 ma-2"
                    depressed
                    color="primary"
                    @click="onSearch()"
                  >
                    検索
                  </v-btn>
                </v-col>
              </v-row>
            </RecordHeader>
            <v-container class="tableSortWrapper">
              <v-spacer></v-spacer>
              <div class="sortLabel">総件数:</div>
              <div class="sortElement total_item">
                {{ searchParams.all_record }}件
              </div>
            </v-container>
          </template>
          <template #tableBody="{ tableHeight }">
            <RecordTable
              :tableLabels="tableLabels"
              :tableHeight="tableHeight"
              :items="items"
              :isNoDataMessage="isNoDataMessage"
              :itemsPerPage="searchParams.page_count"
              :isSelected="isSelected"
              :checkSelectedParents="checkSelectedParents"
              :functions="functions"
              :key="reloadList"
              @update="updateSelectedItems"
            />
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.current_page"
              :total="searchParams.total_page"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup :dialog="popups.isShowItemForm">
      <RecordForm
        :item="editedItem"
        @formUpdate="formUpdate"
        :isDirector="isDirector"
        :isNewItem="isNewItem"
        :companyName="companyName"
        :params="params"
        @cancel="closeItemForm"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmRemoveDialog
        :dialogText="dialogText"
        @close="closeRemoveDialog()"
        @ok="confirmOkDialog()"
        @yes="handleConfirm()"
        :warning="warning"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import RecordHeader from "./components/RecordHeader";
import RecordTable from "./components/RecordTable.vue";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
import Popup from "@/components/common/Popup";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import RecordForm from "@/components/forms/entrances/records/RecordForm";
import { format } from "date-fns";
import { getDayOfWeekStr } from "@/utils/timeUtil";
import _ from "lodash";
import TabSelect from "@/components/forms/elements/TabSelect"; 
import InputText from "@/components/forms/elements/InputText"; 
import SwitchInput from "@/components/forms/elements/SwitchInput"; 
import { SWITCH_APPROVAL_STATUS, MODE_ACTION , DIALOG_TEXT, DIALOG_REMOVE_TEXT, DIALOG_ALL_APPROVAL_TEXT, ENTRANCE_RECORD_INITAL_ITEM , DIALOG_NOT_IN_HISTORY_TEXT} from "@/constants/ENTRANCE_RECORD.js";
import { ROLE_SUPERVISOR, ROLE_FOREMAN} from "@/constants/COMMON.js";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog.vue";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";



const PAGE_TITLE = "入退場実績";

const PAGE_COUNT = 3;

const STORE = "EntranceRecord";

const APPROVE_STATUS = 1;
const REJECT_STATUS = 0;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      MODE_ACTION,
      DIALOG_TEXT,
      DIALOG_REMOVE_TEXT,
      DIALOG_ALL_APPROVAL_TEXT,
      DIALOG_NOT_IN_HISTORY_TEXT,
      isDirector : false,
      tableLabels: [],
      field_id: null,
      PAGE_TITLE,
      items: [],
      params: {},
      searchParams: {
        start_date: null,
        end_date: null,
        field_tree_id: null,
        user_name:'',
        page_count: PAGE_COUNT,
        approval_status : [],
        current_page: 1,
        total_page: 1,
        total_item: 0,
        all_record: 0,
        invalid_data_only_flag : 0
      },
      COMPANY_PARTNER_ARRAY: [],
      popups: {
        isShowItemForm: false,
        isShowConfirmDialog : false
      },
      editedItem: {},
      companyName: null,
      isNewItem: false,
      functions: {
        openItemForm: this.openItemForm,
        getChildItems: this.getChildItems
      },
      reloadList : false,
      selectedItems: [],
      dialogText: {
        title: "",
        text: [],
        btnSubmitText: "",
        flg: "",
      },
      warning : true,
      isNoDataMessage: false,
    };
  },

  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SitePortalHeader,
    RecordHeader,
    RecordTable,
    Select,
    Label,
    Popup,
    SearchFormWrapper,
    RecordForm,
    InputText,
    TabSelect,
    SwitchInput,
    ConfirmRemoveDialog
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.ENTRANCE.id,
    });
    this.getInitialSearchParams();
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.field_id = data.field_id;
          this.checkRoleUser(this.field_id);
          this.getItems();
          this.getListCompanyPartner();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        if (data && data !== ROLE_SUPERVISOR && data !== ROLE_FOREMAN) {
          Store.dispatch("Error/show", {
            status: 401,
            message: "操作する権限が存在しません。",
          });
          this.$router.push("/portal/dashboard");
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    /**
     * get data and pagging list item
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = data[0];
        this.isNoDataMessage = this.items.length == 0;
        let searchParams = { ...this.searchParams };
        searchParams.total_page = data[1].total;
        searchParams.current_page = parseInt(data[1].current);
        searchParams.total_item = data[1].total_item;
        searchParams.all_record = data[1].all_record;
        this.searchParams = { ...searchParams };
        this.getTableLabels(data[0]);
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getListCompanyPartner"],
      (value) => {
        this.COMPANY_PARTNER_ARRAY = [
          { field_tree_id: null, company_name: "" },
          ...value,
        ];
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getListCompanyMainContructorAndPartner"],
      (value) => {
        this.COMPANY_PARTNER_ARRAY = [
          { field_tree_id: null, company_name: "" },
          ...value,
        ];
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.isDirector = data === ROLE_SUPERVISOR;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    disableApproveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.field_id,
        start_date : this.searchParams.start_date,
        end_date : this.searchParams.end_date,
        approval_status : this.searchParams.approval_status,
        invalid_data_only_flag : this.searchParams.invalid_data_only_flag,
        field_tree_id: this.searchParams.field_tree_id,
        user_name : this.searchParams.user_name,
        page_number: this.searchParams.current_page,
      };
    },

    SWITCH_APPROVAL_STATUS() {
      return Object.keys(SWITCH_APPROVAL_STATUS).map((key) => {
        return SWITCH_APPROVAL_STATUS[key];
      });
    },
  },
  methods: {
    /**
     * initial search params start , end date
     */

    getInitialSearchParams(){
      let searchParams = { ...this.searchParams };
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth()-1, date.getDate()-1);
      let lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()-1);
      searchParams.start_date = format(firstDay, "yyyy/MM/dd");
      searchParams.end_date = format(lastDay, "yyyy/MM/dd");
      this.searchParams = { ...searchParams };
    },

    /**
     * 0 : reject
     * 1: approve
     * 2: delete
     * 3: approve all
     */
    openConfirmDialog(flg) {
      if (flg == MODE_ACTION.DELETE) {
        this.warning = true;
        const approves = this.getSelectedItems(APPROVE_STATUS);
        if(approves == null){
          this.dialogText.title = this.DIALOG_NOT_IN_HISTORY_TEXT.TITLE;
          this.dialogText.text[1] = this.DIALOG_NOT_IN_HISTORY_TEXT.TEXT;
        }else{
          const isApproved = approves.length == this.selectedItems.length;
          this.dialogText.title = this.DIALOG_REMOVE_TEXT.TITLE;
          if (isApproved) {
            this.dialogText.text[0] = this.DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_1;
            this.dialogText.text[1] = this.DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_2;
          } else {
            this.dialogText.text[0] = this.DIALOG_REMOVE_TEXT.TEXT_1;
            this.dialogText.text[1] = this.DIALOG_REMOVE_TEXT.TEXT_2;
            this.dialogText.text[2] = this.DIALOG_REMOVE_TEXT.TEXT_3;
            this.dialogText.text[3] = this.DIALOG_REMOVE_TEXT.TEXT_4;
            this.dialogText.flg = flg;
            this.dialogText.btnSubmitText = this.DIALOG_REMOVE_TEXT.BTN_SUBMIT;
          }
        }
      } else {
        if (flg == MODE_ACTION.APPROVE_ALL){
          this.dialogText.title = this.DIALOG_ALL_APPROVAL_TEXT.TITLE ;
          this.dialogText.text[0] = this.DIALOG_ALL_APPROVAL_TEXT.TEXT_1;
          this.dialogText.text[1] = this.DIALOG_ALL_APPROVAL_TEXT.TEXT_2;
          this.dialogText.flg = flg;
          this.warning = false;
          this.dialogText.btnSubmitText = this.DIALOG_ALL_APPROVAL_TEXT.BTN_SUBMIT;
        }
        else{
          this.warning = flg == MODE_ACTION.APPROVED ? false : true ;
          let approve_status = flg == MODE_ACTION.APPROVED ? APPROVE_STATUS: REJECT_STATUS;
          const approves = this.getSelectedItems(approve_status);
          if(approves == null){
            this.dialogText.title = this.DIALOG_NOT_IN_HISTORY_TEXT.TITLE;
            this.dialogText.text[1] = this.DIALOG_NOT_IN_HISTORY_TEXT.TEXT;
          }else{
            const isApproved = approves.length == this.selectedItems.length;
            this.dialogText.title = flg == MODE_ACTION.REJECT ? this.DIALOG_TEXT.REJECT_TITLE : this.DIALOG_TEXT.APPROVE_TITLE;
            if (isApproved) {
              this.dialogText.text[0] =
                flg == MODE_ACTION.REJECT
                  ? this.DIALOG_TEXT.REJECT_ERROR_TEXT1
                  : this.DIALOG_TEXT.ALL_APPROVE_TEXT;
            } else {
              this.dialogText.text[0] =
                flg == MODE_ACTION.REJECT
                  ? this.DIALOG_TEXT.REJECT_TEXT1
                  : this.DIALOG_TEXT.APPROVE_TEXT1;
              this.dialogText.text[1] =
                flg == MODE_ACTION.REJECT
                  ? this.DIALOG_TEXT.REJECT_TEXT2
                  : this.DIALOG_TEXT.APPROVE_TEXT2;
              this.dialogText.flg = flg;
              this.dialogText.btnSubmitText =
                flg == MODE_ACTION.REJECT
                  ? this.DIALOG_TEXT.REJECT_BTN_SUBMIT
                  : this.DIALOG_TEXT.APPROVE_BTN_SUBMIT;
              }
            }
          }
        }
      this.popups.isShowConfirmDialog = true;
    },

    async openItemForm(item, fieldTreeId) {
      const params = {
        user_id: item.user_id,
        field_tree_id: fieldTreeId,
        start_date: this.searchParams.start_date,
        end_date: this.searchParams.end_date,
      }
      this.params = params;
      const result = await Store.dispatch(`${STORE}/getDetail`, params);
      // hasError == False
      // Show Detail Form
      if (!result.hasError) {
        let company_name = result.data.contents.entries.company_name;
        this.companyName = company_name + "（" + this.searchParams.start_date + "〜" + this.searchParams.end_date + "）";
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    openNewItemForm() {
      this.editedItem = _.cloneDeep(ENTRANCE_RECORD_INITAL_ITEM);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },

    /*
    * update status
    * flag : 0 : reject , flag 1 : approve , flag 3 : approve all
    */

    async updateApprove(flag) {
      let params = {};
      if(flag == MODE_ACTION.APPROVE_ALL){
        // approve all
        let paramSearch = {...this.apiParams};
        delete paramSearch.start_date;
        delete paramSearch.end_date;
        params = { searches: paramSearch , approval_flag : APPROVE_STATUS , start_date : this.apiParams.start_date,end_date:this.apiParams.end_date};
      }else{
        let ids = [];
        if (flag == MODE_ACTION.APPROVED) {
          // approve
          ids = this.getSelectedItems(REJECT_STATUS);
        }else {
          // reject
          ids = this.getSelectedItems(APPROVE_STATUS);
        }
        params = { field_tree_users: ids, approval_flag: flag ,start_date : this.apiParams.start_date,end_date:this.apiParams.end_date};
      }
      const result = await Store.dispatch(
        `${STORE}/updateApproveResultArrival`,
        params
      )
      if (!result.hasError) {
        let msg = "承認解除しました";
        if (flag ==  MODE_ACTION.APPROVE_ALL || flag == MODE_ACTION.APPROVED) {
          msg = "承認しました";
        }
        this.getItems();
        Store.dispatch("Notification/getNewNoticeStatus");
        Store.dispatch("Toast/show", {
          status: 200,
          message: msg,
        });
      }
      this.closeRemoveDialog();
    },

    // delete items
    async removeItems() {
      const field_tree_users = this.getSelectedItems(REJECT_STATUS);
      let params = {
        field_tree_users : field_tree_users,
        start_date : this.searchParams.start_date,
        end_date : this.searchParams.end_date
      }
      const result = await Store.dispatch(`${STORE}/deleteResultArrival`, params);
      if (!result.hasError) {
        this.searchParams.page_number = 1;
        this.closeRemoveDialog();
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    /**
     * handleConfirm
     */
    handleConfirm() {
      if (this.dialogText.flg == MODE_ACTION.REJECT) {
        this.updateApprove(MODE_ACTION.REJECT);
      } else if (this.dialogText.flg == MODE_ACTION.APPROVED) {
        this.updateApprove(MODE_ACTION.APPROVED);
      }else if (this.dialogText.flg == MODE_ACTION.APPROVE_ALL) {
        this.updateApprove(MODE_ACTION.APPROVE_ALL);
      }else {
        this.removeItems();
      }
    },

    formUpdate(params) {
      this.editedItem = _.cloneDeep(params);
    },

    //get table label
    getTableLabels(data) {
      let labels = [];
      if(data.length > 0){
        let index = 0;
        let temp = data[0].users;
        temp[0].childrens.forEach(element => {
          labels.push({
              text: `${format(new Date(element.admission_date), "MM/dd")} (${getDayOfWeekStr(new Date(element.admission_date).toString())})`,
              value: index,
              align: "center",
              width: "100px",
            })
          index++;
        });
      }else{
        let startDate = new Date(this.searchParams.start_date);
        let endDate = new Date(this.searchParams.end_date);
        let maxDate = new Date(this.searchParams.start_date);
        maxDate.setDate(maxDate.getDate() + 31);
        if(endDate > maxDate) {
          endDate = maxDate;
        }
        let index = 0;
        while (format(startDate, "yyyyMMdd") <= format(endDate, "yyyyMMdd") && index < 10) {
          labels.push({
            text: `${format(startDate, "MM/dd")} (${getDayOfWeekStr(startDate.toString())})`,
            value: index,
            align: "center",
            width: "100px",
          })
          startDate = new Date(startDate.setDate(startDate.getDate() + 1));
          index++;
        }
      }
      this.tableLabels = _.cloneDeep(labels);
    },

    //close popup confirm dialog
    closeRemoveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.selectedItems = [];
      this.dialogText = {
        title: "",
        text: [],
        flg: "",
        btnSubmitText: "",
      };
    },
    closeItemForm(isUpdate) {
      this.popups.isShowItemForm = false;
      if(isUpdate) {
        this.getItems();
      }
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = {};
        this.selectedItems = [];
      });
    },

    //click ok in dialog
    confirmOkDialog() {
      this.popups.isShowConfirmDialog = false;
      this.selectedItems = [];
      this.$nextTick(() => {
        this.dialogText = {
          title: "",
          text: [],
          flg: "",
          btnSubmitText: "",
        };
      });
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.current_page = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    //get all reject status in list items
    getAllRejectItems(){
      let rs = [];
      let items = [...this.items];
      items.forEach(element => {
        element.users.forEach(e => {
          if(e.approval_flag == REJECT_STATUS){
            rs.push(e.user_id);
          }
        });
      });
      return rs;
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      if(this.checkStartEndDate()) {
        this.searchParams["current_page"] = 1;
        this.selectedItems = [];
        this.getItems();
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value}) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      if((name == "start_date" || name == "end_date") && this.checkStartEndDate()) {
        this.selectedItems = [];
        this.getItems();
      }
    },

    //check start date end end date
    checkStartEndDate(){
      const temp = new Date(this.searchParams.start_date);
      temp.setDate(temp.getDate() + 31);
      return format(new Date(this.searchParams.start_date), "yyyy/MM/dd") <=  format(new Date(this.searchParams.end_date), "yyyy/MM/dd")
      && format(new Date(this.searchParams.start_date), "yyyy/MM/dd") < format(new Date(), "yyyy/MM/dd") 
      && format(new Date(this.searchParams.end_date), "yyyy/MM/dd") < format(new Date(), "yyyy/MM/dd")
      && format(temp, "yyyy/MM/dd") >=  format(new Date(this.searchParams.end_date), "yyyy/MM/dd");
    },

    //get child items
    async getChildItems(id, pageNumber) {
      let searchParams = { ...this.searchParams };
      searchParams["field_tree_id"] = id;
      searchParams["page_number"] = pageNumber;
      delete searchParams.total_item;
      delete searchParams.page_count;
      delete searchParams.current_page;
      delete searchParams.total_page;
      delete searchParams.all_record;
      const params = {
        params: searchParams,
      };
      await Store.dispatch(`${STORE}/loadChildren`, params);
      this.reloadList = ! this.reloadList;
    },

    async getItems() {
      if (this.field_id) {
        if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
          this.apiParams['is_oba']=1;
        }
        await Store.dispatch(`${STORE}/getListResultArrival`, {
          params: this.apiParams,
        });
      }
    },

    async getListCompanyPartner() {
      if (this.field_id) {
        let params = { field_id: this.field_id , manager_or_foreman_flg: 1};
        if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
          await Store.dispatch("Sites/getListCompanyMainContructorAndPartner", { params });
        }
        else{
          await Store.dispatch("Sites/getListCompanyPartner", { params });
        }
        
      }
    },
    isSelected(field_tree_id, id) {
      return Boolean(this.selectedItems.find(element => element.field_tree_id ==field_tree_id && element.user_id == id));
    },

    checkSelectedParents(itemParent) {
      for (let i = 0; i < itemParent.users.length; i++) {
        if (!this.isSelected(itemParent.field_tree_id,itemParent.users[i].user_id)) {
          return false;
        }
      }
      return true;
    },

    /**
     * checkboxの値からselectedItemsを更新
     */
    updateSelectedItems(fieldTreeId,id, isChecked , approvalFlag) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedItems, {'field_tree_id' : fieldTreeId ,'user_id': id ,'approval_flag':approvalFlag}];
      } else {
        // unchecked
        _selectedItems = this.selectedItems.filter((item) => {
          return item.user_id != id || item.field_tree_id != fieldTreeId ;
        });
      }
      // 重複削除してset
      this.selectedItems = [...new Set(_selectedItems)];
    },

    //get ids selected items by flag
    getSelectedItems(flag) {
      if (!this.selectedItems.every(selectedItem => selectedItem.approval_flag === null)){
        if (flag === REJECT_STATUS) {
          return this.selectedItems.filter(selectedItem => selectedItem.approval_flag === flag || selectedItem.approval_flag === null)
          .map(selectedItem => {
            return {'field_tree_id': selectedItem.field_tree_id, 'user_id' : selectedItem.user_id};
          });
        }
        return this.selectedItems.filter(selectedItem => selectedItem.approval_flag === flag)
          .map(selectedItem => {
            return {'field_tree_id': selectedItem.field_tree_id, 'user_id' : selectedItem.user_id};
          });
      }else{
        return null;
      }
    },

    async checkRoleUser(field_id){
      if (!field_id) return;
      const params = {
        field_id,
        target_role: []
      };
      await Store.dispatch("Sites/getRole", params);
    },
  },
};
</script>

<style lang="scss" >
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
}
</style>
