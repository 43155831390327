<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <!--  
              (共通) テーブルヘッダー 
              このなかに、新規作成ボタン、検索ボタン、一括削除ボタンが実装されてます
              @openRemoveDialog : 一括削除の確認ダイアログを開く
              @openItemForm : 新規作成フォームを開く
            -->
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :isInvite="true"
              :isFormInfo="true"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
              @openInviteDialog="openInviteEmployeeDialog"
              @openFormInfoDialog="popups.isShowUploadForm = true"
            >
              <!-- 
                検索はテーブルごとに実装します 
                @onInput 
              -->
              <SearchFormWrapper>
                <!-- 文字入力 -->
                <Label label="社員名">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!--  (共通) 検索ボタン -->
                <v-spacer></v-spacer>
                <v-btn class="mr-6" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              item-key="id"
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              :item-class="rowClass"
              sort-by="updatedAt"
              show-select
              :noDataText="noDataMessage"
              @click:row="openItemForm"
            >
            <template v-slot:[`item.name`]="{ item }">
                <div>
                  {{ convertLongText(item.name, 16) }}
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div>
                  {{ convertLongText(item.email, 40) }}
                </div>
              </template>
              <template v-slot:[`item.invite_flg`]="{ item }">
                {{ handleInviteFlg(item.invite_flg) }}
              </template>
            </v-data-table>
          </template>
          <template #tableFooter >
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (社員用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <EmployeeForm
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :isStopUser="isStopUser"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
    </Popup>

    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowInviteEmployeeDialog">
      <InviteEmployeeDialog
        @close="closeInviteDialogForm()"
        @yes="inviteEmployees()"
        title="ユーザー追加"
        text1="招待メールをお送りします。"
        text2="メールアドレスが設定されていない社員データに
招待メールは送信されません。"
        :items="selectedItems"
        :isDisable="inviteSubmitted"
      />
    </Popup>

    <!--  (社員用) 追加/編集ダイアログ -->
    <Popup width="500px" :dialog="popups.isShowUploadForm">
      <UploadInfoDialog
        @close="closeUploadDialog"
        :flagError="flagError"
        @yes="submitUploadInfo"
      />
    </Popup>
    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowErorDialog">
      <ShowErrorDialog
        @close="popups.isShowErorDialog = false"
        :text="textError"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog"; //削除確認ダイアログ
import InviteEmployeeDialog from "./components/InviteEmployeeDialog";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS, NO_DATA_MESSAGE, SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON"; //絞り込みフォームで使用
import EmployeeForm from "@/components/forms/employees/EmployeeForm"; //社員登録編集フォーム
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  EMPLOYEE_TABLE_LABELS,
  EMPLOYEE_SORT_ITEMS,
  EMPLOYEE_INITAL_ITEM,
  STOP_USER
} from "@/constants/EMPLOYEE"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import _ from "lodash";
import UploadInfoDialog from "./components/UploadInfoDialog";
import ShowErrorDialog from "./components/ShowErrorDialog";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";


/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "社員";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Employees";

//テーブルヘッダーラベル
const TABLE_LABELS = EMPLOYEE_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = EMPLOYEE_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = EMPLOYEE_INITAL_ITEM;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      STOP_USER,

      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: { ...INITIAL_ITEM },

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      isStopUser: false,

      // 検索パラメータ
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: SORT_ITEMS[0].id, //sortする項目
        asc: true,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
        // invite employee dialog
        isShowInviteEmployeeDialog: false,
        isShowUploadForm: false,
        isShowErorDialog: false,
      },
      isErrorSubmit: false,
      isEmitted: false,
      inviteSubmitted: false,
      textError: null,
      flagError: false,
      noDataMessage: "",
    };
  },

  components: {
    InviteEmployeeDialog,
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    EmployeeForm,
    InputText,
    InputDatepicker,
    Select,
    Label,
    UploadInfoDialog,
    ShowErrorDialog,
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.EMPLOYEES.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (共通)
         * 一覧リストの格納
         */
        let items = data[0];
        this.items = [...items];
        if (this.items.length == 0)
          this.noDataMessage = NO_DATA_MESSAGE;

        /**
         *  (共通)
         * ページネーション更新
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     * API Param
     */
    apiParams() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      let companyBranchId = Store.state.PortalChart.companyBranchesId;
      if (!companyBranchId) {
        companyBranchId = companyUser.company_branch_id;
      }
      return {
        company_id: companyUser.company_id,
        company_branch_id: companyBranchId,
        user_name: this.searchParams.word,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      this.editedItem = _.cloneDeep(INITIAL_ITEM);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      this.isStopUser = item.state_handling_flag == STOP_USER.STOP ? true : false;
      const result = await Store.dispatch(`${STORE}/getDetail`, item.id);
      // hasError == False
      // Show Detail Form
      if (!result.hasError) {
        const { entries } = result.data.contents;
        let temp = _.cloneDeep(entries);
        temp.user_awards.forEach(e => {
          if (e.cmn_mst_awards_id) {
            e.sub_catergory_id = e.cmn_mst_awards_id;
          }
        });
        if (Object.keys(JSON.parse(JSON.stringify(temp.user_health_informations))).length === 0) {
          temp.user_health_informations = _.cloneDeep(INITIAL_ITEM.user_health_informations);
        }
        this.editedItem = temp
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.getItems();
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },

    // open invite employee dialog
    openInviteEmployeeDialog() {
      this.popups.isShowInviteEmployeeDialog = true;
    },

    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    // close invite employee
    closeInviteDialogForm() {
      this.popups.isShowInviteEmployeeDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     * handle Invite Flg
     * @returns flg == 0 return "", flag == 1 return "招待済"
     */
    handleInviteFlg(flg) {
      let inviteStatus = "";
      if (flg == 1) {
        inviteStatus = "招待済";
      }
      return inviteStatus;
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
    },

    // 削除
    async removeItems() {
      const user_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { user_ids });

      if (!result.hasError) {
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    async inviteEmployees() {
      this.inviteSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.inviteSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      const selectedItemsFilter = this.selectedItems.filter(
        (items) => items.invite_flg === 0
      );
      const user_ids = selectedItemsFilter.map((items) => items.id);
      const apiParams = {
        user_ids,
        company_id: companyUser.company_id,
        company_branch_id: Store.state.PortalChart.companyBranchesId,
      };
      const result = await Store.dispatch(`${STORE}/inviteEmployee`, apiParams);

      if (!result.hasError) {
        //ダイアログ閉じる
        this.closeInviteDialogForm();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "招待しました",
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const companyUser = JSON.parse(sessionStorage.getItem("COMPANY_USER"))
        .Login.company_user;
      const hasId = "id" in this.editedItem.users;
      let editedItem = { ...this.editedItem };
      editedItem["company_users"] = {
        company_id: companyUser.company_id,
        company_branch_id: Store.state.PortalChart.companyBranchesId,
      };
      editedItem.user_experiences = editedItem.user_experiences.map((item) => {
        item.experience_blank_period = Number(item.experience_blank_period) || 0;
        return item;
      });
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
        editedItem['is_oba']=1;
      }
      this.editedItem = editedItem;
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `${STORE}/update` : `${STORE}/post`,
        this.editedItem
      );
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          this.editedItem.users.id
        );
        let temp = _.cloneDeep(resultDetail.data.contents.entries);
        temp.user_awards.forEach(e => {
          if (e.cmn_mst_awards_id) {
            e.sub_catergory_id = e.cmn_mst_awards_id;
          }
        });
        this.editedItem = { ...temp };
        this.isErrorSubmit = false;
      } else {
        //成功したら値を更新
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },
    convertLongText(name, numberOfChar) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > numberOfChar) {
          result = name.substring(0, numberOfChar) + "•••";
        }
      }
      return result;
    },
    rowClass(item) {
        return item.state_handling_flag == 1 ? 'grey lighten-1' : '';
    },
    closeUploadDialog() {
      this.popups.isShowUploadForm = false;
    },
    async submitUploadInfo(param) {
      const params = {...param};
      params.company_id = this.apiParams.company_id;
      params.company_branch_id = this.apiParams.company_branch_id;
      const rs = await Store.dispatch("Employees/addUserInfo", params);
      if(!rs.hasError) {
        this.getItems();
        this.closeUploadDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      } else {
        if(/422/.test(rs.response.status)) {
          this.popups.isShowErorDialog = true;
          let message = rs.response.data.message_detail;
          this.textError = message.split("\n");
        }
        this.flagError = !this.flagError;
      }
    }
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  }
};
</script>