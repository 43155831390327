<template>
  <div>
    <div class="card-label" v-if="(!editable && label) || (!editable && enableFieldTreeId1)">
        <div class="label-custom">
            <span>{{ label }}</span>
        </div>
    </div>
    <ValidationProvider
      v-else
      v-slot="{ errors, valid }"
      :vid="id"
      :name="validation_label"
      :rules="validation_rules"
    >
      <v-autocomplete
        v-model="val"
        dense
        :items="items"
        :item-text="item_text"
        :item-value="item_value"
        :disabled="!editable"
        :success="valid"
        :filled="editable"
        :error-messages="errors"
        :multiple="multiple"
        :chips="multiple"
        :data-testid="testid"
        :noDataText="NO_DATA_MESSAGE"
        placeholder="検索"
        persistent-placeholder
        @input="$emit('onInput', { name, value: val })"
        :filter="filterObject"
      >
        <template v-slot:prepend-inner v-if="editable">
            <v-icon color="success"> mdi-magnify </v-icon>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    enableFieldTreeId1: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    validation_rules: {
      type: String,
      default: "",
    },
    validation_label: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
    },
    item_text: {
      type: String,
      default: "name",
    },
    item_value: {
      type: String,
      default: "id",
    },
    id: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoCenter: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    }
  },
  data: () => {
    return {
      val: "",
      NO_DATA_MESSAGE
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
    methods: {
      filterObject(item, queryText) {
          return (
              item.company_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase().trim()) >
              -1
          );
      }
    }
};
</script>

<style scoped>
::v-deep .v-autocomplete .v-select__slot .v-select__selections input {
  min-height: 40px !important;
}
::v-deep .v-autocomplete .v-select__slot .v-select__selections input::placeholder {
    font-size: 13px;
}

.card-label {
    padding-right: 24px;
    position: relative;
}

.label-custom {
    margin-top: 5px;
    padding-top: 3px;
    height: 26px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.label-custom span {
    color: black;
}
.label-custom:before {
    bottom: 0;
    content: "";
    left: 0;
    border-style: solid;
    border-width: thin 0 0;
    position: absolute;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    width: 100%;
    border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat;
    border-color: rgba(0,0,0,.42);
}
</style>