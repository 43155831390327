<template>
  <v-container>
    <RegisterFaceDataTable
      :tableLabels="tableLabels"
      :items="items"
      :isNoDataMessage="isNoDataMessage"
      :items-per-page="itemsPerPage"
      :tableHeight="tableHeight"
      :isShowChildren="isShowChildren"
      :toggleShowChildren="toggleShowChildren"
      :imageMap="imageMap"
      :disableMap="disableMap"
      :isSelected="isSelected"
      :isLoadChilr="isLoadChilr"
      :checkSelectedParents="checkSelectedParents"
      :isSelectedParent="isSelectedParent"
      :updateCheckbox="updateCheckbox"
      :childTableDisplayMoreNumber="childTableDisplayMoreNumber"
      :NO_DATA_MESSAGE="NO_DATA_MESSAGE"
      @onChangeImage="onChangeImage"
      @getChildItems="getChildItems"
    />
  </v-container>
</template>
<script>
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import RegisterFaceDataTable from "./RegisterFaceDataTable.vue";
import _ from "lodash";
import { Store } from "@/store/Store.js";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import {
  IMAGE_NO_FACE,
} from "@/constants/COMMON";
import {
  STATE_HANDLING_FLAG,
} from "@/constants/PORTAL_CHART";

export default {
  data: () => {
    return {
      // child table 表示ステート
      isShowChildren: {},
      selectedParent: [],
      NO_DATA_MESSAGE,
      imageMap: new Map(),
      isLoadChilr: false,
      disableMap: new Map(),
      userIdImgMap: new Map(),
    };
  },
  components: {
    RegisterFaceDataTable,
  },

  props: {

    // ヘッダー
    tableLabels: Array,

    // parent テーブルの高さ
    tableHeight: Number,

    // テーブル表示item
    items: Array,

    // 1ページあたりのitem数
    itemsPerPage: Number,

    // checkboxの更新
    update: Function,

    // child テーブルのデータをAPI取得
    getChildItems: Function,
    isSelected: Function,
    checkSelectedParents: Function,
    userImgMap: Map,
    isResetDisableMap: Boolean,
    isNoDataMessage: Boolean,
  },

  async mounted() {
    this.$watch(
      () => this.items,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const items = newValue;
          this.initIsShowChildren(items);
          this.getImageMap();
          this.getDisableMap();
          this.isLoadChilr = !this.isLoadChilr;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.userImgMap,
      (newValue) => {
        this.userIdImgMap = newValue;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.isResetDisableMap,
      (newValue) => {
        if(newValue) {
          this.disableMap = new Map();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {

    childTableDisplayMoreNumber(totalNum, displayNum) {
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },

    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.field_tree_id] = true;
      });
      this.isShowChildren = _isShowChildren;
    },

    toggleShowChildren(id) {
      let temp = _.cloneDeep(this.isShowChildren);
      temp[id] = !temp[id];
      this.isShowChildren = temp;
    },
    async onChangeImage({current_item, current_user, value, fileName, is_oba}) {
      const obj = {
        field_id: this.CURRENT_SITE.field_id,
        user_id: current_user.user_id,
        field_user_reco_image_id: current_user.field_user_reco_image_id ? current_user.field_user_reco_image_id : undefined,
        person_image: fileName,
        person_image_deleted: current_user.person_image_url ? 1 : 0,
        person_image_data: value,
        is_oba: is_oba,
      }
      const result = await Store.dispatch(`RegisterFace/update`, obj);
      if(!result.hasError) {
        let map = _.cloneDeep(this.imageMap);
        map.set(`person_image_url_${current_user.user_id}`, value);
        this.imageMap = map;
        let disable = _.cloneDeep(this.disableMap);
        disable.set(`disable_${current_item.field_tree_id}_${current_user.user_id}`, false);
        const { entries } = result.data.contents;
        let userImgMap = _.cloneDeep(this.userIdImgMap);
        userImgMap.set(`userImgMap_${current_user.user_id}`, entries.id);
        this.$emit("updateUserImgMap", userImgMap);
        this.disableMap = disable;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
    },
    getDisableMap() {
      const disableMap = _.cloneDeep(this.disableMap);
      const arr = [...this.items];
      arr.forEach(item => {
        item.children.forEach(child => {
          if((child.profile_image_url || child.person_image_url) && (child.state_handling_flag == STATE_HANDLING_FLAG.WORKING)) {
            disableMap.set(`disable_${item.field_tree_id}_${child.user_id}`, false);
          } else {
            disableMap.set(`disable_${item.field_tree_id}_${child.user_id}`, true);
          }
        });
      })
      this.disableMap = disableMap;
    },
    async getImageMap() {
      const mapImage = _.cloneDeep(this.imageMap);
      const arr = [...this.items];
      for(let item of arr) {
        await this.handleChildrenImageUrl(item, mapImage);
      }
      this.imageMap = mapImage;
    },
    async handleChildrenImageUrl(child, mapImage) {
      for(let obj of child.children) {
        await this.setImage(`profile_image_url_${obj.user_id}`, obj.profile_image_url, mapImage)
        await this.setImage(`person_image_url_${obj.user_id}`, obj.person_image_url, mapImage)
      }
    },
    async setImage(key, value, mapImage) {
      if(!this.imageMap.get(key)) {
        const url = await this.getImage(value);
          if(url) {
          mapImage.set(key, url);
        } else {
          mapImage.set(key, IMAGE_NO_FACE);
        }
      }
    },
    isSelectedParent(id) {
      return this.selectedParent.includes(id);
    },

    updateCheckbox(item, isChecked, isParent, itemParent) {
      // parentがchecked
      if (isParent && item.children) {
        this.updateSelectedParentItems(item.field_tree_id, isChecked);
        item.children.forEach((user) => {
          if(user.person_image_url || user.profile_image_url || !this.disableMap.get(`disable_${item.field_tree_id}_${user.user_id}`)) {
            this.$emit("update", item.field_tree_id, user.user_id , isChecked, user.face_reco_status, true, user.field_user_reco_image_id);
          }
        });
      } else {
        const hasImage = item.person_image_url || item.profile_image_url || this.imageMap.get(`person_image_url_${item.user_id}`) ? true : false;
        // childがchecked
        this.$emit("update",itemParent.field_tree_id, item.user_id, isChecked ,item.face_reco_status, hasImage, item.field_user_reco_image_id);
        if (itemParent && isChecked && this.checkSelectedParents(itemParent, this.disableMap)) {
          this.selectedParent.push(itemParent.field_tree_id);
        } else {
          this.selectedParent = this.selectedParent.filter((item) => {
            return item != itemParent.field_tree_id;
          });
        }
      }
    },

    updateSelectedParentItems(id, isChecked) {
      let _selectedItems = [];
      // checked
      if (isChecked) {
        _selectedItems = [...this.selectedParent, id];
      } else {
        // unchecked
        _selectedItems = this.selectedParent.filter((item) => {
          return item != id;
        });
      }
      // 重複削除してset
      this.selectedParent = [...new Set(_selectedItems)];
    },
    async getImage(url) {
      let img = "";
      if (url) {
        const params = getParamsOfUrlReadFile(url);
        const response = await Store.dispatch("File/readFile", params);
        if(!response.hasError) {
          img = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"]}));
        }
      }
      return img;
    },
  },
};
</script>

