<template>
  <div>
    <FormDialog>
      <template #header>
        <!-- 
        (共通)
        ヘッダーは全て共通
       -->
        <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
          <v-toolbar-title color="primary">
            {{ formtitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn 
            v-if="!isNewItem && !IS_OBAYASHI" 
            icon 
            @click="openRemoveDialog()" 
            :disabled="!disableRemoveBtn">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            v-if="isDirector && !isNewItem && !IS_OBAYASHI"
            depressed
            small
            color="warning"
            class="mr-4"
            :disabled="!disableRemoveBtn"
            @click="openRejectDialog()"
          >
            承認解除
          </v-btn>
          <v-btn
            v-if="isDirector && !isNewItem && !IS_OBAYASHI"
            depressed
            small
            class="mr-4"
            color="primary"
            :disabled="!disableRemoveBtn"
            @click="openApproveDialog()"
          >
            承認
          </v-btn>
          <v-btn
            v-if="isDirector && !isNewItem && !IS_OBAYASHI"
            depressed
            small
            class="mr-4"
            color="primary"
            @click="openApproveAllDialog()"
          >
            一括承認
          </v-btn>
          <v-btn
          v-if="isNewItem"
          class="mr-4"
          depressed
          small
          outlined
          color="primary"
          @click="closeForm"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="isNewItem"
          class="mr-4"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click="onCreate"
        >
          保存
        </v-btn>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <template v-slot:extension>
          <v-tabs v-if="isNewItem" v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
        </v-app-bar>
      </template>
      <template #main="{ params }">
        <ValidationObserver ref="observer" v-slot="observer">
          <v-tabs-items v-model="tab" v-if="isNewItem">
            <v-tab-item>
              <RecordFormPage1
                :editable="editable"
                :item="item"
                :enterDayTime="enterDayTime"
                :mainHeight="params.mainHeight"
                @formUpdate="formUpdate"
              />
            </v-tab-item>
          </v-tabs-items>
          <RecordTable
            v-else
            :content="content"
            :items="editedItem"
            :isNoDataMessage="isNoDataMessage"
            :editable="editable"
            :enterDayTime="enterDayTime"
            :tableLabels="getTableHeader"
            :mainHeight="params.mainHeight"
            :valid="valid"
            :selectedList="selectedList"
            @onEditable="onEditable"
            @onSubmit="onSubmit"
            @formUpdate="formUpdateEdit"
            @handleBack="handleBack"
            @updateSelectList="updateSelectList"
            @onChangeForceChecked="onChangeForceChecked"
            @onChange="onChange"
            />
          <ValidationCallback :observer="observer" @callback="updateValidate" />
        </ValidationObserver>

        <Popup width="480px" :dialog="popups.isShowConfirmCloseDialog">
          <ConfirmCloseDialog
            title="フォームを閉じる確認"
            text1="フォームを閉じますがよろしいですか？
  入力内容は保存されません。"
            text2="このページから移動してもよろしいですか？"
            @close="onClickBtnCancelForm"
            @yes="handleCloseDialog"
            warning
          />
        </Popup>

        <Popup width="480px" :dialog="popups.isShowConfirmDialog">
          <ConfirmDialog
            :dialogText="dialogText"
            :isApproved="isApproved"
            @close="closeDialog()"
            @yes="handleConfirm()"
            :warning="dialogText.flag == 1 || dialogText.flag == 2"
          />
        </Popup>

      </template>
    </FormDialog>
  </div>
</template>

<script>
import FormDialog from "@/components/dialog/FormDialog.vue";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { ENTRANCE_RECORD_TABLE_LABELS, ENTRANCE_RECORD_TABLE_LABELS_OBAYASHI, DIALOG_REMOVE_TEXT, DIALOG_TEXT, DIALOG_ALL_APPROVAL_TEXT, EXIT_TIME_TYPE } from "@/constants/ENTRANCE_RECORD";
import RecordTable from "@/components/forms/entrances/records/RecordTable.vue";
import RecordFormPage1 from "@/components/forms/entrances/records/RecordFormPage1.vue";
import ConfirmDialog from "./components/ConfirmDialog.vue";
import { Store } from "@/store/Store.js";
import _ from "lodash";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";


const STORE = "EntranceRecord";

const ACTION_FLAG = {
  REMOVE: 1,
  REJECTED: 2,
  APPROVED: 3,
  ALL_APPROVED: 4
}

const FORMS = {
  RecordFormPage1: { id: 1, title: "基本情報" },
};

export default {
  name: "RecordForm",
  data: () => {
    return {
      FORMS,
      tab:null,
      valid: false,
      title: "",
      editable: false,
      ENTRANCE_RECORD_TABLE_LABELS,
      ENTRANCE_RECORD_TABLE_LABELS_OBAYASHI,
      isClickCancelBtn: false,
      popups: {
        isShowConfirmCloseDialog: false,
        isShowConfirmDialog: false,
      },
      selectedList: [],
      enterDayTime: "",
      content: "",
      editedItem: [],
      beforeUpdateItem: [],
      dialogText: {
        title: "",
        text1: "",
        text2: "",
        text3: null,
        text4: null,
        btnSubmitText: "",
        flag: 0,
      },
      isApproved: false,
      isUpdate: false,
      isNoDataMessage: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    ConfirmDialog,
    RecordTable,
    RecordFormPage1
  },
  props: {
    item: Object,
    isDirector: {
      type: Boolean,
      default: false,
    },
    isNewItem: Boolean,
    companyName: String,
    params: Object,
  },
  mounted() {
    //call api GET fields/get_enter_day_time
    this.getEnterDayTime();

    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [
        Store.getters[`${STORE}/getEntranceRecord`],
        this.editable,
      ],
      (data) => {
        if (!this.isNewItem) {
          this.content = data[0].user_name + "（" + data[0].user_total_working_time + "）";
          let field_entrance_histories = [];
          if(data[0].field_entrance_histories && data[0].field_entrance_histories.length > 0) {
            data[0].field_entrance_histories.forEach((item) => {
              field_entrance_histories.push({
                field_entrance_history_id: item.field_entrance_history_id,
                approval_status: item.approval_status,
                admission_date: item.admission_date,
                approval_user: item.approval_user,
                update_user: item.update_user,
                date_total_worktime: item.date_total_worktime,
              })
              item.field_entrance_times.forEach((time) => {
                field_entrance_histories.push({
                  field_entrance_time_id: time.field_entrance_time_id,
                  invalid_data_flag: time.invalid_data_flag,
                  admission_time_type: time.admission_time_type,
                  admission_time: this.getTime(time.admission_time_hour,time.admission_time_min),
                  exit_time_type: time.exit_time_type,
                  exit_time: this.getTime(time.exit_time_hour,time.exit_time_min),
                  total: time.total,
                  id_parrent: item.field_entrance_history_id,
                  approval_status: item.approval_status,
                  career_link_ccus_flg:time.career_link_ccus_flg,
                  career_link_id: time.career_link_id,
                  career_link_response_error: time.career_link_response_error
                });
              })
            });
          }
          this.beforeUpdateItem = _.cloneDeep(field_entrance_histories);
          this.editedItem = _.cloneDeep(field_entrance_histories);
          this.isNoDataMessage = this.editedItem.length == 0;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

  },
  computed: {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    getTableHeader(){
      if(ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT){
        return this.ENTRANCE_RECORD_TABLE_LABELS_OBAYASHI;
      }
      else{
        return this.ENTRANCE_RECORD_TABLE_LABELS;
      }
    },
    disableRemoveBtn() {
      return this.selectedList.length > 0;
    },
    formtitle() {
      return this.isNewItem ? "入退場記録" : this.companyName;
    },
    arrReject() {
      let arrReject = [];
      this.selectedList.forEach((id) => {
        this.editedItem.forEach(item => {
          if(item.field_entrance_history_id == id && item.approval_status == 0) {
            arrReject.push(item.field_entrance_history_id);
          }
        });
      })
      return arrReject;
    },

    arrApprove() {
      let arrApprove = [];
      this.selectedList.forEach((id) => {
        this.editedItem.forEach(item => {
          if(item.field_entrance_history_id == id && item.approval_status == 1) {
            arrApprove.push(item.field_entrance_history_id);
          }
        });
      })
      return arrApprove;
    },
    
    allApprove() {
      let allApprove = [];
      this.editedItem.forEach(item => {
        if(item.field_entrance_history_id && item.approval_status == 0) {
          allApprove.push(item.field_entrance_history_id);
        }
      })
      return allApprove;
    },

    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

  },
  methods: {
    getTime(hour,min) {
      if(hour && min){
        return hour +":"+ min;
      }
    },
    onEditable() {
      this.editable = true;
    },
    async onCreate() {
      const editedItem = _.cloneDeep(this.item);
      const ids = editedItem.user_ids.map((items) => items.id);
      editedItem['user_ids'] = ids;
      const result = await Store.dispatch(`${STORE}/post`, editedItem);
      if (!result.hasError) {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.isUpdate = true;
        this.$emit("cancel", this.isUpdate);
      }
    },

    async onSubmit() {
      let field_entrance_times = [];
        this.editedItem.forEach(item => {
          if(item.field_entrance_time_id){
            field_entrance_times.push({
              id: item.field_entrance_time_id,
              admission_time_type: this.covertNameToId(item.admission_time_type),
              admission_time_hour: item.admission_time.split(':')[0],
              admission_time_min: item.admission_time.split(':')[1],
              exit_time_type: this.covertNameToId(item.exit_time_type),
              exit_time_hour: item.exit_time.split(':')[0],
              exit_time_min: item.exit_time.split(':')[1],
            })
          }
      })
      const params = {
        field_entrance_times: field_entrance_times
      };
      const result = await Store.dispatch(`${STORE}/update`, params);
      if (!result.hasError) {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.isUpdate = true;
        this.getDetail();
      }
      this.editable = false;
    },
    covertNameToId(type) {
      let rs = 0;
      EXIT_TIME_TYPE.forEach(item => {
        if(type == item.name) rs = item.id
      });
      return rs;
    },

    async getDetail() {
      const resultDetail = await Store.dispatch(`${STORE}/getDetail`, this.params);
      if (!resultDetail.hasError) {
        this.formUpdate(resultDetail.data.contents.entries);
      }
    },

    openRemoveDialog() {
      this.isApproved = this.arrReject.length == 0;
      if (this.isApproved) {
        this.dialogText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_1,
          text2: DIALOG_REMOVE_TEXT.TEXT_ALL_APPROVE_2,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_OK,
          flag: ACTION_FLAG.REMOVE,
        };
      } else {
        this.dialogText = {
          title: DIALOG_REMOVE_TEXT.TITLE,
          text1: DIALOG_REMOVE_TEXT.TEXT_1,
          text2: DIALOG_REMOVE_TEXT.TEXT_2,
          text3: DIALOG_REMOVE_TEXT.TEXT_3,
          text4: DIALOG_REMOVE_TEXT.TEXT_4,
          btnSubmitText: DIALOG_REMOVE_TEXT.BTN_SUBMIT,
          flag: ACTION_FLAG.REMOVE,
        };
      }
      this.popups.isShowConfirmDialog = true;
    },

    async handleConfirm() {
      switch (this.dialogText.flag) {
        case ACTION_FLAG.REMOVE:
          this.removeItems();
          break;
        case ACTION_FLAG.REJECTED:
          this.rejectItems();
          break;
        case ACTION_FLAG.APPROVED:
          this.approveItems();
          break;
        case ACTION_FLAG.ALL_APPROVED:
          this.approveAll();
          break;
        default: break;
      }
      this.closeDialog();
    },

    closeDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedList = [];
        this.isApproved = false;
        this.dialogText = {
          title: "",
          text1: "",
          text2: "",
          text3: null,
          text4: null,
          btnSubmitText: "",
          flag: 0
        };
      });
    },

    async removeItems() {
      const params = {
        field_entrance_history_ids: this.arrReject
      };
      const result = await Store.dispatch(`${STORE}/deleteResultArrival`, params);
      if (!result.hasError) {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.isUpdate = true;
        this.getDetail();
      }
    },

    async rejectItems() {
      const params = {
        field_entrance_history_ids: this.arrApprove,
        approval_flag: 0
      };
      const result = await Store.dispatch(`${STORE}/updateApproveResultArrival`, params);
      if (!result.hasError) {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認解除しました",
        });
        this.isUpdate = true;
        this.getDetail();
      }
    },

    async approveItems() {
      const params = {
        field_entrance_history_ids: this.arrReject,
        approval_flag: 1
      };
      const result = await Store.dispatch(`${STORE}/updateApproveResultArrival`, params);
      if (!result.hasError) {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認しました",
        });
        this.isUpdate = true;
        this.getDetail();
      }
    },

    async approveAll() {
      const params = {
        field_entrance_history_ids: this.allApprove,
        approval_flag: 1
      };
      const result = await Store.dispatch(`${STORE}/updateApproveResultArrival`, params);
      if (!result.hasError) {
        //成功したら値を更新
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認しました",
        });
        this.isUpdate = true;
        this.getDetail();
      }
    },

    openRejectDialog() {
      this.isApproved = this.arrReject.length == this.selectedList.length;
      if (this.isApproved) {
        this.dialogText = {
          title: DIALOG_TEXT.REJECT_TITLE,
          text1: DIALOG_TEXT.REJECT_ERROR_TEXT1,
          text2: null,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_TEXT.BTN_OK,
          flag: ACTION_FLAG.REJECTED,
        };
      } else {
        this.dialogText = {
          title: DIALOG_TEXT.REJECT_TITLE,
          text1: DIALOG_TEXT.REJECT_TEXT1,
          text2: DIALOG_TEXT.REJECT_TEXT2,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_TEXT.REJECT_BTN_SUBMIT,
          flag: ACTION_FLAG.REJECTED,
        };
      }
      this.popups.isShowConfirmDialog = true;
    },

    openApproveDialog() {
      this.isApproved = this.arrReject.length == 0;
      if (this.isApproved) {
        this.dialogText = {
          title: DIALOG_TEXT.ALL_APPROVE_TTTLE,
          text1: DIALOG_TEXT.ALL_APPROVE_TEXT,
          text2: null,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_TEXT.BTN_OK,
          flag: ACTION_FLAG.APPROVED,
        };
      } else {
        this.dialogText = {
          title: DIALOG_TEXT.APPROVE_TITLE,
          text1: DIALOG_TEXT.APPROVE_TEXT1,
          text2: DIALOG_TEXT.APPROVE_TEXT2,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_TEXT.APPROVE_BTN_SUBMIT,
          flag: ACTION_FLAG.APPROVED,
        };
      }
      this.popups.isShowConfirmDialog = true;
    },

    openApproveAllDialog() {
        this.isApproved = this.allApprove.length == 0;
      if (this.isApproved) {
        this.dialogText = {
          title: DIALOG_ALL_APPROVAL_TEXT.TITLE,
          text1: DIALOG_ALL_APPROVAL_TEXT.TEXT_ALL_APPROVE,
          text2: null,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_ALL_APPROVAL_TEXT.BTN_OK,
          flag: ACTION_FLAG.ALL_APPROVED,
        };
      } else {
        this.dialogText = {
          title: DIALOG_ALL_APPROVAL_TEXT.TITLE,
          text1: DIALOG_ALL_APPROVAL_TEXT.TEXT_1,
          text2: DIALOG_ALL_APPROVAL_TEXT.TEXT_2,
          text3: null,
          text4: null,
          btnSubmitText: DIALOG_ALL_APPROVAL_TEXT.BTN_SUBMIT,
          flag: ACTION_FLAG.ALL_APPROVED,
        };
      }
      this.popups.isShowConfirmDialog = true;
    },

    onChangeForceChecked(value) {
      let updateList = [];
      if(value) {
        updateList = this.editedItem.filter(item => typeof(item.field_entrance_history_id) != "undefined").map(item => item.field_entrance_history_id);
      }
      this.selectedList = updateList;
    },

    onChange(id, value) {
      let updateList = [...this.selectedList];
      if(value) {
        updateList.push(id);
      } else {
        updateList = this.selectedList.filter(item => item != id);
      }
      this.selectedList = [...new Set(updateList)];
    },

    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    formUpdateEdit(params) {
      this.editedItem = _.cloneDeep(params);
    },

    closeForm() {
      if(this.editable) {
        this.isClickCancelBtn = true;
        this.popups.isShowConfirmCloseDialog = true;
      } else {
        this.$emit("cancel", this.isUpdate);
      }
    },

    onClickBtnCancelForm() {
      this.popups.isShowConfirmCloseDialog = false;
      this.isClickCancelBtn = false;
    },

    handleCloseDialog() {
      const item = _.cloneDeep(this.beforeUpdateItem);
      this.formUpdateEdit(item);
      this.editable = false;
      this.popups.isShowConfirmCloseDialog = false;
      if(this.isClickCancelBtn || this.isNewItem) {
        this.$emit("cancel", this.isUpdate);
      }
    },

    handleBack() {
      this.popups.isShowConfirmCloseDialog = true;
    },

    async getEnterDayTime() {  
      const result = await Store.dispatch("Sites/getEnterDayTime", this.CURRENT_SITE.field_id);
      if(!result.hasError) {
        this.enterDayTime = result.data.contents.entries.enter_day_time;
      }
    },

    updateSelectList(updateList) {
      this.selectedList = updateList;
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
