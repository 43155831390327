<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <IconPdf
          v-if="editable"
          class="mr-5"
          @previewPdf="viewPdf"
        />
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="isNewItem"
          :disabled="!isLoaded"
          depressed
          class="mr-5"
          small
          color="primary"
          @click="onCopy"
        >
          前回コピー
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable && item.approval == 0 && formValues['owner_flag'] === 1"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS_CREATE_EDIT_OBAYASHI" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <div>
        <transition name="fade">
          <div class="loading" v-if="!isLoaded">
            <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#61d690'"
            />
          </div>
        </transition>
      </div>
      <ValidationObserver ref="observer" v-slot="observer" v-if="isThisFormLoaded">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ScheduleGateFormObayashiPage1
              :editable="editable"
              :item="formValues"
              :roleUser="roleUser"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              :isBackData="isBackData"
              :statusCopy="statusCopy"
              :gatePassagesCustomize="gatePassagesCustomize"
              @onChooseTimeZone="onChooseTimeZone"
              @resetStatusCopy="statusCopy = false"
              @formUpdate="formUpdate"
              @completeLoading="completeLoading"
            />
          </v-tab-item>
          <v-tab-item>
            <ScheduleGateFormObayashiPage2
              :editable="false"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="1200px" :dialog="isShowPopupPdf">
        <PopupPdf
          :mainHeight="params.mainHeight"
          :fieldDocument="fieldDocument"
          :numPages="numPages"
          @cancel="isShowPopupPdf = false"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ScheduleGateFormObayashiPage1
  from "@/components/forms/schedule/gates/obayashi/ScheduleGateFormObayashiPage1.vue";
import ScheduleGateFormObayashiPage2 from "@/components/forms/schedule/gates/ScheduleGateFormPage3.vue";
import {
  TITLE_FORM_CREATE_EDIT,
  FORMS_CREATE_EDIT_OBAYASHI,
  SCHEDULE_GATE_INITIAL_ITEM_OBAYASHI,
} from "@/constants/SCHEDULE_GATE";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import IconPdf from "@/components/forms/schedule/components/IconPdf";
import PopupPdf from "../../../../../views/schedule/components/PopupPdf";
import pdf from "vue-pdf";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { removeOneDayToEndTime} from "@/constants/CRANES";
import { HalfCircleSpinner } from "epic-spinners";

const STORE = "ScheduleGates";

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TITLE_FORM_CREATE_EDIT,
      FORMS_CREATE_EDIT_OBAYASHI,
      errors: {},
      formValues: {},
      timeZone: {},
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      FIELD_TREE_GROUP_ARRAY: [],
      isShowPopupPdf: false,
      fieldDocument: {},
      numPages: 0,
      roleUser: null,
      statusCopy : false,
      isBackData : false,
      gatePassagesCustomize: {},
      isLoaded: false,
      isThisFormLoaded:false
    };
  },
  components: {
    PopupPdf,
    IconPdf,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    ScheduleGateFormObayashiPage1,
    ScheduleGateFormObayashiPage2,
    pdf,
    HalfCircleSpinner,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    dataInitial : Object
  },
  created() {
    // call condition required and show/off item
    this.getPlanItemSetting();
  },
  mounted() {
    this.checkRoleUser(this.CURRENT_SITE.field_id);
    this.$watch(
      () => this.item,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.roleUser = data;
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getDetail`],
      (data) => {
        // if (!this.editable) {
        //   this.isLoaded = true;
        // }
        if (!this.isNewItem) {
          this.beforeUpdateItem = _.cloneDeep(data);
          if(this.beforeUpdateItem.device_usage_times) {
            this.beforeUpdateItem.device_usage_times = removeOneDayToEndTime(this.beforeUpdateItem.device_usage_times);
          }
          this.beforeUpdateItem["main_user_id"] = this.beforeUpdateItem.device_users?.length > 0 ? this.beforeUpdateItem.device_users[0].field_director_id : null;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.isEmitted,
      () => {
        if (!this.isErrorSubmit) {
          this.editable = false;
        }
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.isNewItem
        ? TITLE_FORM_CREATE_EDIT.NEW
        : TITLE_FORM_CREATE_EDIT.EDIT;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    }
  },
  methods: {
    completeLoading(){
      let selfThis=this;
      setTimeout(function () { selfThis.isLoaded = true;} , 2000);
    },
    async viewPdf() {
      let _params = {
        field_id: this.CURRENT_SITE.field_id,
        type: 1
      };
      let rs = await Store.dispatch(`Sites/getFieldRuleDocument`, { params: _params });
      this.fieldDocument = _.cloneDeep(rs.data.contents.entries.field_rule_documents);
      if (this.fieldDocument.document_url) {
        const params = getParamsOfUrlReadFile(this.fieldDocument.document_url);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          this.fieldDocument.document_url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
        }
        let src = pdf.createLoadingTask(this.fieldDocument.document_url);
        await src.promise.then(pdf => {
          this.numPages = pdf.numPages;
        });
      }
      this.isShowPopupPdf = true;
    },
    async checkRoleUser(field_id) {
      const params = {
        field_id,
        target_role: []
      };
      await Store.dispatch("Sites/getRole", params);
    },
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
      this.isBackData = false;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      this.$emit("submit");
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.editable = false;
        this.isBackData = true;
        this.formUpdate(item);
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onChooseTimeZone(value) {
      this.timeZone = value;
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    /**
     * function copy data last regist
     */
    async onCopy() {
      let params = {
        field_id : this.CURRENT_SITE.field_id,
        is_oba : 1
      }
      const result = await Store.dispatch(`ScheduleGates/getGatePassageLastTime`, { params });
      if (!result.hasError && result.data.contents.entries) {
        let data = _.cloneDeep(result.data.contents.entries);
        data = { ...this.formValues, ...data };
        data["passage_date_from"] = this.item["passage_date_from"];
        data["passage_date_to"] = this.item["passage_date_to"];
        this.statusCopy = true;
        if(data["device_users"].length>0){
          data["main_user_id"]=data["device_users"][0].field_director_id;
        }
        data.device_usage_times = removeOneDayToEndTime(data.device_usage_times);

        this.formValues = _.cloneDeep(data);
      } else {
        const data = _.cloneDeep(SCHEDULE_GATE_INITIAL_ITEM_OBAYASHI);
        data.passage_date_from = this.dataInitial.passage_date_from;
        data.passage_date_to = this.dataInitial.passage_date_to;
        data.field_construction_id = this.dataInitial.field_construction_id;
        if (data.field_construction_id == this.item.field_construction_id) {
          let listCompanyPartnerLevel1 = [...Store.getters["PortalChart/getListCompanyPartnerLevel1"]];
          data.field_tree_id_1 = listCompanyPartnerLevel1[0].field_tree_id;
        }
        data.work_hour_zone = this.dataInitial.work_hour_zone;
        this.formValues = _.cloneDeep(data);
      }
      this.formUpdate(this.formValues);
    },

    async getPlanItemSetting() {
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        company_type: 1 // 1: is obayashi
      };
      let rs = await Store.dispatch("Customizes/get", { params });
      this.gatePassagesCustomize = _.cloneDeep(rs.data.contents.entries)?.gate_passages;
      this.isThisFormLoaded = true;
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>

<style lang="sass" scoped>
  .loading 
    text-align: center
    position: absolute
    color: #fff
    z-index: 9
    padding: 8px 18px
    border-radius: 5px
    left: calc(50% - 45px)
    top: calc(50% - 18px)

  .from-close-btn
    float: right
</style>
