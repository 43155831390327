import Vue from "vue";
import Vuex from "vuex";
import {patrolRecord} from "@/api/modules/patrolRecord";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = patrolRecord;
export const PatrolRecord = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    constructions: [],
    patrolRecord: {},
    detailSafetyDiarySettings: {},
    listPositions: [],
    dataAction: [],
    paginationAction: {},
    isLoading: false,
    dataJump: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_DATA_ACTION(state, payload) {
      state.dataAction = payload;
    },
    SET_PAGINATION_ACTION(state, payload) {
      state.paginationAction = payload;
    },
    SET_DATA_JUMP(state, payload) {
      state.dataJump = payload;
    },
    SET_PATROL_RECORD(state, payload) {
      state.patrolRecord = payload;
    },
    SET_DETAIL_SAFETY_DIARY_SETTINGS(state, payload) {
      state.detailSafetyDiarySettings = payload;
    },
    SET_CHILD_DATA(state, payload) {
      let parent = [];
      let children = [];
      let data = [];
      parent = state.data.find(
        (item) => item.safety_diaries_id === payload.safety_diaries_id
      );
      children = [...parent.children, ...payload.entries];
      data = state.data.map((item) => {
        if (item.safety_diaries_id === payload.safety_diaries_id) {
          const _item = {...item};
          _item.children = children;
          return _item;
        }
        return item;
      });
      state.data = data;
    },
    SET_LIST_POSITIONS(state, payload) {
      state.listPositions = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },

  actions: {
    async get({commit}, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getForAction({commit}, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;
      commit("SET_DATA_ACTION", entries);
      commit("SET_PAGINATION_ACTION", pagination);
      return response?.data?.content?.entries;
    },
    async getListSafetyJump({commit}, payload) {
      const response = await ENTITY.getListSafetyJump(payload);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_DATA_JUMP", entries);
      return response?.data?.contents?.entries;
    },

    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      const detail = response.data.contents;
      commit("SET_PATROL_RECORD", detail.entries);
      return detail?.entries;
    },

    async getDetailNew({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getDetail(payload);
      commit("SET_IS_LOADING", false);
      const detail = response.data.contents;
      commit("SET_PATROL_RECORD", detail.entries);
    },
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    // 承認
    async updateStatus(context, payload) {
      const response = await ENTITY.updateStatus(payload);
      return response;
    },
    async updateStatusReject(context, payload) {
      const response = await ENTITY.updateStatusReject(payload);
      return response;
    },
    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async getDetailSafetyDiarySettings({commit}, payload) {
      const response = await ENTITY.getDetailSafetyDiarySettings(payload);
      const detail = response.data.contents;
      commit("SET_DETAIL_SAFETY_DIARY_SETTINGS", detail.entries);
    },

    async createSafetyDiarySettings(context, payload) {
      const response = await ENTITY.createSafetyDiarySettings(payload);
      return response;
    },

    async loadChildren({commit}, payload) {
      const response = await ENTITY.loadChildren(payload);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_CHILD_DATA", {
        entries,
        safety_diaries_id: payload.params.safety_diary_id,
      });
      return response;
    },

    async getListPositions({commit}, payload) {
      const response = await ENTITY.getListPositions(payload);
      const detail = response.data.contents;
      commit("SET_LIST_POSITIONS", detail.entries.cmn_mst_safety_diary_journal_types);
      return response;
    },

    async report(_context, payload) {
      return await ENTITY.report(payload);
    },

  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getDataAction: (state) => {
      return state.dataAction;
    },
    getPaginationAction: (state) => {
      return state.paginationAction;
    },
    getDetail: (state) => {
      return state.patrolRecord;
    },
    getDetailSafetyDiarySettings: (state) => {
      return state.detailSafetyDiarySettings;
    },
    getListPositions: (state) => {
      return state.listPositions;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
    getListSafetyJump: (state) => {
      return state.dataJump;
    },
  }
};
