<template>
  <v-card flat>
  <v-card-text>
    <ValidationObserver ref="observer" v-slot="observer">
      <div class="dialog-header sticky">
        <div class="dialog-title text-left">{{report_name_jp}}</div>
      </div>
      <v-form ref="form" lazy-validation autocomplete="off">
        <!-- 日付 -->
        <v-list-item v-if="nameFile === 'Grn_All_Work_Detailed_Statement_Payment' && IS_OBAYASHI">
          <v-list-item-content>
            <SwitchInput
              class="ml-2"
              name="is_approved"
              label="所長未承認作業を含まない"
              :values="formValues"
              :editable="true"
              @onInput="onInput"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="nameFile === 'Grn_All_Work_Detailed_Statement_Payment'">
          <v-list-item-content>
            <v-checkbox
              class="ml-2"
              label="請負"
              v-model="check"
              value="請負"
              @change="onCheckbox"
            >
            </v-checkbox>
            <v-checkbox
              class="ml-3"
              label="常傭"
              v-model="check"
              value="常傭"
              @change="onCheckbox"
            >
            </v-checkbox>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content v-if="nameFile !== 'Grn_Deignation_Safety'">
            <!-- 日付 -->
            <Label label="日付（開始）" editable required>
              <InputDatepicker
                name="work_date"
                :values="formValues"
                :editable="true"
                placeholder="yyyy/mm/dd"
                validation_label="日付"
                :validation_rules="getWorkDateRules"
                @onInput="onInput"
                :flagNull="true"
              />
            </Label>
          </v-list-item-content>
          <v-list-item-content v-if="nameFile == 'Grn_Deignation_Safety'">
            <!-- 日付 -->
            <Label label="日付（開始）" editable required>
              <InputDatepicker
                  name="work_date"
                  :values="formValues"
                  :editable="true"
                  placeholder="yyyy/mm/dd"
                  validation_label="日付"
                  :validation_rules="getStartDateRules"
                  @onInput="onInput"
                  :flagNull="true"
              />
            </Label>
          </v-list-item-content>
          <v-list-item-content class="form-date" v-if="nameFile == 'Grn_Crane_Use_Daily' || nameFile == 'Grn_Gate_Passage_Daily'">
            <!-- 日付 -->
            <Label label="日付（終了）" editable required>
              <InputDatepicker
                name="to_date"
                :values="formValues"
                :editable="true"
                placeholder="yyyy/mm/dd"
                validation_label="日付"
                :validation_rules="getToDateRules"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
          <v-list-item-content class="form-date" v-if="nameFile == 'Grn_Deignation_Safety'">
            <!-- 日付 -->
            <Label label="日付（終了）" editable required>
              <InputDatepicker
                  name="to_date"
                  :values="formValues"
                  :editable="true"
                  placeholder="yyyy/mm/dd"
                  validation_label="日付"
                  :validation_rules="getEndDateRules"
                  @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="nameFile == 'Grn_Crane_Use_Daily'">
          <v-list-item-content class="row-position-multi">
            <Label label="揚重" editable required>
              <SelectMulti
                class="select-position-multi"
                name="cranes_ids"
                :values="formValues"
                :items="FIELD_CRANES_ARRAY"
                item_text="crane_name"
                :editable="true"
                validation_label="揚重"
                validation_rules="required"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="nameFile == 'Grn_Gate_Passage_Daily'">
          <v-list-item-content class="row-position-multi">
            <Label label="ゲート" editable required>
              <SelectMulti
                class="select-position-multi"
                name="gate_ids"
                :values="formValues"
                :items="FIELD_GATES_ARRAY"
                item_text="name"
                :editable="true"
                validation_label="ゲート"
                validation_rules="required"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="row-position">
            <Label label="工事" editable required>
              <Select
                class="select-position"
                name="field_construction_id"
                :values="formValues"
                :items="FIELD_CONSTRUCTION_ARRAY"
                :editable="true"
                validation_label="工事"
                validation_rules="required"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="nameFile == 'Grn_Deignation_Safety'">
          <v-list-item-content>
            <Label label="ステータス" editable>
              <SelectMulti
                ref="selectMultiFieldStatusCode"
                class="select-position-multi"
                name="status_code"
                :values="formValues"
                :items="STATUS_CODE_ARRAY"
                item_text="name"
                :editable="true"
                validation_label="ステータス"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="row-position Switch">
            <SwitchInput
              label="作業グループで絞り込む"
              :editable="true"
              @onInput="onOffTeam"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="show">
          <v-list-item-content class="row-position">
            <Label label="作業グループ" editable required>
              <Select
                class="select-position"
                name="field_tree_group_id"
                :values="formValues"
                :items="FIELD_TREE_GROUP_ARRAY"
                item_text="group_name"
                :editable="true"
                validation_label="作業グループ"
                validation_rules="required"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="協力会社" editable style="margin-bottom: -38px; !important" />
            <SpecialEducationInput
              name="field_tree_id"
              :values="formValues"
              :show="show"
              :statusFlag="statusFlag"
              :editable="true"
              :nameFile ="nameFile"
              @onInput="onInput"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="row-position">
            <Label label="作業時間帯" editable required>
              <Select
                class="select-position"
                name="work_hour_zone"
                :values="formValues"
                :items="WORK_PLACE_ARRAY"
                :editable="true"
                validation_label="作業時間帯"
                validation_rules="required"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
      </v-form>
      <ValidationCallback :observer="observer" @callback="updateValidate" />
    </ValidationObserver>
    <div class="dialog-header sticky pt-8 pb-6">
      <div class="dialog-title text-left"></div>
      <v-btn
        depressed
        small
        outlined
        color="primary"
        class="mx-1 px-4"
        @click="onCancel"
      >
        やめる
      </v-btn>
      <v-btn
        depressed
        small
        :disabled="!valid || isSubmitted || !validCheckbox"
        color="primary"
        class="mx-1 px-5"
        @click="onSubmit"
      >
        作成
      </v-btn>
    </div>
  </v-card-text>
  </v-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import SingleLayout from "@/components/layout/SingleLayout";
import InputText from "@/components/forms/elements/InputText.vue";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Label from "@/components/forms/elements/Label.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import Select from "@/components/forms/elements/Select.vue";
import { Store } from "@/store/Store.js";
import { USER_ROLE, REPORT_INITIAL_ITEM } from "@/constants/SAFETY_FORM_PRINT";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import SpecialEducationInput from "./SpecialEducationInput.vue";
import SelectMulti from "./SelectMulti.vue";
import {ENV_CLIENT} from "@/constants/ENV_CLIENT";
const INITIAL_ITEM = REPORT_INITIAL_ITEM;
import { format } from "date-fns";
import {STATUS_CODE_ARRAY} from "@/constants/PATROL_RECORD";
import moment from "moment";

/**
 * 元請けからの招待フォーム
 * 1次請けを招待するフォーム
 */
export default {
  components: {
    SingleLayout,
    ValidationCallback,
    ValidationObserver,
    ConfirmDialog,
    InputText,
    InputDatepicker,
    Label,
    Select,
    SwitchInput,
    SpecialEducationInput,
    SelectMulti,
  },
  data: () => {
    return {
      userRole: false,
      valid: false,
      formValues: { ...INITIAL_ITEM },
      FIELD_CONSTRUCTION_ARRAY: [],
      FIELD_TREE_GROUP_ARRAY: [],
      WORK_PLACE_ARRAY: [],
      isSubmitted: false,
      timeout: null,
      fieldValues:false,
      show: false,
      ENV_CLIENT,
      FIELD_CRANES_ARRAY: [],
      FIELD_GATES_ARRAY: [],
      check: ['請負', '常傭'],
      validCheckbox: true,
      userRolepartner: false,
      STATUS_CODE_ARRAY
    };
  },
  computed: {
    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
    getToDateRules() {
      return this.formValues.work_date
          ? "required|" + `compare-to-start-date:${this.formValues.work_date.replaceAll("-", "/")}`
          : "required";
    },
    getWorkDateRules() {
      return this.formValues.to_date
          ? "required|" + `compare-to-end-date:${this.formValues.to_date.replaceAll("-", "/")}`
          : "required";
    },
    getStartDateRules() {
      return this.formValues.to_date ? "required|" + `compare-to-end-date:${this.formValues.to_date?.replaceAll("-",
          "/")}|compare-schedule-end-date:${this.formValues.to_date?.replaceAll(
          "-",
          "/"
      )}` : "required";
    },
    getEndDateRules() {
      return this.formValues.work_date ? "required|" + `compare-to-start-date:${this.formValues.work_date?.replaceAll("-",
          "/")}|compare-schedule-start-date:${this.formValues.work_date?.replaceAll(
          "-",
          "/"
      )}` : "required";
    },
  },
  props: {
    statusFlag: {
      type: Boolean,
      default: false
    },
    nameFile: {
      type: String,
    },
    report_name_jp:{
      type: String,
    }
  },
  created() {
    if(this.nameFile === 'Grn_All_Work_Detailed_Statement_Payment' && 
      ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT)  {
      this.formValues["is_approved"] = true;
    }
  },
  mounted() {
    if(this.nameFile === "Grn_All_Adjust_Work_and_Safety" || this.nameFile === "Grn_Crane_Use_Daily" || this.nameFile === "Grn_Gate_Passage_Daily"){
      this.formValues.work_date= format(new Date().setDate(new Date().getDate()+1), "yyyy-MM-dd");
      this.formValues.to_date= format(new Date().setDate(new Date().getDate()+1), "yyyy-MM-dd");
    }
    if(this.nameFile === "Grn_Deignation_Safety"){
      this.formValues.to_date= format(new Date(), "yyyy-MM-dd");
      var endDate = moment(this.formValues.to_date);
      var lastMonth = moment(endDate).subtract(1, "months");
      if (endDate.date() == 28 && endDate.month() == 2 && endDate.year() % 4 !== 0) {
        lastMonth.set({ "year": endDate.year(), "month": 2, "date": 1 });
      } else if (endDate.date() == 28 && endDate.month() == 2 && endDate.year() % 4 == 0) {
        lastMonth.set({ "year": endDate.year(), "month": 1, "date": 29 });
      } else if (endDate.date() >= 29 && endDate.month() == 2) {
        lastMonth.set({ "year": endDate.year(), "month": 2, "date": 1 });
      } else {
        lastMonth.set('date', endDate.date() + 1);
      }
      this.formValues.work_date = format(new Date(lastMonth), "yyyy-MM-dd");
      this.formValues.status_code = [1, 7]
      this.$refs.selectMultiFieldStatusCode.updateDataSelected(this.formValues.status_code)
    }
    this.getListConstruction();
    if(this.nameFile === "Grn_Gate_Passage_Daily") {
      this.getListGateByField();
    }
    if(this.nameFile === "Grn_Crane_Use_Daily") {
      this.getListCraneByField();
    }
    if(this.nameFile === "Grn_All_Work_Detailed_Statement_Payment") {
      this.onCheckbox();
    }
    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        if (this.formValues["field_construction_id"]) {
          this.formValues["work_hour_zone"] = data[0].id;
          this.WORK_PLACE_ARRAY = [...data];
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Report/getUserRole"],
      (data) => {
        if (data.role == USER_ROLE.PARTNERCOMPANY.id) {
          this.userRole = true;
          this.userRolepartner = true;
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Cranes/getListCraneByField"],
      (data) => {
        if (data) {
          this.FIELD_CRANES_ARRAY = data;
        }
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Gates/getListGateByField"],
      (data) => {
        if (data) {
          this.FIELD_GATES_ARRAY = data;
        }
      },
      {
        deep: true,
      }
    );
    if (!(this.nameFile === 'Grn_Crane_Use_Daily' || this.nameFile === 'Grn_Gate_Passage_Daily' || this.nameFile === 'Grn_Deignation_Safety')) {
      delete this.formValues['to_date'];
    }
  },
  methods: {
    async getListConstruction() {
      const response = await Store.dispatch(
        "PortalChart/getWorkTypes",
        this.CURRENT_SITE.field_id
      );
      this.FIELD_CONSTRUCTION_ARRAY = [...response.data.contents.entries];
      if (this.FIELD_CONSTRUCTION_ARRAY.length > 0) {
        if (!this.formValues["field_construction_id"]) {
          this.formValues["field_construction_id"] =
            this.FIELD_CONSTRUCTION_ARRAY[0].id;
        }
        this.getListGroups();
        this.getWorkTimeZones();
      }
    },
    async getWorkTimeZones() {
      await Store.dispatch(
        "PortalChart/getFieldTimeZones",
        this.formValues["field_construction_id"]
      );
    },
    async getListGroups() {
      const params = {
        field_construction_id: this.formValues["field_construction_id"],
        all_group_flag: 1, // get all of group
      };
      const response = await Store.dispatch(
        "PortalChart/getListGroups",
        params
      );
      this.FIELD_TREE_GROUP_ARRAY = [
        this.getDefaultArrays("id", "group_name"),
        ...response.data.contents.entries,
      ];
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    getDataByName(name, value) {
      switch (name) {
        case "field_construction_id": {
          this.formValues.field_tree_group_id = null;
          this.formValues.field_tree_id = null;
          this.FIELD_TREE_GROUP_ARRAY = [
            this.getDefaultArrays("id", "group_name"),
          ];
          this.COMPANY_PARTNER_ARRAY = [
            this.getDefaultArrays("field_tree_id", "company_name"),
          ];
          if (value) this.getListGroups();
          break;
        }
        case "work_date":{
          let new_value=value.replaceAll("/", "-");
          let old_value=this.formValues.to_date.replaceAll("/", "-");
          if(new_value > old_value)
            this.formValues.to_date=new_value;
          break;
        }
        case "to_date":{
          let new_value=value.replaceAll("/", "-");
          let old_value=this.formValues.work_date.replaceAll("/", "-");
          if(new_value < old_value)
            this.formValues.work_date=new_value;
          break;
        }
        default:
          break;
      }
    },
    /**
     * フォームの値を更新
     */
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.getDataByName(name, value);
    },
    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },
    /**
     * フォームを閉じる
     */
    onCancel() {
      this.$emit("onCancel");
    },

    /**
     * フォームを送信
     */
    onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      if (!(this.nameFile === 'Grn_Crane_Use_Daily' || this.nameFile === 'Grn_Gate_Passage_Daily' || this.nameFile === 'Grn_Deignation_Safety')) {
        delete this.formValues['to_date'];
      }
      if (this.userRolepartner 
        && (this.nameFile === 'Grn_Work_Total' 
        || this.nameFile === 'Grn_All_Work_Detailed_Statement_Payment')
      ) {
        this.formValues['is_partner'] = 1;
      }
      this.$emit("onSubmit", this.formValues);
    },
    onOffTeam() {
      this.show = ! this.show;
    },
    async getListGateByField() {
      const response = await Store.dispatch(
        "Gates/getListGateByField",
        this.CURRENT_SITE.field_id + "?soft_aiueo=1"
      );
      this.FIELD_GATES_ARRAY = [...response.data.contents.entries];
    },
    async getListCraneByField() {
      const response = await Store.dispatch(
        "Cranes/getListCraneByField",
        this.CURRENT_SITE.field_id + "?soft_aiueo=1"
      );
      this.FIELD_CRANES_ARRAY = [...response.data.contents.entries];
    },
    onCheckbox() {
      let valueCheck  = [...this.check];
      const formValues = {...this.formValues};
      let value;
      let name = 'ukeoi_joyo_flg';
      this.validCheckbox = false
      if (valueCheck.includes('請負') && valueCheck.includes('常傭')) {
        value = 0;
        this.validCheckbox = true;
      } 
      if (valueCheck.includes('請負') && !valueCheck.includes('常傭')) {
        value = 1;
        this.validCheckbox = true;
      }
      if (!valueCheck.includes('請負') && valueCheck.includes('常傭')) {
        value = 2;
        this.validCheckbox = true;
      }
      formValues[name] = value;
      this.formValues = formValues;
      this.getDataByName(name, value);
    }
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialog.scss";

.row-position {
  margin-top: -30px;
  height: 110px;
  position: relative;
}

.row-position-multi {
  margin-top: -30px;
  margin-bottom: 30px;
  display: block !important;
}

.select-position {
  width: 100%;
  position: absolute;
}

.v-list-item__content {
    align-items: flex-start !important;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    overflow: hidden;
    padding: 12px 0;
}

.row-position.Switch {
  align-items: flex-end !important;
  padding-left: 12px;
}

.row-position.partner {
  flex: 5.5;
}

.row-position.btn {
  align-items: flex-end !important;
  margin-left: 15px;
  padding-bottom: 25px;
  padding-right: 15px;
  flex: 1;
}

.select-position-multi {
  width: 100%;
}
</style>
