<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.page_count >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <HistoryHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
              @openItemForm="openNewItemForm"
              v-if="searchParams.table_type === TAB_ENTRANCE_HISTORY"
            >
              <v-row>
                <v-col sm="11" md="11">
                  <SearchFormWrapper>
                    <Label label="協力会社" width="300px">
                      <Select
                        name="field_tree_id"
                        :items="COMPANY_PARTNER_ARRAY"
                        :editable="true"
                        item_text="company_name"
                        item_value="field_tree_id"
                        :values="searchParams"
                        validation_label="協力会社"
                        validation_rules=""
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <Label label="氏名">
                      <InputText
                        name="user_name"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                    <TabSelect
                      class="mt-8"
                      name="admission_status"
                      :items="ENTERING_STATUS"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </SearchFormWrapper>
                </v-col>
                <v-col sm="1" md="1" class="text-right">
                  <v-btn
                    class="mr-6 ma-2"
                    depressed
                    color="primary"
                    @click="onSearch()"
                  >
                    検索
                  </v-btn>
                </v-col>
              </v-row>
            </HistoryHeader>
            <PanelImageHeader v-else :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader" @zoomOut="zoomOut" @openDisplaySettingPanelForm="openDisplaySettingPanelForm">
            </PanelImageHeader>
            <TableTab
              :items="ENTRANCE_HISTORY_TABLE_TAB_ITEMS"
              @onChangeTableTab="onChangeTableTab"
            >
              <v-container
                class="tableSortWrapper"
                v-if="searchParams.table_type === TAB_ENTRANCE_HISTORY"
              >
                <v-spacer></v-spacer>
                <div class="sortLabel">総件数:</div>
                <div class="sortElement total_item">
                  {{ searchParams.all_record }}件
                </div>
              </v-container>
            </TableTab>
          </template>
          <template #tableBody="{ tableHeight }">
            <HistoryTable
              v-show="searchParams.table_type === TAB_ENTRANCE_HISTORY"
              :tableLabels="TABLE_LABELS"
              :tableHeight="tableHeight"
              :items="items"
              :isNoDataMessage="isNoDataMessage"
              :itemsPerPage="searchParams.page_count"
              :getChildItems="getChildItems"
              @openItemForm="openItemForm"
            />
            <PanelImage v-show="searchParams.table_type === TAB_PANEL_IMAGE" :tableHeight="tableHeight"> </PanelImage>
          </template>
          <template #tableFooter v-if="searchParams.table_type === TAB_ENTRANCE_HISTORY">
            <Pagination
              :current="searchParams.current_page"
              :total="searchParams.total_page"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <!--  (社員用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <HistoryForm
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
      />
    </Popup>
    <Popup :dialog="popups.isShowDisplaySettingPanel">
      <DisplaySettingPanel
        @closeForm="closeDisplaySettingPanelForm"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import Pagination from "@/components/masterTable/elements/Pagination";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import HistoryHeader from "./components/HistoryHeader";
import HistoryTable from "./components/HistoryTable.vue";
import PanelImageHeader from "./components/PanelImageHeader.vue";
import PanelImage from "./components/PanelImage.vue";
import DisplaySettingPanel from "./components/DisplaySettingPanel.vue";
import { HEADER_MENU_ITEMS_SITE } from "@/constants/GLOBALHEADER";
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TabSelect from "@/components/forms/elements/TabSelect"; //絞り込みフォームで使用
import TableTab from "@/views/schedule/components/TableTab"; //テーブルの切り替え
import {
  ENTERING_STATUS,
  TABLE_HEADER_LABELS,
  INITIAL_ITEM,
  ENTRANCE_HISTORY_TABLE_TAB_ITEMS,
} from "@/constants/ENTRANCE_HISTORY";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import HistoryForm from "@/components/forms/entrances/history/HistoryForm";
import _ from "lodash";
import InputText from "@/components/forms/elements/InputText.vue";
import { ROLE_SUPERVISOR, ROLE_FOREMAN} from "@/constants/COMMON.js";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";


const PAGE_TITLE = "入退場履歴";

const PAGE_COUNT = 30;
const TAB_ENTRANCE_HISTORY = 1;
const TAB_PANEL_IMAGE = 2 ;


const STORE = "EntranceHistory";

const TABLE_LABELS = TABLE_HEADER_LABELS;

export default {
  head: {
    title() {
      return { inner: "GREEN", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      TAB_ENTRANCE_HISTORY,
      TAB_PANEL_IMAGE,
      field_id: null,
      PAGE_TITLE,
      TABLE_LABELS,
      items: [],
      searchParams: {
        field_tree_id: null,
        admission_status: [],
        user_name: null,
        page_count: PAGE_COUNT,
        current_page: 1,
        total_page: 1,
        total_item: 0,
        all_record: 0,
        table_type: ENTRANCE_HISTORY_TABLE_TAB_ITEMS.LIST.id,
      },
      COMPANY_PARTNER_ARRAY: [],
      popups: {
        isShowItemForm: false,
        isShowDisplaySettingPanel : false
      },
      isNewItem: true,
      editedItem: {},
      isErrorSubmit: false,
      isEmitted: false,
      isNoDataMessage: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    Pagination,
    SitePortalHeader,
    HistoryHeader,
    HistoryTable,
    Select,
    Label,
    SearchFormWrapper,
    TabSelect,
    Popup,
    HistoryForm,
    InputText,
    TableTab,
    PanelImage,
    PanelImageHeader,
    DisplaySettingPanel
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.$watch(
      () => Store.getters[`${STORE}/getAdmissionStatus`],
      (data) => {
        if(data.length > 0){
          this.searchParams.admission_status = [...data];
          Store.dispatch("EntranceHistory/updateAdmissionStatus",[]);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    await this.getItems();
    await Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_SITE.ENTRANCE.id,
    });

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data && JSON.stringify(data) !== JSON.stringify(oldData) && data.field_id) {
          this.field_id = data.field_id;
          this.checkRoleUser(this.field_id);
          this.getItems();
          this.getListCompanyPartner();
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        if (data && data !== ROLE_SUPERVISOR && data !== ROLE_FOREMAN) {
          Store.dispatch("Error/show", {
            status: 401,
            message: "操作する権限が存在しません。",
          });
          this.$router.push("/portal/dashboard");
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = data[0];
        this.isNoDataMessage = this.items.length == 0;
        let searchParams = { ...this.searchParams };
        searchParams.total_page = data[1].total;
        searchParams.current_page = parseInt(data[1].current);
        searchParams.total_item = data[1].total_item;
        searchParams.all_record = data[1].all_record;
        this.searchParams = { ...searchParams };
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getListCompanyPartner"],
      (value) => {
        this.COMPANY_PARTNER_ARRAY = [
          { field_tree_id: null, company_name: "" },
          ...value,
        ];
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getListCompanyMainContructorAndPartner"],
      (value) => {
        this.COMPANY_PARTNER_ARRAY = [
          { field_tree_id: null, company_name: "" },
          ...value,
        ];
      },
      {
        deep: true,
      }
    );
  },

  computed: {
    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.CURRENT_SITE.field_id,
        field_tree_id: this.searchParams.field_tree_id,
        user_name: this.searchParams.user_name,
        admission_status: this.searchParams.admission_status,
        page_number: this.searchParams.current_page,
      };
    },
    ENTERING_STATUS() {
      return Object.keys(ENTERING_STATUS).map((key) => {
        return ENTERING_STATUS[key];
      });
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    ENTRANCE_HISTORY_TABLE_TAB_ITEMS() {
      return Object.keys(ENTRANCE_HISTORY_TABLE_TAB_ITEMS).map((key) => {
        return ENTRANCE_HISTORY_TABLE_TAB_ITEMS[key];
      });
    },
  },
  methods: {
    zoomOut(){
      let routeData = this.$router.resolve({name: 'ViewPanel', query: {}});
      window.open(routeData.href, '_blank');
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.current_page = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["current_page"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    async getChildItems(id, pageNumber) {
      let searchParams = { ...this.searchParams };
      searchParams["field_tree_id"] = id;
      searchParams["page_number"] = pageNumber;
      delete searchParams.total_item;
      delete searchParams.page_count;
      delete searchParams.current_page;
      delete searchParams.total_page;
      const params = {
        params: searchParams,
      };
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
        params['is_oba']=1;
      }
      await Store.dispatch(`${STORE}/loadChildren`, params);
    },

    async getItems() {
      if (this.field_id) {
        if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
          this.apiParams['is_oba']=1;
        }
        await Store.dispatch(`${STORE}/getListHistory`, {
          params: this.apiParams,
        });
      }
    },

    async getListCompanyPartner() {
      if (this.field_id) {
        let params = {
          field_id: this.field_id,
          manager_or_foreman_flg: 1
        };
        if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
          params['is_oba']=1;
          await Store.dispatch("Sites/getListCompanyMainContructorAndPartner", { params });
        }
        else
        {
          await Store.dispatch("Sites/getListCompanyPartner", { params });
        }
      }
    },
    openNewItemForm() {
      this.editedItem = _.cloneDeep(INITIAL_ITEM);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(id) {
      const result = await Store.dispatch(`${STORE}/getDetail`, id);
      // Show Detail Form
      if (!result.hasError) {
        this.isNewItem = false;
        this.editedItem = _.cloneDeep(result.data.contents.entries);
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
      });
    },
    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const editedItem = { ...this.editedItem };
      if(!hasId) {
        const ids = editedItem.user_ids.map((items) => items.id);
        editedItem['user_ids'] = ids;
      }
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      const result = await Store.dispatch(
        //idがある場合は更新、ない場合は新規
        hasId ? `${STORE}/update` : `${STORE}/post`,
        editedItem
      );
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        await Store.dispatch(
          `${STORE}/getDetail`,
          editedItem.id
        );
        this.isErrorSubmit = false;
      } else {
        //成功したら値を更新
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },
    onChangeTableTab(item) {
      const searchParams = { ...this.searchParams };
      searchParams.table_type = item.id;
      this.searchParams = searchParams;
    },
    openDisplaySettingPanelForm(){
      this.popups.isShowDisplaySettingPanel = true;
    },
    closeDisplaySettingPanelForm(){
      this.popups.isShowDisplaySettingPanel = false;
    },
    async checkRoleUser(field_id){
      if (!field_id) return;
      const params = {
        field_id,
        target_role: []
      };
      await Store.dispatch("Sites/getRole", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 168px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
  .button_search {
    margin-right: 28px;
  }
}
</style>
