<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters v-if="isNewItem">
                    <v-col cols="12" sm="12" md="12">
                      <v-switch
                      v-model="isHideEndDate"
                      label="繰り返し予定を入力する"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="workDate">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="日付（開始）"
                            :editable="editable"
                            required
                          >
                            <InputDatepicker
                              name="use_date_from"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（開始）"
                              :validation_rules="getStartDateRules()"
                              @onInput="onInput('', $event)"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="isHideEndDate" class="workDate">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="日付（終了）"
                            :editable="editable"
                            required
                          >
                            <InputDatepicker
                              name="use_date_to"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（終了）"
                              :validation_rules="getEndDateRules()"
                              @onInput="onInput('', $event)"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="作業時間帯"
                            :editable="editable"
                            required
                          >
                            <Select
                              name="work_hour_zone"
                              :values="formValues"
                              :editable="editable"
                              :items="working_time_zone"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 日付 -->
                          <Label label="日付" :editable="editable" required>
                            <InputDatepicker
                              name="use_date"
                              :values="formValues"
                              :editable="editable"
                              placeholder=""
                              validation_label="日付"
                              validation_rules="required"
                              @onInput="onInput('', $event)"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item :class="editable ? '' : 'mt-2'">
                        <v-list-item-content>
                          <Label
                            label="作業時間帯"
                            :editable="editable"
                            required
                          >
                            <Select
                              name="work_hour_zone"
                              :values="formValues"
                              :editable="editable"
                              :items="working_time_zone"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="!editable">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="時間" :editable="!editable">
                            <v-text-field
                              :label="getProhibitTime()"
                              dense
                              color="primary"
                              :disabled="!editable"
                              :filled="editable"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="開始時間" :editable="editable" required>
                            <Select
                              name="usage_start_time"
                              :values="dataUsageTime"
                              :items="cmn_mst_time_start"
                              :editable="editable"
                              validation_label="開始時間"
                              validation_rules="required"
                              autoCenter
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="終了時間" :editable="editable" required>
                            <Select
                              name="usage_end_time"
                              :values="dataUsageTime"
                              :items="cmn_mst_time_end"
                              :editable="editable"
                              validation_label="終了時間"
                              validation_rules="required"
                              autoCenter
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('field_tree_id_1')">
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="協力会社" :editable="editable" :class="editable && !enableFieldTreeId1 ? 'mt-2':''" :required="enableFieldTreeId1 && checkRequired('field_tree_id_1')">
                            <SelectWithFilter
                              name="field_tree_id_1"
                              :values="formValues"
                              :editable="editable && enableFieldTreeId1"
                              :enableFieldTreeId1="enableFieldTreeId1"
                              :items="COMPANY_PARTNER_LEVEL_1_ARRAY"
                              item_value="field_tree_id"
                              item_text="company_name"
                              validation_label="協力会社"
                              :validation_rules="enableFieldTreeId1 && checkRequired('field_tree_id_1')?'required':''"
                              :label="String(formValues.field_tree_company_name_1 || '')"
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="checkShow('job_id')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="職種" :editable="editable" :required="checkRequired('job_id') && !isSelectPrimeCompany">
                            <Select
                              name="job_id"
                              :values="formValues"
                              :items="JOB_ARRAY"
                              :editable="editable && !isSelectPrimeCompany"
                              item_text="category"
                              item_value="category_id"
                              @onInput="onInput('', $event)"
                              :label="formValues.job_name || ''"
                              validation_label="職種"
                              :validation_rules="checkRequired('job_id') && !isSelectPrimeCompany ?'required':''"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="checkShow('field_tree_user_id')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="担当者氏名" :editable="editable" :required="checkRequired('field_tree_user_id')">
                            <span @click="editable ? (isSelectPrimeCompany ?  openPopUpSearchManager('field_tree_user_id') : openPopUpSearchUser()) : null" class="select">
                              <span class="select_wrap">
                                <Select
                                  name="field_tree_user_id"
                                  :values="formValues"
                                  :items="FIELD_TREE_USER_ARRAY"
                                  item_text="user_name"
                                  item_value="field_tree_user_id"
                                  :editable="editable"
                                  @onInput="onInput('', $event)"
                                  validation_label="担当者氏名"
                                  :validation_rules="checkRequired('field_tree_user_id')?'required':''"
                                />
                              </span>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" v-if="checkShow('field_tree_user_tel')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="担当者連絡先" :editable="editable" :required="checkRequired('field_tree_user_tel')">
                            <InputText
                              name="field_tree_user_tel"
                              :values="formValues"
                              :editable="editable"
                              placeholder="03-1234-5678"
                              validation_label="担当者連絡先（電話番号）"
                              :validation_rules="checkRequired('field_tree_user_tel')?'required|phone':'phone'"
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="checkShow('field_director_id')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="元請担当者" :editable="editable" :required="checkRequired('field_director_id')">
                            <span @click="editable ? openPopUpSearchManager() : null" class="select">
                              <span class="select_wrap">
                                <Select
                                  name="field_director_id"
                                  :values="deviceUsers"
                                  :items="MANAGER_ARRAY"
                                  item_text="user_name"
                                  :editable="editable"
                                  @onInput="onInput('', $event)"
                                  validation_label="元請担当者"
                                  :validation_rules="checkRequired('field_director_id')?'required':''"
                                />
                              </span>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="使用揚重機"
                            :editable="editable"
                            required
                          >
                            <Select
                              name="crane_id"
                              :values="formValues"
                              :items="CRANES_BY_FIELD"
                              :editable="editable"
                              item_text="crane_name"
                              @onInput="onInput('', $event)"
                              validation_label="使用揚重機"
                              validation_rules="required"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters :style="editable?'':'margin-top: 12px;'">
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="吊荷" :editable="editable" required>
                            <InputText
                              name="suspended_load"
                              :values="formValues"
                              :editable="editable"
                              @onInput="onInput('', $event)"
                              validation_label="吊荷"
                              validation_rules="required|max:255"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn
                        color="#1B9C4F"
                        class="white--text"
                        width="5vw"
                        style="top: 44px; float: right;"
                        @click="openHistoryPopup(HISTORY_TYPE.SUSPENDED_LOAD)"
                      >
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('work_details')">
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業内容" :editable="editable" :required="checkRequired('work_details')">
                            <InputText
                              name="work_details"
                              :values="formValues"
                              :editable="editable"
                              @onInput="onInput('', $event)"
                              validation_label="作業内容"
                              :validation_rules="checkRequired('work_details')?'required|max:255':'max:255'"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn
                        color="#1B9C4F"
                        class="white--text"
                        width="5vw"
                        style="top: 44px; float: right;"
                        @click="openHistoryPopup(HISTORY_TYPE.WORK_DETAILS)"
                      >
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('field_item_tree_id')">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position-location-v2">
                          <Label label="場所" :editable="editable" style="margin-top: -51px;"
                            :required="checkRequired('field_item_tree_id')" />
                          <Select
                            name="field_item_tree_id"
                            class="select-position-location"
                            :values="formValues"
                            :items="WORK_PLACE"
                            :editable="editable"
                            item_text="item_name"
                            @onInput="onInput('', $event)"
                            validation_label="場所"
                            :validation_rules="checkRequired('field_item_tree_id')?'required':''"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('gate_id')">
                    <v-col cols="12" sm="6" md="6" class="mt-4">
                      <v-list-item>
                        <v-list-item-content class="row-position-location-v2">
                          <Label label="ゲート" :editable="editable" :required="checkRequired('gate_id')" style="margin-top: -51px;" />
                          <Select
                            name="gate_id"
                            class="select-position-location"
                            :values="formValues"
                            :items="GATE_ARRAY"
                            :editable="editable"
                            @onInput="onInput(GATE_PASSAGES, $event)"
                            validation_label="ゲート"
                            :validation_rules="checkRequired('gate_id')?'required':''"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="mt-4">
                      <v-list-item>
                        <v-list-item-content class="row-position-location-v2">
                          <Label label="車種" :editable="editable" style="margin-top: -51px;" />
                          <Select
                            name="gate_cmn_mst_vehicle_type_id"
                            class="select-position-location"
                            :values="formValues"
                            :items="VEHICLE_ARRAY"
                            item_text="vehicle_type"
                            :editable="editable && enableGroupGate"
                            @onInput="onInput(GATE_PASSAGES, $event)"
                            validation_label="車種"
                            validation_rules=""
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="mt-4">
                      <v-list-item>
                        <v-list-item-content class="row-position-location-v2">
                          <Label label="台数" :editable="editable" style="margin-top: -51px;" />
                          <Select
                            name="gate_truck_count"
                            class="select-position-location"
                            :values="formValues"
                            :items="COUNT_ARRAY"
                            :editable="editable && enableGroupGate"
                            @onInput="onInput(GATE_PASSAGES, $event)"
                            validation_label="台数"
                            validation_rules=""
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('gate_id')">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="ステータス" :editable="editable">
                            <Select
                              name="gate_status_code"
                              class="select-position"
                              :values="formValues"
                              :items="STATUS_CODE_ARRAY"
                              :editable="editable && enableGroupGate"
                              @onInput="onInput(GATE_PASSAGES, $event)"
                              validation_label="ステータス"
                              validation_rules=""
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="row-position">
                          <Label label="運送会社" :editable="editable">
                            <InputText
                              class="select-position"
                              name="gate_shipping_company"
                              :values="formValues"
                              :editable="editable && enableGroupGate"
                              validation_label="運送会社"
                              validation_rules="max:255"
                              @onInput="onInput(GATE_PASSAGES, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="運送会社電話番号" :editable="editable" class="mt-10">
                            <InputText
                              name="gate_shipping_company_tel"
                              :values="formValues"
                              :editable="editable && enableGroupGate"
                              placeholder="03-1234-5678"
                              validation_label="運送会社電話番号"
                              validation_rules="phone|max:16"
                              @onInput="onInput(GATE_PASSAGES, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('gate_id')">
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="荷種" :editable="editable" :required="enableGroupGate && checkRequired('gate_id')">
                            <InputText
                              name="commodity_type"
                              :values="formValues"
                              :editable="editable && enableGroupGate"
                              validation_label="荷種"
                              :validation_rules="enableGroupGate && checkRequired('gate_id')?'required|max:255':''"
                               @onInput="onInput(GATE_PASSAGES, $event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn color="#1B9C4F" class="white--text" width="5vw" style="bottom: -46px;float: right"
                        :disabled="!editable || !formValues.gate_id"
                        @click="openHistoryPopup(HISTORY_TYPE.COMMODITY_TYPE)"
                      >
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('gate_id')">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="備考" :editable="editable">
                            <InputText
                              name="gate_remarks"
                              :values="formValues"
                              :editable="editable && enableGroupGate"
                              @onInput="onInput(GATE_PASSAGES, $event)"
                              validation_label="備考"
                              validation_rules="max:512"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup width="480px" :dialog="isShowHistoryDialog">
        <HistorySelectDialog
          :field_tree_id="formValues.field_tree_id"
          :historyType="historyType"
          :placeHolder="historyType===HISTORY_TYPE.COMMODITY_TYPE?'荷種':'作業詳細'"
          @close="closeHistoryDialog"
          @updateCommentHistory="updateCommentHistory"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowSearchUserDialog">
        <SearchUserDialog
          title="担当者選択"
          :field_construction_id="formValues.field_construction_id"
          :field_tree_id_1="formValues.field_tree_id_1"
          :job_id="formValues.job_id"
          @close="isShowSearchUserDialog = false"
          @updateData="updateUser"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowSearchManagerDialog">
        <SearchManagerDialog
          title="元請担当者選択"
          :field_construction_id="formValues.field_construction_id"
          :job_id="formValues.job_id"
          @close="isShowSearchManagerDialog = false"
          @updateData="updateManager"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import SearchUserDialog from "../components/popup_search/SearchUserDialog.vue";
import SearchManagerDialog from "../components/popup_search/SearchManagerDialog.vue";
import HistorySelectDialog from "../components/popup_search/HistorySelectDialog.vue";
import Popup from "@/components/common/Popup";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter.vue";

import {
  OWNER_FLAG,
  cmn_mst_time,
  STATUS_CODE_ARRAY,
  HISTORY_TYPE,
} from "@/constants/SCHEDULE_CRANE";
import { COUNT_ARRAY } from "@/constants/SCHEDULE_GATE";
import _ from "lodash";
import { ROLE_USER } from "@/constants/DEVICE_STOCK";
// const GATE_PASSAGES = "gate_passages";
const GATE_PASSAGES = '';
const IS_CUSTOMIZE = 1;
import { cmn_mst_time_start, cmn_mst_time_end } from "@/constants/CRANES";
import { addMinutesToTime } from "@/constants/COMMON";


export default {
  data: () => {
    return {
      cmn_mst_time_start,
      cmn_mst_time_end,
      WORKING_TIME_ZONE: Store.state.PortalChart.listTimeZones,
      ROLE_USER,
      COMPANY_PARTNER_LEVEL_1_ARRAY: [{ field_tree_id: null, company_name: "" }],
      time_start: [],
      time_end: [],
      formValues: {},
      dataUsageTime: {},
      deviceUsers: {},
      WORK_TYPES: [],
      working_group_select: [],
      listCompanyPartner: [],
      listCompanyPartnerUser: [],
      user_experiences: [],
      working_time_zone: [],
      WORK_PLACE: [],
      JOB_ARRAY: [{ category_id: null, category: "" }],
      FIELD_TREE_USER_ARRAY: [{ field_tree_user_id: null, user_name: "" }],
      MANAGER_ARRAY: [{ id: null, user_name: "" }],
      CRANES_BY_FIELD: [],
      STATUS_CODE_ARRAY,
      COUNT_ARRAY,
      GATE_ARRAY: [],
      VEHICLE_ARRAY: [{ id: null, vehicle_type: "" }, ...Store.state.CmnMst.constants.entries.cmn_mst_vehicle_types],
      OWNER_FLAG,
      HISTORY_TYPE,
      cmn_mst_time,
      historyType: null,
      placeHolderHistory: null,
      isShowSearchUserDialog: false,
      isShowSearchManagerDialog: false,
      isShowHistoryDialog: false,
      GATE_PASSAGES,
      isCopyForm: false,
      IS_CUSTOMIZE,
      MAP_FIELD_TREE_NEST_NO: [],
      isSelectPrimeCompany: false,
      currentFieldFocusPopupSearchMng: '',
      isHideEndDate: false,
    };
  },
  components: {
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
    InputTextArea,
    Popup,
    SearchUserDialog,
    SearchManagerDialog,
    HistorySelectDialog,
    SelectWithFilter
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
    roleUser: Number,
    isCopy: Boolean,
    cranePassagesCustomize: Object,
  },
  mounted() {
    this.initDataDefault();
    this.$watch(
      () => this.item,
      (data, oldData) => {
        if (data != oldData) {
          this.formValues = _.cloneDeep(data);
          this.dataUsageTime = { ...data["device_usage_times"][0] };
          this.deviceUsers = { ...data["device_users"][0] };
          if(this.formValues.use_date_from && !this.isHideEndDate){
            this.formValues.use_date_to = this.formValues.use_date_from;
          }
          if ((this.formValues['field_tree_user_id_name'] || "").length > 0) {
            this.FIELD_TREE_USER_ARRAY = [{ field_tree_user_id: null, user_name: "" }, {
              field_tree_user_id: this.formValues["field_tree_user_id"],
              user_name: this.formValues["field_tree_user_id_name"]
            }];
          }
          if ((this.deviceUsers['field_director_name'] || "").length > 0) {
            this.MANAGER_ARRAY = [{
              id: this.deviceUsers['field_director_id'],
              user_name: this.deviceUsers['field_director_name'] || ''
            }];
          }

          // if is copy then get data select 協力会社, 作業時間帯, 場所 corresponding
          if (this.isCopy && this.formValues["field_construction_id"]) {
            this.dataUsageTime = {
              usage_end_time: data.device_usage_times[0].usage_end_time,
              usage_start_time: data.device_usage_times[0].usage_start_time,
            };
            this.formValues.device_usage_times = [this.dataUsageTime];
            if (!this.formValues.gate_status_code) this.formValues.gate_status_code = 1;
            this.initData(this.formValues["field_construction_id"]);
            this.$emit('resetCopy');
            this.isCopyForm = true;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.formValues?.field_construction_id,
      (data, oldVal) => {
        if (data !== oldVal && data) {
          this.initData(this.formValues["field_construction_id"]);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.formValues?.work_hour_zone,
      (data, oldVal) => {
        if (data !== oldVal && data && this.working_time_zone.length > 1) {
          this.handleTimeZone(data);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.WORK_TYPES = [this.getDefaultArrays("id", "name"), ...newValue];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["ScheduleCrane/getWorkHouseZone"],
      (newValue) => {
        if (newValue) {
          let workingTime = this.working_time_zone.find(
            (item) => item.id == newValue
          );
          if(this.isNewItem){
            this.dataUsageTime = {
              usage_start_time: workingTime.start_time,
              usage_end_time: null,
            };
            this.formValues.device_usage_times = [this.dataUsageTime];
          }
          this.getDataUseTimes(workingTime);
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => this.working_time_zone,
      (newValue) => {
        if (Array.isArray(newValue) && newValue.length > 0) {
          let workingTime = newValue.find(
            (item) => item.id == this.formValues["work_hour_zone"]
          );
          this.getDataUseTimes(workingTime);
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (newValue) => {
        this.WORK_PLACE = [
          this.getDefaultArrays("id", "item_name"),
          ...newValue,
        ];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListSkills"],
      (data) => {
        this.JOB_ARRAY = [
          this.getDefaultArrays("category_id", "category"),
          ...data,
        ];
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Gates/getListGateByField"],
      (data) => {
        this.GATE_ARRAY = [this.getDefaultArrays("id", "name"), ...data];
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Cranes/getListCraneByField"],
      (newValue) => {
        this.CRANES_BY_FIELD = [
          this.getDefaultArrays("id", "crane_name"),
          ...newValue,
        ];
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => this.isHideEndDate,
      (data) => {
        if(!data){
          this.formValues.use_date_to = this.formValues.use_date_from
        }
      },
      {
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    enableFieldTreeId1() {
      return (
        this.roleUser == ROLE_USER[3] ||
        this.COMPANY_PARTNER_LEVEL_1_ARRAY.length > 2 ||
        !this.formValues.field_construction_id
      );
    },
    enableGroupGate() {
      return this.formValues["gate_id"] !== null;
    },
  },
  methods: {
    initData(field_construction_id) {
      if(this.working_time_zone.length == 0) {
        this.getWorkingTimeZone(field_construction_id);
      }
      this.getListItemTrees(field_construction_id);
      this.getListCompanyPartnerLevel1(field_construction_id);
    },
    initDataDefault() {
      this.getListConstruction();
      this.getListGateByField();
      this.getListCraneByField();
      this.COUNT_ARRAY = [this.getDefaultArrays("id", "name"), ...this.COUNT_ARRAY];
    },
    onInput(parentName, { name, value }) {
      if(name == "usage_start_time") {
        this.dataUsageTime = {
          usage_start_time: value,
          usage_end_time: addMinutesToTime(value)
        }
        this.formValues.device_usage_times = [this.dataUsageTime];
      }
      if (name === 'use_date_from' && !this.isHideEndDate) {
        this.formValues.use_date_to = value;
      }
      const formValues = { ...this.formValues };
      // if (parentName === GATE_PASSAGES) {
      //   formValues['gate_passages'][name] = value;
      //   if (name === 'gate_id' && !value) {
      //     formValues['gate_passages']['cmn_mst_vehicle_type_id'] = null;
      //     formValues['gate_passages']['truck_count'] = null;
      //     formValues['gate_passages']['status_code'] = 1;
      //     formValues['gate_passages']['shipping_company'] = null;
      //     formValues['gate_passages']['shipping_company_tel'] = null;
      //     formValues['gate_passages']['remarks'] = null;
      //   }
      // } else if ( name === "usage_start_time" || name === "usage_end_time") {
      if ( name === "usage_start_time" || name === "usage_end_time") {
        let item = formValues['device_usage_times'][0];
        item[name] = value;
        formValues['device_usage_times'][0] = {...item};
      } else if (name === "field_director_id") {
        let item = formValues['device_users'][0];
        item[name] = value;
        formValues["device_users"][0] = {...item};
      } else if (name === "field_construction_id") {
        formValues.field_tree_id_1 = null;
        formValues.job_id = null;
        formValues.field_item_tree_id = null;
        formValues.field_tree_user_id = null;
        formValues.field_tree_id = null;
        formValues.device_users = [{field_director_id: null}];
        this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
        this.JOB_ARRAY = [this.getDefaultArrays("category_id", "category")];
        this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name")];
        this.MANAGER_ARRAY = [this.getDefaultArrays("id", "user_name")];
        this.WORK_PLACE = [this.getDefaultArrays("id", "item_name")];
        formValues[name] = value;
      } else if (name === "work_hour_zone") {
          formValues[name] = value;
          let item = {usage_start_time: null, usage_end_time: null};
          formValues['device_usage_times'][0] = {...item};
      } else {
        if (name === "field_tree_id_1") {
          this.getListSkills(value);
          formValues.job_id = null;
          formValues.field_tree_user_id = null;
          formValues.field_tree_id = null;
          this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name")];
          if (typeof this.MAP_FIELD_TREE_NEST_NO[value] !== 'undefined' && this.MAP_FIELD_TREE_NEST_NO[value] === 0) {
            this.isSelectPrimeCompany = true;
          } else {
            this.isSelectPrimeCompany = false;
          }
        } else if (name == "job_id") {
          formValues.field_tree_user_id = null;
          formValues.field_tree_id = null;
          formValues.device_users = [{field_director_id: null}];
          this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name")];
          this.MANAGER_ARRAY = [this.getDefaultArrays("id", "user_name")];
        } else if (name === 'gate_id' && !value) {
          formValues['gate_cmn_mst_vehicle_type_id'] = null;
          formValues['gate_truck_count'] = null;
          formValues['gate_status_code'] = null;
          formValues['gate_shipping_company'] = null;
          formValues['gate_shipping_company_tel'] = null;
          formValues['commodity_type'] = null;
          formValues['gate_remarks'] = null;
        }
        formValues[name] = value;
      }
      this.$emit("formUpdate", formValues);
    },

    async getListCompanyPartnerLevel1(fieldConstructionId) {
      if (fieldConstructionId) {
        const params = { field_construction_id: fieldConstructionId, has_prime: 1, is_oba: 1 };
        // if role manage plan
        if (await this.isPlanManager(fieldConstructionId)) params.is_plan_manager = 1;
        let rs = await Store.dispatch("PortalChart/getListCompanyPartnerLevel1", { params });
        let arr = _.cloneDeep(rs.data.contents.entries);
        if (arr.length > 0) {
          var _this= this;
          arr.forEach(function(val) {
            _this.MAP_FIELD_TREE_NEST_NO[val.field_tree_id] = val.nest_no;
          });
          if (typeof _this.MAP_FIELD_TREE_NEST_NO[_this.formValues.field_tree_id_1] !== 'undefined' && _this.MAP_FIELD_TREE_NEST_NO[_this.formValues.field_tree_id_1] === 0) {
            _this.isSelectPrimeCompany = true;
          } else {
            _this.isSelectPrimeCompany = false;
          }
          this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...arr];
          const formValues = {...this.formValues};
          if (this.editable && !this.isCopyForm) {
            const isSelected = arr.find(item => {
                return item.is_selected === true
            })
            formValues.field_tree_id_1 = isSelected ? isSelected.field_tree_id : arr[0].field_tree_id;
            this.$emit("formUpdate", formValues);
          } else if (this.isCopyForm) {
            this.isCopyForm = false;
          }
          await this.getListSkills(formValues.field_tree_id_1);
        } else {
          this.MAP_FIELD_TREE_NEST_NO = [];
          this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.JOB_ARRAY = [this.getDefaultArrays("category_id", "category")];
          const formValues = { ...this.formValues };
          formValues.field_tree_id_1 = null;
          this.$emit("formUpdate", formValues);
        }
      }
    },

    async isPlanManager(field_construction_id) {
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.user_id,
        field_construction_id,
      };
      let rs = await Store.dispatch("Employees/checkIsDirector", { params });
      return rs.data.contents.entries?.is_plan_manager;
    },

    async getListSkills(id) {
      if (id) {
        let params = { field_tree_id_1: id };
        await Store.dispatch("PortalChart/getListSkills", { params });
      } else {
        this.JOB_ARRAY = [this.getDefaultArrays("category_id", "category")];
      }
    },

    async getListGateByField() {
      await Store.dispatch(
        "Gates/getListGateByField",
        this.CURRENT_SITE.field_id
      );
    },

    async getListConstruction() {
      await Store.dispatch(
        "PortalChart/getWorkTypes",
        this.CURRENT_SITE.field_id
      );
    },
    async getWorkingTimeZone(field_construction_id) {
      if (field_construction_id) {
        const rs = await Store.dispatch(
          "PortalChart/getFieldTimeZones",
          field_construction_id
        );
        if (!rs.hasError) {
          const { entries } = rs.data.contents;
          this.working_time_zone = [this.getDefaultArrays("id", "name"), ...entries];
          if (!this.formValues["work_hour_zone"]) {
            this.formValues["work_hour_zone"] = this.working_time_zone[1].id;
          }
          await Store.dispatch("ScheduleCrane/setWorkHouseZone", this.formValues["work_hour_zone"]);
        }
      } else this.working_time_zone = [this.getDefaultArrays("id", "name")];
    },
    async getListItemTrees(val) {
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val,
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      }
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    getStartDateRules() {
      return this.formValues?.use_date_to && this.isHideEndDate
        ? "required|" +
            `compare-end-date:${String(this.formValues?.use_date_to).replaceAll(
              "-",
              "/"
            )}`
        : "";
    },
    getEndDateRules() {
      return this.formValues?.use_date_from
        ? "required|" +
            `compare-start-date:${String(
              this.formValues?.use_date_from
            ).replaceAll("-", "/")}`
        : "";
    },

    getProhibitTime() {
      let rs = "";
      let item = { ...this.dataUsageTime };
      if (!this.editable && this.cmn_mst_time_start.length > 0 && this.cmn_mst_time_end.length > 0) {
        if (item.usage_start_time && item.usage_end_time) {
          let usage_start_time = this.cmn_mst_time_start.find(element => element.id == item.usage_start_time)?.name || "";
          let usage_end_time = this.cmn_mst_time_end.find(element => element.id == item.usage_end_time)?.name || "";
          rs = `${usage_start_time}～${usage_end_time}`;
        } else {
          rs = "";
        }
      }
      return rs;
    },

    getStartTimeRules() {
      let item = { ...this.dataUsageTime };
      let rule = item.usage_end_time
        ? "required|" + `compare-to-end-time:${item.usage_end_time}|` : "required";
      return rule;
    },
    getEndTimeRules() {
      let item = { ...this.dataUsageTime };
      let rule = item.usage_start_time
        ? "required|" + `compare-to-start-time:${item.usage_start_time}|` : "required";
      return rule;
    },
    handleTimeZone(work_hour_zone) {
      let workingTime = this.working_time_zone.find((item) => {
        return item.id == work_hour_zone;
      });
      this.getDataUseTimes(workingTime);
    },
    getDataUseTimes(workingTime) {
      if (workingTime) {
        let arrayStart = this.cmn_mst_time.filter((item) => {
          return item.id >= workingTime?.start_time && item.id < workingTime?.end_time;
        });
        this.time_start = [this.getDefaultArrays("id", "name"), ...arrayStart];
        let arrayEnd = this.cmn_mst_time.filter((item) => {
          return item.id > workingTime?.start_time && item.id <= workingTime?.end_time;
        });
        this.time_end = [this.getDefaultArrays("id", "name"), ...arrayEnd];
      } else {
        this.dataUsageTime = {
          usage_start_time: null,
          usage_end_time: null,
        };
        this.time_start = [this.getDefaultArrays("id", "name")];
        this.time_end = [this.getDefaultArrays("id", "name")];
      }
    },
    openHistoryPopup(type) {
      this.historyType = type;
      this.isShowHistoryDialog = true;
    },
    async getListCraneByField() {
      await Store.dispatch(
        "Cranes/getListCraneByField",
        this.CURRENT_SITE.field_id
      );
    },
    openPopUpSearchUser(){
      this.isShowSearchUserDialog = true;
    },
    openPopUpSearchManager(fieldName) {
      this.currentFieldFocusPopupSearchMng = fieldName;
      this.isShowSearchManagerDialog = true;
    },
    updateUser(item) {
      this.isShowSearchUserDialog = false;
      this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name"), ...[{ ...item }]];
      let formValues = {...this.formValues};
      formValues.field_tree_user_id = item.field_tree_user_id;
      formValues.field_tree_user_id_name = item.user_name;
      formValues.field_tree_id = item.field_tree_id;
      formValues.field_tree_company_name_1 = null;
      if (item.field_tree_user_tel) formValues.field_tree_user_tel = item.field_tree_user_tel;
      this.$emit("formUpdate", formValues);
    },
    updateManager(item) {
      this.isShowSearchManagerDialog = false;
      this.MANAGER_ARRAY = [this.getDefaultArrays("id", "user_name"), ...[{ ...item }]];
      let formValues = { ...this.formValues };
      if (this.currentFieldFocusPopupSearchMng === "field_tree_user_id") {
        formValues.field_tree_user_id = item.field_tree_user_id;
        formValues.field_tree_user_id_name = item.user_name;
        formValues.field_tree_id = item.field_tree_id;
        formValues.field_tree_company_name_1 = null;
        if (item.field_tree_user_tel) formValues.field_tree_user_tel = item.field_tree_user_tel;
      } else {
        let _item={};
        _item["field_director_id"] = item.id;
        _item["field_director_name"] = null;
        formValues["device_users"][0] = {..._item};
      }
      this.$emit("formUpdate", formValues);
    },
    updateCommentHistory(item) {
      let formValues = { ...this.formValues };
      if (this.historyType == HISTORY_TYPE.SUSPENDED_LOAD) formValues["suspended_load"] = item;
      else if (this.historyType == HISTORY_TYPE.WORK_DETAILS) formValues["work_details"] = item;
      else if (this.historyType == HISTORY_TYPE.COMMODITY_TYPE) formValues["commodity_type"] = item;
      this.$emit("formUpdate", formValues);
      this.closeHistoryDialog();
    },
    closeHistoryDialog() {
      this.historyType = null;
      this.isShowHistoryDialog = false;
    },

    /**
     * Check item requred
     */
    checkRequired(name) {
      return this.cranePassagesCustomize[name]?.required === IS_CUSTOMIZE;
    },

    /**
     * Check item show or hidden
     */
    checkShow(name) {
      return this.cranePassagesCustomize[name]?.form_view === IS_CUSTOMIZE;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";

.row-position {
  margin-top: -15px;
  height: 150px;
  position: relative;
}

.row-position-location {
  height: 90px;
  position: relative;
}

.row-position-location-v2 {
  height: 100px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}

.select-position-location {
  width: 100%;
  position: absolute;
  top: 39px;
}

.select_wrap{
  width: 100%;
  pointer-events: none;
}

.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

::v-deep .labelWrapper .label {
  white-space: nowrap !important;
}
::v-deep .labelWrapper {
  max-width: 100%;
}
@media only screen and (max-width: 1300px) {
  .FormDialog .container > .row > * > .row > .workDate {
    padding: 0px !important;
  }
  ::v-deep .workDate .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
      padding: 0px 4px;
    }
}

</style>
