<template>
  <div class="form-position-radio" :style="`height: ${mainHeight}px`">
    <v-radio-group v-if="listPosition.length" v-model="formValues.field_tree_user_details.position_id" :readonly="!editable">
      <v-row>
        <v-col cols="12" sm="6" class="pb-0 pb-sm-2">
          <v-list-item v-for="item in listPosition.slice(0, 10)" :key="item.id" :disabled="!editable">
            <v-list-item-content>
              <v-radio
                :label="item.position"
                :value="item.id"
                :ripple="false"
                @change="change"
              ></v-radio>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0 pt-sm-2">
          <v-list-item v-for="item in listPosition.slice(10)" :key="item.id" :disabled="!editable">
            <v-list-item-content>
              <v-radio
                :label="item.position"
                :value="item.id"
                :ripple="false"
                @change="change"
              ></v-radio>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    listPosition: {
      type: Array,
      default: () => []
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formValues: {
        field_tree_user_details: {
          position_id: null
        }
      },
      position_id: null
    }
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    change() {
      this.$emit('formUpdate', this.formValues)
    }
  }
}
</script>
<style scoped>
.form-position-radio {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>