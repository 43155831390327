<template>
  <!-- parent table -->
  <v-data-table
    :headers="tableLabels"
    :items="items"
    :items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    hide-default-footer
    disable-sort
    :show-select="true"
    class="elevation-0 v-data-table__wrapper"
    sort-by="updatedAt"
  >
    <template v-slot:header="{ props }">
      <th class="select-all">
        <v-simple-checkbox
        v-if="!flagNotData  && items.length && !isDisableCheckBox"
        :value="isSelectedAllParent(items)"
        @click="updateAllCheckbox(items, !isSelectedAllParent(items))"
      ></v-simple-checkbox>

      <th class="select-all">
        <v-simple-checkbox
        v-if="!flagNotData  && items.length && isDisableCheckBox"
        :value="isSelectedAllParent(items)"
      ></v-simple-checkbox>
      </th>
    </template>

    <!-- parent table template -->
    <template v-slot:body="{ items }">
      <template v-if="items && items.length > 0">
        <tbody class="tableRow" :class="{'borderRow': ENV_OBAYASHI}" v-for="item in items" :key="item.work_result_id">
          <tr :class="item.state_handling_flag == 1 ? 'grey lighten-1' : ''">
            <td class="start">
              <v-row>
                <v-col cols="12" sm="3">
                  <!-- toggle icon -->
                  <v-icon @click.stop="toggleShowChildren(item.work_result_id)">
                    {{
                      isShowChildren[item.work_result_id]
                        ? "mdi-chevron-down"
                        : "mdi-chevron-right"
                    }}
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="3">
                  <!-- checkbox -->
                  <v-simple-checkbox
                    v-if="!isTableTypeAll"
                    :value="isSelectedParent(item.work_result_id)"
                    @input="updateCheckbox(item, $event, true)"
                    :disabled="isDisableCheckBox"
                  ></v-simple-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <!-- checkbox -->
                  <v-simple-checkbox
                    v-if="!isTableTypeAll"
                    :value="isSelected(item.work_result_id)"
                    @input="updateCheckbox(item, $event, null, item)"
                    :disabled="isDisableCheckBox"
                  ></v-simple-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn small icon @click="$emit('addNewChild', item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
            <td class="col-status">
              <div
                v-for="(status, index) in approveStatus"
                :key="`status_${status}_${index}_1`"
              >
                <v-chip
                  @click="$emit('openPopupRatify', item)"
                  v-if="status.id === item.work_approval_flag"
                  class="ma-2"
                  small
                  :color="`${status.class}`"
                  dark
                  >{{ status.name }} {{getTotalApproval(item.total_approval)}}</v-chip
                >
              </div>
            </td>
            <td @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">
              {{ item.company_name }}
            </td>
            <td @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">
              {{ convertDate(item.work_date) }}
            </td>
            <td @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">{{ item.job_name }}</td>
            <td @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">
              {{ convertString(item.location_name, 16) }}
            </td>
            <td @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">
              <table>
                <tr>
                  <td>
                    <template
                      v-if="
                        SCHEDULE_WARNING.hot_work_flag.id === item.hot_work_flag
                      "
                    >
                      <div
                        :class="`warning-icon ${SCHEDULE_WARNING.hot_work_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.hot_work_flag.name }}
                      </div>
                    </template>
                    <template
                      v-if="
                        SCHEDULE_WARNING.oxygen_lack_flag.id ===
                        item.oxygen_lack_flag
                      "
                    >
                      <div
                        :class="`warning-icon ${SCHEDULE_WARNING.oxygen_lack_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.oxygen_lack_flag.name }}
                      </div>
                    </template>
                  </td>
                  <td class="content">
                    {{ item.contents }}
                  </td>
                </tr>
              </table>
            </td>
            <td v-if="ENV_OBAYASHI" @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">
              {{ item.main_contractor_user_name}}
            </td>
          <td v-if="ENV_OBAYASHI" @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)"  style="min-width: 70px">
            {{ getDayWorkerType(item.dayworker_type) }}
          </td>
            <td @click="$emit('openItemForm', item)">
                <div class="d-flex" :class="{'flex-column': ENV_OBAYASHI}">
                  <div>
                    <v-chip
                    v-if="item.early_over_type.earlytime_hour > 0"
                    class="ma-2"
                    small
                    :color="`${SCHEDULE_ATTENDANCE.early_flag.class}`"
                    dark
                    >{{
                      SCHEDULE_ATTENDANCE.early_flag.name +
                      "（" +
                      item.early_over_type.earlytime_hour +
                      " h）"
                    }}
                    </v-chip>
                  </div>
                  <div>
                    <v-chip
                    v-if="item.early_over_type.overtime_hour > 0"
                    class="ma-2"
                    small
                    :color="`${SCHEDULE_ATTENDANCE.over_flag.class}`"
                    dark
                    >{{
                      SCHEDULE_ATTENDANCE.over_flag.name +
                      "（" +
                      item.early_over_type.overtime_hour +
                      " h）"
                    }}
                    </v-chip>
                  </div>
                </div>
            </td>
            <td @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">
              <span >{{
                  item.dayworker_plan
                }}</span>
            </td>
            <td @click="$emit('openItemForm', item, true, IS_PARENT_EDIT_TABLE)">
              <span  v-if="item.dayworker_result_update_user_id!=null || item.dayworker_result_create_user_id!=null ||!ENV_OBAYASHI">{{
                  item.dayworker_result
                }}</span>
              <span  v-if="item.dayworker_result_update_user_id==null && item.dayworker_result_create_user_id==null &&ENV_OBAYASHI">--</span>
              <br/>
              <span v-if="item.total_dayworker_result != null">({{item.total_dayworker_result}})</span>
            </td>
          </tr>
          <!-- child table template -->
          <template v-for="itemChild in item.children">
            <tr
              :class="
                itemChild.state_handling_flag == 1 ? 'grey lighten-1' : ''
              "
              v-show="isShowChildren[item.work_result_id]"
              class="child-table"
              :key="itemChild.work_result_id"
            >
              <td class="start">
                <v-row v-if="!ENV_OBAYASHI">
                  <v-col cols="12" sm="3"> </v-col>
                  <v-col cols="12" sm="3"> </v-col>
                  <v-col cols="12" sm="3">
                    <v-simple-checkbox
                      v-if="!isTableTypeAll"
                      :value="isSelected(itemChild.work_result_id)"
                      @input="updateCheckbox(itemChild, $event, null, item)"
                      :disabled="isDisableCheckBox"
                    ></v-simple-checkbox>
                  </v-col>
                  <v-col cols="12" sm="3"> </v-col>
                </v-row>
                <v-row v-else justify="end">
                  <v-col cols="12" sm="3">
                    <v-simple-checkbox
                      v-if="!isTableTypeAll"
                      :value="isSelected(itemChild.work_result_id)"
                      @input="updateCheckbox(itemChild, $event, null, item)"
                      :disabled="isDisableCheckBox"
                    ></v-simple-checkbox>
                  </v-col>
                </v-row>
              </td>
              <td class="col-status">
                <div
                  v-for="(status, index) in approveStatus"
                  :key="`status_${status}_${index}_2`"
                >
                  <v-chip
                    v-if="status.id === itemChild.work_approval_flag"
                    @click="$emit('openPopupRatify', itemChild)"
                    class="ma-2"
                    small
                    :color="`${status.class}`"
                    dark
                    >{{ status.name }} {{getTotalApproval(itemChild.total_approval)}}</v-chip
                  >
                </div>
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                {{ itemChild.company_name }}
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                {{ convertDate(itemChild.work_date) }}
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                {{ itemChild.job_name }}
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                {{ convertString(itemChild.location_name, 16) }}
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                <table>
                  <tr>
                    <td>
                      <div
                        v-if="
                          SCHEDULE_WARNING.hot_work_flag.id ===
                          itemChild.hot_work_flag
                        "
                        :class="`warning-icon ${SCHEDULE_WARNING.hot_work_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.hot_work_flag.name }}
                      </div>
                      <div
                        v-if="
                          SCHEDULE_WARNING.oxygen_lack_flag.id ===
                          itemChild.oxygen_lack_flag
                        "
                        :class="`warning-icon ${SCHEDULE_WARNING.oxygen_lack_flag.class}`"
                      >
                        {{ SCHEDULE_WARNING.oxygen_lack_flag.name }}
                      </div>
                    </td>
                    <td class="content">
                      {{ itemChild.contents }}
                    </td>
                  </tr>
                </table>
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)" v-if="ENV_OBAYASHI">
              {{ itemChild.main_contractor_user_name }}
              </td>
            <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)" v-if="ENV_OBAYASHI">
              {{ getDayWorkerType(itemChild.dayworker_type) }}
            </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                <div class="d-flex" :class="{'flex-column': ENV_OBAYASHI}">
                  <div>
                    <v-chip
                    v-if="itemChild.early_over_type.earlytime_hour > 0"
                    class="ma-2"
                    small
                    :color="`${SCHEDULE_ATTENDANCE.early_flag.class}`"
                    dark
                    >{{
                      SCHEDULE_ATTENDANCE.early_flag.name +
                      "（" +
                      itemChild.early_over_type.earlytime_hour +
                      " h）"
                    }}
                    </v-chip>
                  </div>
                  <div>
                    <v-chip
                    v-if="itemChild.early_over_type.overtime_hour > 0"
                    class="ma-2"
                    small
                    :color="`${SCHEDULE_ATTENDANCE.over_flag.class}`"
                    dark
                    >{{
                      SCHEDULE_ATTENDANCE.over_flag.name +
                      "（" +
                      itemChild.early_over_type.overtime_hour +
                      " h）"
                    }}
                    </v-chip>
                  </div>
                </div>
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                <span>{{ itemChild.dayworker_plan }}</span>
              </td>
              <td @click="$emit('openItemForm', itemChild, true, IS_CHILD_EDIT_TABLE)">
                <span  v-if="itemChild.dayworker_result_update_user_id!=null || itemChild.dayworker_result_create_user_id!=null || !ENV_OBAYASHI">{{
                      itemChild.dayworker_result
                    }}</span>
                <span  v-if="itemChild.dayworker_result_update_user_id==null && itemChild.dayworker_result_create_user_id==null &&ENV_OBAYASHI">--</span>
              </td>
            </tr>
          </template>
          <tr
            v-show="
              childTableDisplayMoreNumber(
                item.total_children_item,
                item.children.length
              ) > 0
              && isShowChildren[item.work_result_id]
            "
          >
            <td colspan="8">
              <div class="d-flex justify-center">
                <v-btn
                  x-small
                  depressed
                  :disabled="isDisable"
                  class="mr-4 display-more"
                  color="#E5E5E5"
                  v-if="
                    childTableDisplayMoreNumber(
                      item.total_children_item,
                      item.children.length
                    ) > 0
                  "
                  @click="getChildren(item)"
                  >さらに表示する
                  <v-badge
                    class="display-more-number"
                    inline
                    color="#767676"
                    :content="
                      childTableDisplayMoreNumber(
                        item.total_children_item,
                        item.children.length
                      )
                    "
                  />
                </v-btn>
                <!-- <v-btn
                  class="add-new-child"
                  depressed
                  x-small
                  v-if="!isTableTypeAll"
                  @click="$emit('addNewChild', item)"
                  color="primary"
                  >作業を新規追加</v-btn
                > -->
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <div v-else-if="flagNotData" class="schedule_nodata mt-4">
        <span>{{ NO_DATA_MESSAGE }}</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import { IS_CHILD_EDIT_TABLE, IS_PARENT_EDIT_TABLE } from "@/constants/COMMON";
import {CONTRACT_CLASSIFICATION} from "@/constants/SCHEDULE_WORKS";

export default {
  data() {
    return {
      isDisable: false,
      time_out: null,
      IS_CHILD_EDIT_TABLE,
      IS_PARENT_EDIT_TABLE,
      CONTRACT_CLASSIFICATION
    };
  },
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    isTableTypeAll: Boolean,
    items: Array,
    isSelected: Function,
    isSelectedParent: Function,
    updateCheckbox: Function,
    toggleShowChildren: Function,
    childTableDisplayMoreNumber: Function,
    isShowChildren: Object,
    approveStatus: Array,
    SCHEDULE_ATTENDANCE: Object,
    SCHEDULE_WARNING: Object,
    NO_DATA_MESSAGE: String,
    flagNotData: Boolean,
    updateAllCheckbox: Function,
    isSelectedAllParent: Function,
    isDisableCheckBox: Boolean
  },
  computed: {
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    getDayWorkerType(type) {
      return type ? this.CONTRACT_CLASSIFICATION[type].name : this.CONTRACT_CLASSIFICATION[0].name
    },
    getChildren(item) {
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.$emit(
        "getChildItems",
        item.work_group_result_id,
        Math.floor((item.children.length - 3) / 5) + 2
      );
      this.updateCheckbox(item, false, true);
    },

    convertString(text, maxlength) {
      let result = "";
      if (text) {
        result = text;
        if (text.length > maxlength) {
          result = text.substring(0, maxlength) + " •••";
        }
      }
      return result;
    },

    getTotalApproval(total) {
      if (!this.ENV_OBAYASHI) return "";
      return `(${total||''})`;
    },
    convertDate(date){
      return date ? date.slice(5,10) : ""
    }
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
// ヘッダーとテーブルコンテンツのたて位置を合わせるために
// show-selectオプションをONにした状態でcheckboxを非表示
::v-deep .v-data-table-header {
  .v-input--selection-controls__input {
    display: none !important;
  }
}

td.start div.row {
  width: 120px !important;
}
td.col-status {
  width: 110px !important;
}
.tableRow {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: $color_table_border;
    z-index: 1;
  }
}
.borderRow{
  &:before {
    left: 0;
    top: 0;
    background-color: $color_table_border_customize;
  }
}

.child-table {
  td.start {
    padding-right: 0;
  }
}

.schedule_nodata {
  position: absolute;
  left: 50%;
}

button.add-new-child,
button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}
div.circle-red {
  background-color: $color_warning_dark_red;
}
div.circle-blue {
  background-color: $color_warning_blue;
}
div.warning-icon {
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  width: 25px;
  height: 25px;
  padding-top: 4px;
  margin: 3px;
}
.content {
  word-break: break-all;
}
.select-all {
  position: absolute;
  margin-top: -35px;
  margin-left: 45px ;
  z-index: 3;
}
</style>
