export const TABLE_HEADER_LABELS = [
  {
    text: "",
    value: "",
    align: "left",
    width: "20%",
  },
  {
    text: "職種",
    value: "skill",
    align: "left",
    width: "20%",
  },
  {
    text: "入場開始日",
    align: "left",
    width: "10%",
  },
  {
    text: "登録状況",
    align: "left",
    width: "10%",
  },
  {
    text: "登録日",
    align: "left",
    width: "10%",
  },
  {
    text: "顔写真",
    align: "center",
    width: "15%",
  },
  {
    text: "現場写真",
    align: "center",
    width: "15%",
  },
];

export const REGISTRATION_STATUS = {
  REGISTERED: {id: 2, name: "登録済"},
  UNREGISTERED: {id: 0, name: "未登録"}
}

export const FACE_REGISTER_STATUS = {
  REGISTERERROR2: {id: 4, name: "失敗", style: "color: #ff0000;"},
  REGISTERERROR: {id: 3, name: "失敗", style: "color: #ff0000;"},
  REGISTERED: {id: 2, name: "登録済", style: "color: #1B9C4F;"},
  REGISTERING: {id: 1, name: "登録中...", style: "color: #B00020;"},
  UNREGISTERED: {id: 0, name: "未登録", style: "color: #E5951D;"},
}

export const FACE_RECOGNITION_FLAG = {
  FACE: {id: 1},
  NO_FACE: {id: 0}
}