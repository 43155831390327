<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <SectionLabel label="日時情報" />
                  <v-row no-gutters v-if="isNewItem">
                    <v-col cols="12" sm="12" md="12">
                      <v-switch
                      v-model="isHideEndDate"
                      label="繰り返し予定を入力する"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="workDate">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（開始）" :editable="editable" required>
                            <InputDatepicker
                              name="passage_date_from"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（開始）"
                              :validation_rules="getStartDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="isHideEndDate" class="workDate">
                      <!-- 日付 -->
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="日付（終了）" :editable="editable" required>
                            <InputDatepicker
                              name="passage_date_to"
                              :values="formValues"
                              :editable="editable"
                              validation_label="日付（終了）"
                              :validation_rules="getEndDateRules()"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="作業時間帯" :editable="editable" required>
                            <Select
                              name="work_hour_zone"
                              :values="formValues"
                              :items="WORK_TIMEZONE_ARRAY"
                              :editable="editable"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <!-- 日付 -->
                          <Label label="日付" :editable="editable" required>
                            <InputDatepicker
                              name="passage_date"
                              :values="formValues"
                              :editable="editable"
                              placeholder="yyyy/mm/dd"
                              validation_label="日付"
                              validation_rules="required"
                              @onInput="onInput"
                              :flagNull="true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item :class="editable ? '' : 'mt-2'">
                        <v-list-item-content>
                          <Label label="作業時間帯" :editable="editable" required>
                            <Select
                              name="work_hour_zone"
                              :values="formValues"
                              :items="WORK_TIMEZONE_ARRAY"
                              :editable="editable"
                              validation_label="作業時間帯"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="!editable">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="時間" :editable="!editable">
                            <v-text-field
                              :label="getProhibitTime()"
                              dense
                              color="primary"
                              :disabled="!editable"
                              :filled="editable"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="開始時間" :editable="editable" required>
                            <Select
                              name="usage_start_time"
                              :values="dataUsageTime"
                              :items="cmn_mst_time_start"
                              :editable="editable"
                              validation_label="開始時間"
                              validation_rules="required"
                              autoCenter
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="終了時間" :editable="editable" required>
                            <Select
                              name="usage_end_time"
                              :values="dataUsageTime"
                              :items="cmn_mst_time_end"
                              :editable="editable"
                              validation_label="終了時間"
                              validation_rules="required"
                              autoCenter
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <SectionLabel label="会社、担当者情報" class="ml-4" />
                  <v-row no-gutters v-if="checkShow('field_tree_id_1')">
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="協力会社" :editable="editable" :class="editable && !enableFieldTreeId1 ? 'mt-2':''" :required="enableFieldTreeId1 && checkRequired('field_tree_id_1')" >
                            <SelectWithFilter
                              name="field_tree_id_1"
                              :values="formValues"
                              :editable="editable && enableFieldTreeId1"
                              :enableFieldTreeId1="enableFieldTreeId1"
                              :items="COMPANY_PARTNER_LEVEL_1_ARRAY"
                              item_value="field_tree_id"
                              item_text="company_name"
                              :label="formValues.field_tree_company_name_1 || ''"
                              validation_label="協力会社"
                              :validation_rules="enableFieldTreeId1 && checkRequired('field_tree_id_1')?'required':''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="checkShow('job_id')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="職種" :editable="editable " :required="!isSelectPrimeCompany && checkRequired('job_id') ">
                            <Select
                              name="job_id"
                              :values="formValues"
                              :items="JOB_ARRAY"
                              :editable="editable && !isSelectPrimeCompany"
                              item_text="category"
                              item_value="category_id"
                              validation_label="職種"
                              :validation_rules="!isSelectPrimeCompany && checkRequired('job_id') ?'required':''"
                              :label="formValues.job_name || ''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="checkShow('field_tree_user_id')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="担当者氏名" :editable="editable" :required="checkRequired('field_tree_user_id')">
                            <span @click="editable ? (isSelectPrimeCompany ?  openPopUpSearchManager('field_tree_user_id') : openPopUpSearchUser()) : null" class="select">
                              <span class="select_wrap">
                                <Select
                                  name="field_tree_user_id"
                                  :values="formValues"
                                  :editable="editable"
                                  :items="FIELD_TREE_USER_ARRAY"
                                  item_text="user_name"
                                  item_value="field_tree_user_id"
                                  validation_label="担当者氏名"
                                  :validation_rules="checkRequired('field_tree_user_id')?'required':''"
                                />
                              </span>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" v-if="checkShow('field_tree_user_tel')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="担当者連絡先（電話番号）" :editable="editable" :required="checkRequired('field_tree_user_tel')">
                            <InputText
                              name="field_tree_user_tel"
                              :values="formValues"
                              :editable="editable"
                              placeholder="03-1234-5678"
                              validation_label="担当者連絡先"
                              :validation_rules="checkRequired('field_tree_user_tel')?'required|phone':'phone'"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="checkShow('field_director_id')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="元請担当者" :editable="editable" :required="checkRequired('field_director_id')">
                            <span @click="editable ? openPopUpSearchManager('main_user_id') : null" class="select">
                              <span class="select_wrap">
                                <Select
                                  name="main_user_id"
                                  :items="MAIN_USER_ARRAY"
                                  :values="formValues"
                                  :editable="editable"
                                  item_text="user_name"
                                  validation_label="元請担当者"
                                  :validation_rules="checkRequired('field_director_id')?'required':''"
                                />
                              </span>
                            </span>
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <SectionLabel label="搬入出情報" />
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="ゲート" :editable="editable" required>
                            <Select
                              name="gate_id"
                              :values="formValues"
                              :items="GATE_ARRAY"
                              :editable="editable"
                              validation_label="ゲート"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="車種" :editable="editable" required>
                            <Select
                              name="cmn_mst_vehicle_type_id"
                              :values="formValues"
                              :items="VEHICLE_ARRAY"
                              :editable="editable"
                              item_text="vehicle_type"
                              validation_label="車種 "
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="台数" :editable="editable" required>
                            <Select
                              name="truck_count"
                              :values="formValues"
                              :items="COUNT_ARRAY"
                              :editable="editable"
                              validation_label="台数"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="ステータス" :editable="editable" required>
                            <Select
                              name="status_code"
                              :values="formValues"
                              :items="STATUS_CODE"
                              :editable="editable"
                              item_value="value"
                              validation_label="ステータス"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="checkShow('shipping_company')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="運送会社" :editable="editable" :required="checkRequired('shipping_company')">
                            <InputText
                              name="shipping_company"
                              :values="formValues"
                              :editable="editable"
                              validation_label="運送会社"
                              :validation_rules="checkRequired('shipping_company')?'required':''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="checkShow('shipping_company_tel')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="運送会社電話番号" :editable="editable" :required="checkRequired('shipping_company_tel')">
                            <InputText
                              name="shipping_company_tel"
                              :values="formValues"
                              :editable="editable"
                              placeholder="03-1234-5678"
                              validation_label="運送会社電話番号"
                              :validation_rules="checkRequired('shipping_company_tel')?'required|phone|max:16':'phone|max:16'"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'" v-if="checkShow('commodity_type')">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="荷種" :editable="editable" :required="checkRequired('commodity_type')">
                            <InputText
                              name="commodity_type"
                              :values="formValues"
                              :editable="editable"
                              validation_label="荷種"
                              :validation_rules="checkRequired('commodity_type') ? 'required|max:255': ''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn color="#1B9C4F" class="white--text" width="5vw" style="bottom: -45px;float: right"
                        @click="openHistoryPopup(HISTORY_TYPE.COMMODITY_TYPE)"
                      >
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('crane_id')">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="使用揚重機" :editable="editable" :required="checkRequired('crane_id')">
                            <Select
                              name="crane_id"
                              :values="formValues"
                              :items="CRANE_ARRAY"
                              :editable="editable"
                              item_text="crane_name"
                              validation_label="使用揚重機"
                              :validation_rules="checkRequired('crane_id')?'required':''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('crane_id')">
                    <v-col cols="12" :sm="editable ? '10':'12'" :md="editable ? '10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="吊荷" :editable="editable" :required="checkCraneId && checkRequired('crane_id')">
                            <InputText
                              name="crane_suspended_load"
                              :values="formValues"
                              :editable="editable && checkCraneId"
                              validation_label="吊荷"
                              :validation_rules="checkCraneId && checkRequired('crane_id')?'required|max:255':''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editable">
                      <v-btn color="#1B9C4F" class="white--text" width="5vw" :style="checkCraneId?'bottom: -45px;float: right':'bottom: -37px;float: right'"
                          :disabled="!editable || !checkCraneId || !formValues.crane_id"
                          @click="openHistoryPopup(HISTORY_TYPE.SUSPENDED_LOAD)"
                      >
                        履歴
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('crane_id')">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="場所" :editable="editable">
                            <Select
                              name="crane_field_item_tree_id"
                              :values="formValues"
                              :items="WORK_PLACE_ARRAY"
                              :editable="editable && checkCraneId"
                              item_text="item_name"
                              validation_label="場所"
                              validation_rules=""
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row no-gutters v-if="checkShow('crane_id')">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="備考" :editable="editable">
                            <InputText
                              name="crane_remarks"
                              :values="formValues"
                              :editable="editable && checkCraneId"
                              validation_label="備考"
                              :validation_rules="checkCraneId ? 'max:512':''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <Popup width="480px" :dialog="isShowSearchUserDialog">
        <SearchUserDialog
          :field_construction_id="formValues.field_construction_id"
          :field_tree_id_1="formValues.field_tree_id_1"
          :job_id="formValues.job_id"
          @close="isShowSearchUserDialog = false"
          @updateData="updateUser"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowSearchManagerDialog">
        <SearchManagerDialog
          :field_construction_id="formValues.field_construction_id"
          :job_id="formValues.job_id"
          @close="isShowSearchManagerDialog = false"
          @updateData="updateManager"
        />
      </Popup>
      <Popup width="480px" :dialog="isShowSearchCommodityTypeHistory">
        <HistoryCommodityTypeDialog
          :placeHolder="historyType===HISTORY_TYPE.COMMODITY_TYPE?'荷種':'作業詳細'"
          :historyType="historyType"
          :field_tree_id="formValues.field_tree_id"
          @close="isShowSearchCommodityTypeHistory = false"
          @updateDataHistory="updateDataHistory"
        />
      </Popup>
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import Select from "@/components/forms/elements/Select";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Popup from "@/components/common/Popup";
import { COUNT_ARRAY, STATUS_CODE } from "@/constants/SCHEDULE_GATE";
import { PROHIBIT_TIME_START, PROHIBIT_TIME_END, HISTORY_TYPE } from "@/constants/SCHEDULE_GATE";
import { ROLE_USER } from "@/constants/DEVICE_STOCK";
import _ from "lodash";
import SearchUserDialog from "../components/SearchUserDialog";
import SearchManagerDialog from "../components/SearchManagerDialog";
import HistorySearchDialog from "../components/HistorySearchDialog";
import HistoryCommodityTypeDialog from "../components/HistoryCommodityTypeDialog";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter.vue";

const IS_CUSTOMIZE = 1;
import { cmn_mst_time_start, cmn_mst_time_end } from "@/constants/CRANES";
import { addMinutesToTime } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      cmn_mst_time_start,
      cmn_mst_time_end,
      historyType: 0,
      formValues: {},
      dataUsageTime: {},
      time_start: [],
      time_end: [],
      WORKING_TIME_ZONE: Store.state.PortalChart.listTimeZones,
      workingTime: {},
      WORK_TIMEZONE_ARRAY: [],
      FIELD_CONSTRUCTION_ARRAY: [],
      COMPANY_PARTNER_LEVEL_1_ARRAY: [{ field_tree_id: null, company_name: "" }],
      GATE_ARRAY: [{ id: null, name: "" }],
      WORK_PLACE_ARRAY: [{ id: null, item_name: "" }],
      VEHICLE_ARRAY: [{ id: null, vehicle_type: "" }, ...Store.state.CmnMst.constants.entries.cmn_mst_vehicle_types],
      CRANE_ARRAY: [{ id: null, crane_name: "" }],
      JOB_ARRAY: [{ category_id: null, category: "" }],
      MAIN_USER_ARRAY: [{ id: null, user_name: "" }],
      FIELD_TREE_USER_ARRAY: [{ field_tree_user_id: null, user_name: "" }],
      COUNT_ARRAY,
      PROHIBIT_TIME_START,
      PROHIBIT_TIME_END,
      STATUS_CODE,
      isShowSearchUserDialog: false,
      isShowSearchManagerDialog: false,
      isShowSearchCommodityTypeHistory: false,
      IS_CUSTOMIZE,
      HISTORY_TYPE,
      MAP_FIELD_TREE_NEST_NO: [],
      isSelectPrimeCompany: false,
      currentFieldFocusPopupSearchMng: '',
      isHideEndDate: false,
    };
  },
  components: {
    HistorySearchDialog,
    SearchManagerDialog,
    SearchUserDialog,
    Label,
    SectionLabel,
    InputText,
    Select,
    InputDatepicker,
    InputTextArea,
    Popup,
    HistoryCommodityTypeDialog,
    SelectWithFilter
  },
  props: {
    item: Object,
    editable: {
      type: Boolean
    },
    mainHeight: {
      type: Number,
      default: 0
    },
    formUpdate: Function,
    isNewItem: Boolean,
    roleUser: Number,
    statusCopy: Boolean,
    isBackData : Boolean,
    gatePassagesCustomize: Object,
  },

  mounted() {
    this.initDataDefault();
    this.$watch(
      () => this.item,
      (data) => {
        if(data) {
          this.formValues = { ...data };
          if (Array.isArray(data?.device_usage_times) && !this.isNewItem) {
            this.dataUsageTime = { ...data?.device_usage_times[0] };
          }
          if(this.formValues.passage_date_from && !this.isHideEndDate) {
            this.formValues.passage_date_to = this.formValues.passage_date_from;
          }
          if (!this.editable) {
            this.initData(this.item);
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.formValues?.field_construction_id,
      (data, oldVal) => {
        if (data !== oldVal && data && this.editable) {
          this.initData(this.formValues);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.formValues?.work_hour_zone,
      (data, oldVal) => {
        if (data !== oldVal && data && this.WORK_TIMEZONE_ARRAY.length > 1) {
          this.handleTimeZone(data);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getWorkTypes"],
      (newValue) => {
        this.FIELD_CONSTRUCTION_ARRAY = [this.getDefaultArrays("id", "name"), ...newValue];
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.statusCopy,
      (data) => {
        if (data) {
          this.dataUsageTime = this.item.device_usage_times[0];
          this.formValues.device_usage_times = [this.dataUsageTime];
          this.initData(this.item);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => this.isBackData,
      (data) => {
        if (data) {
          this.initData(this.item);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getFieldTimeZones"],
      (data) => {
        if (this.formValues["field_construction_id"]) {
          this.WORK_TIMEZONE_ARRAY = [this.getDefaultArrays("id", "name"), ...data];
          if (this.isNewItem) {
            if (this.WORK_TIMEZONE_ARRAY.length > 0 && !this.formValues["work_hour_zone"]) {
              this.formValues["work_hour_zone"] = this.WORK_TIMEZONE_ARRAY[0].id;
            }
            this.timeZone = this.WORK_TIMEZONE_ARRAY.find((item) => {
              return item.id == this.formValues["work_hour_zone"];
            });

            this.dataUsageTime = {
              usage_start_time: this.timeZone.start_time,
              //add 15 minutes
              usage_end_time: null,
            }
            this.formValues.device_usage_times = [this.dataUsageTime]
          }
          this.handleTimeZone(this.formValues["work_hour_zone"]);
        }
      },
      {
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["Gates/getListGateByField"],
      (data) => {
        this.GATE_ARRAY = [this.getDefaultArrays("id", "name"), ...data];
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["Cranes/getListCraneByField"],
      (data) => {
        this.CRANE_ARRAY = [this.getDefaultArrays("id", "crane_name"), ...data];
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListItemTrees"],
      (newValue) => {
        this.WORK_PLACE_ARRAY = [this.getDefaultArrays("id", "item_name"), ...newValue];
      },
      {
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getListSkills"],
      (data) => {
        this.JOB_ARRAY = [this.getDefaultArrays("category_id", "category"), ...data];
      },
      {
        deep: true
      }
    );
    this.$watch(
      () => this.isHideEndDate,
      (data) => {
        if(!data){
          this.formValues.passage_date_to = this.formValues.passage_date_from
        }
      },
      {
        deep: true
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    enableFieldTreeId1() {
      return this.roleUser == ROLE_USER[3] || this.COMPANY_PARTNER_LEVEL_1_ARRAY.length > 2 || !this.formValues.field_construction_id;
    },
    checkCraneId() {
      return this.formValues?.crane_id !== null;
    }
  },
  methods: {
    initDataDefault() {
      this.getListConstruction();
      this.getListGateByField();
      this.getListCraneByField();
      this.COUNT_ARRAY = [this.getDefaultArrays("id", "name"), ...this.COUNT_ARRAY];
      this.$emit("completeLoading");
    },
    initData(data) {
      let _data = { ...data };
      if(_data.field_construction_id) {
        if (this.WORK_TIMEZONE_ARRAY.length == 0) {
          this.getWorkingTimeZone(_data.field_construction_id);
        }
        this.getListItemTrees(_data.field_construction_id);
        this.getListCompanyPartnerLevel1(_data.field_construction_id);
        if (!this.editable || this.statusCopy) {
          this.getListSkills(_data.field_tree_id_1);
          if (_data.field_tree_user_id) {
            this.FIELD_TREE_USER_ARRAY = [{
              field_tree_user_id: _data.field_tree_user_id,
              user_name: _data.field_tree_user_id_name
            }];
          }
          if (_data.device_users.length > 0) {
            this.MAIN_USER_ARRAY = [{
              id: _data.main_user_id,
              user_name: _data.device_users[0].field_director_name || ''
            }];
          }

        }
      }
      // if (this.statusCopy) {
      //   this.$emit("resetStatusCopy");
      // }
    },
    onInput({ name, value }) {
      if (name === 'passage_date_from' && !this.isHideEndDate) {
        this.formValues.passage_date_to = value;
      }
      const formValues = { ...this.formValues };
      if (name == "crane_id" || name == "crane_suspended_load" || name == "crane_field_item_tree_id" || name == "crane_remarks") {
        if (name == "crane_id" && !value) {
          formValues.crane_suspended_load = "";
          formValues.crane_field_item_tree_id = null;
          formValues.crane_remarks = "";
        }
        formValues[name] = value;
      } else {
        switch (name) {
          case "field_construction_id" :
            formValues.field_tree_id_1 = null;
            formValues.job_id = null;
            formValues.field_tree_user_id = null;
            formValues.field_tree_id = null;
            formValues.field_tree_user_tel = "";
            formValues.main_user_id = null;
            formValues.crane_field_item_tree_id = null;
            if (!value) {
              this.COMPANY_PARTNER_LEVEL_1_ARRAY = [{ field_tree_id: null, company_name: "" }];
              this.WORK_PLACE_ARRAY = [{ id: null, item_name: "" }];
            }
            this.JOB_ARRAY = [{ category_id: null, category: "" }];
            this.MAIN_USER_ARRAY = [{ id: null, user_name: "" }];
            this.FIELD_TREE_USER_ARRAY = [{ field_tree_user_id: null, user_name: "" }];
            break;
          case "field_tree_id_1":
            formValues.job_id = null;
            formValues.field_tree_user_id = null;
            formValues.field_tree_id = null;
            formValues.field_tree_user_tel = "";
            formValues.main_user_id = null;
            if (value) {
              this.getListSkills(value);
            } else {
              this.JOB_ARRAY = [{ category_id: null, category: "" }];
            }
            this.MAIN_USER_ARRAY = [{ id: null, user_name: "" }];
            this.FIELD_TREE_USER_ARRAY = [{ field_tree_user_id: null, user_name: "" }];
            if (typeof this.MAP_FIELD_TREE_NEST_NO[value] !== 'undefined' && this.MAP_FIELD_TREE_NEST_NO[value] === 0) {
              this.isSelectPrimeCompany = true;
            } else {
              this.isSelectPrimeCompany = false;
            }
            break;
          case  "job_id":
            formValues.field_tree_user_id = null;
            formValues.field_tree_id = null;
            formValues.field_tree_user_tel = "";
            formValues.main_user_id = null;
            this.MAIN_USER_ARRAY = [{ id: null, user_name: "" }];
            this.FIELD_TREE_USER_ARRAY = [{ field_tree_user_id: null, user_name: "" }];
            break;
          case "field_tree_user_id":
            formValues.field_tree_user_tel = "";
            break;
          case "work_hour_zone":
            this.dataUsageTime = {
              usage_start_time: null,
              usage_end_time: null,
            }
            formValues["device_usage_times"][0]["usage_start_time"] = null;
            formValues["device_usage_times"][0]["usage_end_time"] = null;
            break;
          case "usage_start_time":
            this.dataUsageTime = {
              usage_start_time: value,
              //add 15 minutes
              usage_end_time: addMinutesToTime(value),
            }
            formValues["device_usage_times"][0]["usage_end_time"] = addMinutesToTime(value);
            formValues["device_usage_times"][0]["usage_start_time"] = value;
            break;
          case "usage_end_time":
            formValues["device_usage_times"][0]["usage_end_time"] = value;
            break;
          default :
            break;
        }
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async getListGateByField() {
      await Store.dispatch("Gates/getListGateByField", this.CURRENT_SITE.field_id);
    },
    async getListCraneByField() {
      await Store.dispatch("Cranes/getListCraneByField", this.CURRENT_SITE.field_id);
    },
    async getListConstruction() {
      await Store.dispatch("PortalChart/getWorkTypes", this.CURRENT_SITE.field_id);
    },
    async getListCompanyPartnerLevel1(fieldConstructionId) {
      if (fieldConstructionId) {
        const params = { field_construction_id: fieldConstructionId, has_prime: 1, is_oba: 1 };
        // if role manage plan
        if (await this.isPlanManager(fieldConstructionId)) params.is_plan_manager = 1;
        let rs = await Store.dispatch("PortalChart/getListCompanyPartnerLevel1", { params });
        let arr = _.cloneDeep(rs.data.contents.entries);
        if (arr.length > 0) {
          var _this= this;
          arr.forEach(function(val) {
            _this.MAP_FIELD_TREE_NEST_NO[val.field_tree_id] = val.nest_no;
          });
          if (typeof _this.MAP_FIELD_TREE_NEST_NO[_this.formValues.field_tree_id_1] !== 'undefined' && _this.MAP_FIELD_TREE_NEST_NO[_this.formValues.field_tree_id_1] === 0) {
            _this.isSelectPrimeCompany = true;
          } else {
            _this.isSelectPrimeCompany = false;
          }

          this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name"), ...arr];
          if (this.editable && !this.formValues.field_tree_id_1) {
            const formValues = {...this.formValues};

            const isSelected = arr.find(item => {
                return item.is_selected === true
            })
            formValues.field_tree_id_1 = isSelected ? isSelected.field_tree_id : arr[0].field_tree_id;
            await this.getListSkills(formValues.field_tree_id_1);
            this.$emit("formUpdate", formValues);
          }
        } else {
          this.MAP_FIELD_TREE_NEST_NO = [];
          this.COMPANY_PARTNER_LEVEL_1_ARRAY = [this.getDefaultArrays("field_tree_id", "company_name")];
          this.JOB_ARRAY = [this.getDefaultArrays("category_id", "category")];
          const formValues = { ...this.formValues };
          formValues.field_tree_id_1 = null;
          this.$emit("formUpdate", formValues);
        }
      }
    },
    async isPlanManager(field_construction_id) {
      const params = {
        user_id: JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user.user_id,
        field_construction_id,
      };
      let rs = await Store.dispatch("Employees/checkIsDirector", { params });
      return rs.data.contents.entries?.is_plan_manager;
    },
    async getListSkills(id) {
      if (id) {
        let params = { field_tree_id_1: id };
        await Store.dispatch("PortalChart/getListSkills", { params });
      } else {
        this.JOB_ARRAY = [this.getDefaultArrays("category_id", "category")];
      }
    },
    async getWorkingTimeZone(field_construction_id) {
      if (field_construction_id) {
        await Store.dispatch("PortalChart/getFieldTimeZones", field_construction_id);
      } else {
        this.WORK_TIMEZONE_ARRAY = [this.getDefaultArrays("id", "name")];
      }
    },
    async getListItemTrees(val) {
      if (val) {
        let params = {
          field_id: this.CURRENT_SITE.field_id,
          field_construction_id: val
        };
        await Store.dispatch("PortalChart/getListItemTrees", { params });
      } else {
        this.WORK_PLACE_ARRAY = [this.getDefaultArrays("id", "item_name")];
      }
    },
    handleTimeZone(workHourZone) {
      this.timeZone = this.WORK_TIMEZONE_ARRAY.find((item) => {
        return item.id == workHourZone;
      });
      this.getDataUseTimes(this.timeZone);
    },
    getDataUseTimes(timeZone) {
      let arrayStart = this.PROHIBIT_TIME_START.filter((item) => {
        return item.id >= timeZone.start_time && item.id < timeZone.end_time;
      });
      this.time_start = [this.getDefaultArrays("id", "name"), ...arrayStart];
      let arrayEnd = this.PROHIBIT_TIME_END.filter((item) => {
        return item.id > timeZone.start_time && item.id <= timeZone.end_time;
      });
      this.time_end = [this.getDefaultArrays("id", "name"), ...arrayEnd];
    },
    getDefaultArrays(id, value) {
      let results = {};
      results[id] = null;
      results[value] = "";
      return results;
    },
    getStartDateRules() {
      return this.formValues?.passage_date_to && this.isHideEndDate ? "required|" + `compare-end-date:${String(this.formValues?.passage_date_to).replaceAll("-", "/")}` : "";
    },
    getEndDateRules() {
      return this.formValues?.passage_date_from ? "required|" + `compare-start-date:${String(this.formValues?.passage_date_from).replaceAll("-", "/")}` : "";
    },
    getStartTimeRules() {
      let item = { ...this.dataUsageTime };
      let rule = item.usage_end_time ? "required|" + `compare-to-end-time:${item.usage_end_time}|` : "required";
      return rule;
    },
    getEndTimeRules() {
      let item = { ...this.dataUsageTime };
      let rule = item.usage_start_time ? "required|" + `compare-to-start-time:${item.usage_start_time}|` : "required";
      return rule;
    },
    openPopUpSearchUser() {
      this.isShowSearchUserDialog = true;
    },
    openPopUpSearchManager(fieldName) {
      this.currentFieldFocusPopupSearchMng = fieldName;
      this.isShowSearchManagerDialog = true;
    },
    openHistoryPopup(historyType) {
      // 0: commodity_type - 荷種
      // 1: commodity_type - 吊荷
      this.historyType = historyType;
      this.isShowSearchCommodityTypeHistory = true;
    },
    updateUser(item) {
      this.isShowSearchUserDialog = false;
      let formValues = { ...this.formValues };
      formValues.field_tree_user_id = item.field_tree_user_id;
      formValues.field_tree_id = item.field_tree_id;
      this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name"), ...[{
        field_tree_user_id: item.field_tree_user_id,
        user_name: item.user_name
      }]];
      if (item.field_tree_user_tel) {
        formValues.field_tree_user_tel = item.field_tree_user_tel;
      }
      this.$emit("formUpdate", formValues);
    },
    updateManager(item) {
      this.isShowSearchManagerDialog = false;
      let formValues = { ...this.formValues };
      if (this.currentFieldFocusPopupSearchMng === "field_tree_user_id") {
        formValues.field_tree_user_id = item.field_tree_user_id;
        formValues.field_tree_id = item.field_tree_id;
        this.FIELD_TREE_USER_ARRAY = [this.getDefaultArrays("field_tree_user_id", "user_name"), ...[{
          field_tree_user_id: item.field_tree_user_id,
          user_name: item.user_name
        }]];
        if (item.field_tree_user_tel) {
          formValues.field_tree_user_tel = item.field_tree_user_tel;
        }
      } else {
        formValues.main_user_id = item.id;
        formValues.device_users = [{ field_director_id: item.id }];
        this.MAIN_USER_ARRAY = [this.getDefaultArrays("id", "user_name"), ...[{
          id: item.id,
          user_name: item.user_name
        }]];
      }
      this.$emit("formUpdate", formValues);
    },
    updateDataHistory(item) {
      this.isShowSearchCommodityTypeHistory = false;
      let formValues = { ...this.formValues };
      if (this.historyType == HISTORY_TYPE.COMMODITY_TYPE) formValues["commodity_type"] = item;
      else if (this.historyType == HISTORY_TYPE.SUSPENDED_LOAD) formValues["crane_suspended_load"] = item;
      this.historyType = null;
      this.$emit("formUpdate", formValues);
    },
    getProhibitTime() {
      let rs = "";
      let item = { ...this.dataUsageTime };
      if (!this.editable && this.cmn_mst_time_start.length > 0 && this.cmn_mst_time_end.length > 0) {
        if (item.usage_start_time && item.usage_end_time) {
          let usage_start_time = this.cmn_mst_time_start.find(element => element.id == item.usage_start_time)?.name || '';
          let usage_end_time = this.cmn_mst_time_end.find(element => element.id == item.usage_end_time)?.name || '';
          rs = `${usage_start_time}～${usage_end_time}`;
        } else {
          rs = "";
        }
      }
      return rs;
    },

    /**
     * Check item requred
     */
    checkRequired(name) {
      return this.gatePassagesCustomize[name]?.required === IS_CUSTOMIZE;
    },

    /**
     * Check item show or hidden
     */
    checkShow(name) {
      return this.gatePassagesCustomize[name]?.form_view === IS_CUSTOMIZE;
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";

.select {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.select_wrap {
  width: 100%;
  pointer-events: none;
}

::v-deep .labelWrapper .label {
  white-space: nowrap !important;
}
::v-deep .labelWrapper {
  max-width: 100%;
}
@media only screen and (max-width: 1300px) {
  .FormDialog .container > .row > * > .row > .workDate {
    padding: 0px !important;
  }
  ::v-deep .workDate .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
      padding: 0px 4px;
    }
}
</style>
