<template>
  <v-card class="dialog" style="min-width: 900px">
    <v-card-text class="body" style="height: 370px;">
      <div class="work_result "><b>契約: </b>
        {{ workResultApproval.dayworker_type != null ? CONTRACT_CLASSIFICATION.find(item => item.id == workResultApproval.dayworker_type).name : '' }}
      </div>
      <div class="work_result"><b>日付: </b> {{ workResultApproval.work_date }} </div>
      <div class="work_result"><b>人工: </b> {{ workResultApproval.dayworker_plan }} </div>
      <div class="w-100 d-flex work_result"><b style="min-width: 90px">作業内容: </b> <p class="nowrap flex-grow-1" style="height: 20px">{{ workResultApproval.contents }}</p></div>
      <v-row class="ml-1 mr-1">
        <v-col
          v-for="(item,index) in formValues"
          :key="index"
          class="d-flex child-flex custom10cols"
        >
          <div class="d-flex flex-column card-circle" :style="editableList[index] ? 'scale: 1.2':'opacity:0.5'"
              @click="changeStatusApprove(index)">
            <span class="name-user" style="height: 20px" :style="getColorName(item)">{{ item.name_sei }}</span>
            <span class="name-user" style="height: 20px" :style="getColorName(item)">{{ item.name_mei }} </span>
            <div class="circle-rafity" :style="getColorBorderAndRafity(item)">
              <span class="name-circle ml-1" v-if="item.type == '主任係員'">主任<br>係員</span>
              <span class="name-circle ml-1" v-else>{{ item.type }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="green"
        class="btn"
        @click="$emit('close')"
      >やめる</v-btn>
      <v-btn v-if="isShowButtonSubmit" color="primary" @click="updateRafity">保存</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Store } from "@/store/Store.js";
import _ from 'lodash';
import { CONTRACT_CLASSIFICATION } from '@/constants/SCHEDULE_WORKS';
export default {
  data() {
    return {
      formValues: [],
      userName: null,
      ratifyList: [],
      cancelList: [],
      userIdLogin: null,
      typeLogin: null,
      name_sei: null,
      name_mei: null,
      CONTRACT_CLASSIFICATION
    }
  },

  props: {
    values: {
      type: Array,
      default: [],
    },
    state_handling_flag: Number,
    workResultApproval: {
      type: Object,
      default: () => {
        return {
          dayworker_type: '',
          contents: '',
          work_date: '',
          dayworker_plan: '',
        }
      }
    },
  },

  computed: {
    editableList() {
      let statusList = [];
      this.formValues?.forEach(element => {
        if(element.approval_flag == 1) {
          element.permission_cancel_flag? statusList.push(true): statusList.push(false);
        } else {
          element.permission_approval_flag? statusList.push(true): statusList.push(false);
        }
      });
      return statusList;
    },

    isShowButtonSubmit(){
     return this.formValues?.some(element => (element.approval_flag == 1 && element.permission_cancel_flag == 1)
     || (element.approval_flag == 0 && element.permission_approval_flag == 1));
    }
  },

  mounted() {
    this.$watch(
      () => this.values,
      (newValue) => {
        if(newValue && this.userIdLogin){
          this.formValues = _.cloneDeep(newValue);
          let listtemp = _.cloneDeep(this.formValues);
          let indexItemFirst = 0;
          if(this.state_handling_flag){
            listtemp.forEach(e => {
              e.permission_cancel_flag = 0;
              e.permission_approval_flag = 0;
            });
            this.formValues = _.cloneDeep(listtemp);
            return;
          }
          listtemp.forEach(e => {
            if( e.level_type >= e.level_user_login &&  e.approval_flag && e.level_user_login){
              e.permission_cancel_flag = 1;
            } else {
              e.permission_cancel_flag = 0;
            }
          });
          this.typeLogin = listtemp[0]?.user_type;
          if(listtemp.find(e => e.user_id == this.userIdLogin)?.type){
            listtemp.forEach(e => {
              if(e.approval_flag === 0 && e.permission_approval_flag == 1){
                e.permission_approval_flag = 0;
                e.permission_cancel_flag = 1;
              }
            });
          } else {
            listtemp.forEach(e => {
              if(e.approval_flag == 0 && e.permission_approval_flag == 1){
                if(indexItemFirst > 0){
                  e.permission_cancel_flag = 1;
                  e.permission_approval_flag = 0;
                }
                indexItemFirst ++;
              }
            });
          }
          this.formValues = _.cloneDeep(listtemp);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Login/getUser"],
      (data) => {
        this.userName = `${data.name_sei} ${data.name_mei}`;
        this.name_sei = data.name_sei;
        this.name_mei = data.name_mei;
        this.userIdLogin = data.id;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods : {
    limitString(str, limit) {
      if (str.length > limit) {
        return str.substring(0, limit) + "...";
      }
      return str;
    },
    changeStatusApprove(index){
      let formValues = [...this.formValues];
      let indexItemFirst = 0;
      formValues.forEach((e, ind) => {
        if(ind == index && this.editableList[index]) {
          e.approval_flag = !e.approval_flag;
          e.permission_approval_flag = 1;
          e.permission_cancel_flag = 1;
          if(e.approval_flag){
            e.name_sei = this.name_sei;
            e.name_mei = this.name_mei;
            e.user_id = this.userIdLogin;
            if(!this.ratifyList.find(x => x === e.id)) {
            this.ratifyList.push(e.id);
            this.cancelList = this.cancelList.filter(el=> el != e.id);
            }
          } else {
            e.name_sei = '';
            e.name_mei = '';
            e.user_id = null;
            if(!this.cancelList.find(x => x === e.id)) {
              e.id? this.cancelList.push(e.id):'';
              this.ratifyList = this.ratifyList.filter(el=> el != e.id);
              e.id = null;
              if(formValues.filter(el => el.type == e.type && el.approval_flag == 1 && el.permission_cancel_flag == 1).length && formValues.filter(e=> e.user_id == this.userIdLogin)?.length){
                e.permission_approval_flag = 0;
              } else {
                if(formValues.filter(el=> el.user_id == this.userIdLogin)?.length) {
                  e.permission_approval_flag = 0;
                } else {
                  formValues.forEach(el => {
                    if(el.approval_flag == 0 && el.type == e.type){
                      if(indexItemFirst > 0 || (el.type != this.typeLogin && this.typeLogin)){
                        el.permission_cancel_flag = 0;
                        el.permission_approval_flag = 0;
                      } else {
                        el.permission_cancel_flag = 1;
                        el.permission_approval_flag = 1;
                      }
                      indexItemFirst ++;
                    }
                  });
                }
              }
            }
          }
        }
      })
    },
    getColorName(item) {
      return item.approval_flag == 1 ? "color:#1B9C4F" : "color:#E5951D";
    },
    getColorBorderAndRafity(item) {
      return item.approval_flag == 1 ? "border-color: #1B9C4F;color:#1B9C4F" : "border-color: #E5951D;color:#E5951D";
    },

    async updateRafity(){
      let values = {
        ratifyList: this.ratifyList,
        cancelList: this.cancelList,
      }
      this.$emit("updateRafity", values);
    }
  }
};
</script>
<style lang="scss" scoped>
.circle-rafity {
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border-style: solid;
  display: flex;
}

.card-circle {
  align-items: center;
  cursor: pointer;
}

.name-circle {
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-user {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}
.custom10cols {
  width: 10%;
  max-width: 10%;
  flex-basis: 10%;
}
.nowrap{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
   -webkit-box-orient: vertical;
}
.work_result {
  font-size: 20px;
  margin-left: 10px;
}
</style>
