const EMPLOYEE_TABLE_LABELS = [
  {
    text: "氏名",
    align: "left",
    value: "name",
  },
  { text: "役職", value: "role_name", align: "left" },
  { text: "メールアドレス", value: "email", align: "left" },
  { text: "主たる職種", value: "skills_sub_catergory", align: "left" },
  { text: "CCUS", value: "ccus_user_id", align: "left" },
  { text: "技能講習", value: "licence_name", align: "left" },
  { text: "免許", value: "licences_sub_catergory", align: "left" },
  { text: "招待", value: "invite_flg", align: "left" },
];

// ソート項目
const EMPLOYEE_SORT_ITEMS = [
  { id: "name", name: "氏名" },
  { id: "role_name", name: "役職" },
  { id: "email", name: "メールアドレス" },
  { id: "ccus_user_id", name: "CCUS登録状況" },
];

const EMPLOYEE_INITAL_ITEM = {
  users: {
    name_sei: null,
    name_mei: null,
    kana_sei: null,
    kana_mei: null,
    ccus_user_id: null,
    email: null,
    birthday: null,
    cmn_mst_gender_id: null,
    postal_code: null,
    cmn_mst_prefecture_id: null,
    city: null,
    address: null,
    tel: null,
    cmn_mst_identification_id: null,
    identification_image_name: null,
    profile_image_name: null,
    relation_full_name: null,
    relationship_with_user: null,
    relation_postal_code: null,
    relation_cmn_mst_prefecture_id: null,
    relation_city: null,
    relation_address: null,
    relation_tel: null,
    blood_pressure_max: null,
    blood_pressure_min: null,
    cmn_mst_bloodtype_id: null,
    health_examination_date: null,
    employment_start_date: null,
    confirm_code: null,
  },
  user_roles: {
    code: 1,
    role_name: "一般",
  },
  user_insurances: {
    health_insurance_status: 1,
    cmn_mst_health_insurances_id: null,
    health_insurance_company_name: null,
    health_insurance_document_name: null,
    accident_insurance_status: 1,
    cmn_mst_accident_insurances_id: null,
    accident_insurance_document_name: null,
    pension_insurance_status: 1,
    cmn_mst_annuity_insurances_id: null,
    pension_insurance_document_name: null,
    employment_insurance_status: 1,
    employment_insurance_number: null,
    cmn_mst_unemployment_insurances_id: null,
    employment_insurance_document_name: null,
    participation_construction_status: 1,
    participation_construction_number: null,
    participation_construction_document_name: null,
    participation_sme_status: 1,
    participation_sme_document_name: null,
  },
  user_health_informations: {
    general_medical_examination: [
      {
        sub_catergory_id: null,
        health_checkup_date: null,
      },
    ],
    pneumoconiosis_health_checkup: [
      {
        sub_catergory_id: null,
        health_checkup_date: null,
      },
    ],
    special_medical_examination: [
      {
        sub_catergory_id: null,
        health_checkup_date: null,
      },
      {
        sub_catergory_id: null,
        health_checkup_date: null,
      },
      {
        sub_catergory_id: null,
        health_checkup_date: null,
      },
      {
        sub_catergory_id: null,
        health_checkup_date: null,
      },
    ],
  },
  user_experiences: [
    {
      catergory_id: null,
      sub_catergory_id: null,
      experience: null,
      cmn_mst_skills_id: null,
      experience_start_date : null,
      experience_blank_period : 0
    },
  ],
  user_engineer_jobs: [
    {
      engineer_job_status: 1,
      cmn_mst_engineer_jobs_id: null,
      complete_date: null,
      expiration_date: null,
      document_name: null,
    },
  ],
  user_engineer_categories: [
    {
      catergory: null,
      sub_category: null,
      cmn_mst_date_types_id: null,
      engineer_categories_date: null,
      document_name: null,
      cmn_mst_engineer_categories_id: null,
    },
  ],
  user_licenses: [
    {
      catergory_id: null,
      sub_catergory: null,
      licence_bussiness: null,
      licence_number: null,
      cmn_mst_date_types_id: null,
      licence_date: null,
      document_name: null,
      cmn_mst_licences_id: null,
    },
  ],
  user_skill_trainings: [
    {
      cmn_mst_skill_trainings_id: null,
      cmn_mst_date_types_id: null,
      skill_training_date: null,
      document_name: null,
    },
  ],
  user_sp_trainings: [
    {
      cmn_mst_sp_trainings_id: null,
      cmn_mst_date_types_id: null,
      sp_training_date: null,
      document_name: null,
    },
  ],
  user_ex_trainings: [
    {
      cmn_mst_ex_trainings_id: null,
      cmn_mst_date_types_id: null,
      ex_training_date: null,
      document_name: null,
    },
  ],
  user_awards: [
    {
      catergory_id: null,
      sub_catergory_id: null,
      cmn_mst_awards_id: null,
      award_date: null,
      document_name: null,
    },
  ],
  user_foreigners: {
    cmn_mst_nationality_id: null,
    cmn_mst_visa_status_id: null,
    expiration_date: null,
    passport_name: null,
    resident_card_name: null,
    registration_certificate_name: null,
    employment_contract_name: null,
  },
};

const ROLE = {
  MANAGER: "管理者",
  GENERAL: "一般",
};

const STOP_USER = {
  NOSTOP: 0,
  STOP: 1,
};

const USER_EVENT_TARGET = {
  USERS: "users",
  USER_ROLES: "user_roles",
  USER_INSURANCES: "user_insurances",
  USER_HEALTH_INFORMATIONS: "user_health_informations",
  USER_EXPERIENCES: "user_experiences",
  USER_ENGINEER_JOBS:"user_engineer_jobs",
  USER_ENGINEER_CATEGORIES:"user_engineer_categories",
  USER_LICENSES:"user_licenses",
  USER_SKILL_TRAININGS:"user_skill_trainings",
  USER_SP_TRAININGS:"user_sp_trainings",
  USER_EX_TRAININGS:"user_ex_trainings",
  USER_AWARDS:"user_awards",
  USER_FOREIGNERS:"user_foreigners",
};

const INSURANCE_STATUS_2 = [
  { id: 1, name: "無", value: 1 },
  { id: 2, name: "有", value: 2 },
];

const TYPE_EXPIRATION_DATE = 1;


export {
  EMPLOYEE_TABLE_LABELS,
  EMPLOYEE_SORT_ITEMS,
  EMPLOYEE_INITAL_ITEM,
  INSURANCE_STATUS_2,
  ROLE,
  USER_EVENT_TARGET,
  STOP_USER,
  TYPE_EXPIRATION_DATE
};
