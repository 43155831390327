<template>
  <li
    class="information_row"
    :key="`info_${item.schedule_id}`"
    :index="index"
    :style="getInformationSize"
  >
    <div class="information_col">
      <v-checkbox
        class="ml-10 mt-0"
        v-model="checked"
        dense
        @change="onChange"
      ></v-checkbox>
    </div>
    <div class="information_col">
      <div class="information_approval">
        <BulletApproval :value="item.schedule_approval" />
      </div>
    </div>
    <div @click="openItemForm" class="row ml-1 h-75" v-if="checkObayashi && isGates">
        <div class="information_name-obay">{{ convertDataLong(item.company_display_name, 15) }}</div>
        <div class="infor_cmn_mst_vehicle_type-obay">{{ item.cmn_mst_vehicle_type_short_name }}</div>
        <span class="space-info" v-html="`&nbsp;/&nbsp;`"></span>
        <div class="infor_truck_count-obay">{{ item.truck_count }}台</div>
        <span class="space-info" v-html="`&nbsp;/&nbsp;`"></span>
        <div class="infor_commodity_type-obay">{{ item.commodity_type }}</div>
    </div>
    <div @click="openItemForm" class="row ml-1 h-75"  v-else-if="checkObayashi">
      <div class="information_name">{{ convertDataLong(item.company_display_name, 15) }}</div>
      <div class="infor_cmn_mst_vehicle_type">{{ item.cmn_mst_vehicle_type_id }}</div>
      <div class="infor_truck_count">{{ item.truck_count }}</div>
      <div class="infor_commodity_type">{{ convertDataLong(item.commodity_type, 5) }}</div>
      <div class="infor_user" :class="haveDataLocation?'':'width-merge-location'">{{ convertDataLong(item.foreman_name, haveDataLocation?10:45)}}</div>
    </div>
    <div @click="openItemForm" class="row ml-1 h-75" v-else>
      <div class="information_name">{{ convertDataLong(item.company_display_name, 15) }}</div>
      <div class="information_user" :class="haveDataLocation?'':'width-merge-location'">{{ convertDataLong(item.foreman_name, haveDataLocation?10:45)}}</div>
      <div class="information_location" v-if="haveDataLocation">
        {{ convertDataLong(item.children_description, 12) }}
      </div>
    </div>
  </li>
</template>
<script>
import BulletApproval from "../ButtelApproval.vue";
import { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  data: () => {
    return {
      checked: false
    };
  },
  components: {
    BulletApproval
  },
  mounted() {
    this.$watch(
      () => this.forchChecked,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.checked = newValue;
        }
      },
      {
        immediate: true
      }
    );
  },
  props: {
    item: { type: Object },
    index: { type: Number },
    forchChecked: { type: Boolean },
    cellRect: { type: Object },
    isGates: {
      type: Boolean
    },
  },
  computed: {
    // informationの高さ
    getInformationSize() {
      return `height:${this.cellRect.height}px;line-height::${this.cellRect.height}px;`;
    },
    haveDataLocation() {
      return this.item.children_description.length > 0;
    },
    checkObayashi(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    },
  },
  methods: {
    onChange(value) {
      this.checked = value;
      this.$emit("onChange", {
        schedule_id: this.item.schedule_id,
        checked: value
      });
    },
    openItemForm() {
      this.$emit("openItemForm", this.item.schedule_id);
    },
    convertDataLong(description, length) {
      if (description) {
        return description.length > length
          ? description.substring(0, length) + "•••"
          : description;
      }
      return "";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.information_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 11px;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.information_col {
  height: 32px;
}
.information_approval {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.information_name {
  font-size: 16px;
  color: $color_primary;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}
.information_name-obay {
  font-size: 16px;
  color: $color_primary;
  flex: 1;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 8px;
}
.infor_name {
  font-size: 16px;
  color: $color_primary;
  flex: 1;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}
.infor_commodity_type {
  font-size: 16px;
  color: $color_primary;
  flex: 1;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}
.infor_commodity_type-obay {
  font-size: 16px;
  color: $color_primary;
  overflow: hidden;
  flex: 1;
  max-width: 200px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 8px;
}
.infor_truck_count {
  font-size: 16px;
  color: $color_primary;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}
.infor_truck_count-obay {
  font-size: 16px;
  color: $color_primary;
  width: 44px;
  text-align: left;
}
.infor_cmn_mst_vehicle_type {
  font-size: 16px;
  color: $color_primary;
  padding:0px 10px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.infor_cmn_mst_vehicle_type-obay {
  font-size: 16px;
  color: $color_primary;
  width: 52px;
}

.space-info {
  font-size: 16px;
  color: $color_primary;
}

.infor_user {
  font-size: 16px;
  overflow: hidden;
  flex: 1;
  padding-left: 10px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $color_primary;
}
.information_user {
  font-size: 10px;
  padding-right: 5px;
  overflow: hidden;
  width: 22%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.information_location {
  font-size: 10px;
  color: $color_text_sub;
  width: 30%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}
.h-75 {
  height: 75%;
}
.width-merge-location {
  width: 52% !important;
}
</style>
