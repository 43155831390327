import Api, { Mock } from "../api";
import mock_sites from "./mock/sites.json";
import mock_sites_detail from "./mock/sites_detail.json";
import mock_document from "./mock/document.json";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/fields`;
const GET_LIST_URL = `${SITES_BASE_URL}/list`;
const DELETE_URL = `${SITES_BASE_URL}/delete`;
const GET_DETAIL_SITE_URL = `${SITES_BASE_URL}/detail`;
const EDIT_URL = `${SITES_BASE_URL}/update`;
const CREATE_URL = `${SITES_BASE_URL}/create`;
const GET_LIST_COMPANY_PARTNER = `${SITES_BASE_URL}/list_company_partner`;
const GET_LIST_COMPANY_MAIN_CONTRUCTOR_AND_PARTNER = `${SITES_BASE_URL}/list_company_main_contructor_and_partner`;

const GET_ENTER_DAY_TIME_URL = `${SITES_BASE_URL}/get_enter_day_time`;
const GET_USER_ENTERING_FIELD_URL = `${SITES_BASE_URL}/get_user_entering_field_today`;
const GET_ROLE_URL = `${SITES_BASE_URL}/role`;
const INVITE_MACHINE_RENTAL_COMPANY = `${SITES_BASE_URL}/invite`;
const DELETE_MACHINE_RENTAL_COMPANY = `${SITES_BASE_URL}/delete_rental_company`;
const URL_CHECK_USER_ROLE = `${SITES_BASE_URL}/role`;
const GET_LIST_RENTAL_COMPANY = `${SITES_BASE_URL}/get_list_rental_company`;
const GET_DOCUMENT_URL = `${SITES_BASE_URL}/get_field_document`;
const UPDATE_FIELD_DOCUMENTS_URL = `${SITES_BASE_URL}/update_field_document`;
const GET_FIELD_RULE_DOCUMENT_URL = `${SITES_BASE_URL}/get_field_rule_document`;


// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_sites,
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_SITE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_sites_detail,
  });
  Mock.onPost(EDIT_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CREATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(GET_LIST_COMPANY_PARTNER).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          field_tree_id: 1,
          company_name: "清和工業株式会社xxx株式会社"
        },
        {
          field_tree_id: 2,
          company_name: "清和工業"
        }
      ]
    },
  });
  Mock.onPost(INVITE_MACHINE_RENTAL_COMPANY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_MACHINE_RENTAL_COMPANY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(URL_CHECK_USER_ROLE)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        role: 3,
      },
    },
  });
  Mock.onGet(GET_ROLE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        role: 3
      }
    }
  });
  Mock.onGet(GET_LIST_RENTAL_COMPANY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          company_id: 1,
          company_branch_id: 1,
          name: "株式会社清和工業"
        },
        {
          company_id: 2,
          company_branch_id: 2,
          name: "株式会社田中組"
        }
      ]
    },
  });
  Mock.onGet(new RegExp(`${GET_DOCUMENT_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_document,
  });
}

export const sites = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  add: (params) => {
    return Api.post(CREATE_URL, params);
  },
  edit: async (params) => {
    return Api.post(EDIT_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_SITE_URL}`, params);
  },
  getListCompanyPartner: async (params) => {
    return Api.get(GET_LIST_COMPANY_PARTNER, params);
  },
  getListCompanyMainContructorAndPartner: async (params) => {
    return Api.get(GET_LIST_COMPANY_MAIN_CONTRUCTOR_AND_PARTNER, params);
  },
  getListCompanyRental: async (params) => {
    return Api.get(`${GET_LIST_RENTAL_COMPANY}/${params}`);
  },
  getEnterDayTime: (params) => {
    return Api.get(`${GET_ENTER_DAY_TIME_URL}/${params}`);
  },
  getUserEnteringField: (params) => {
    return Api.get(`${GET_USER_ENTERING_FIELD_URL}/${params}`);
  },
  inviteMachineRentalCompany: async (params) => {
    return Api.post(INVITE_MACHINE_RENTAL_COMPANY, params);
  },
  deleteMachineRentalCompany: async (params) => {
    return Api.post(DELETE_MACHINE_RENTAL_COMPANY, params);
  },
  checkUserRole: async (params) => {
    return Api.get(URL_CHECK_USER_ROLE, params);
  },
  getRole: async (params) => {
    return Api.get(GET_ROLE_URL, {params});
  },
  getDocument: async (params) => {
    return Api.get(`${GET_DOCUMENT_URL}/${params}`);
  },
  updateFieldDocument: async (params) => {
    return Api.post(UPDATE_FIELD_DOCUMENTS_URL, params);
  },
  getFieldRuleDocument: async (params) => {
    return Api.get(GET_FIELD_RULE_DOCUMENT_URL, params);
  }
};
