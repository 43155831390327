<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && !checkFieldTreeDate && !isEndWorked"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="onCancel"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <FormInfo
              :editable="editable"
              :mainHeight="params.mainHeight"
              :item="formValues"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FormEducation
              :editable="editable"
              :mainHeight="params.mainHeight"
              :item="formValues"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item v-if="IS_OBAYASHI">
            <FormPosition
              :editable="editable"
              :listPosition="listPosition"
              :mainHeight="params.mainHeight"
              :item="formValues"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!--
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="isShowConfirmDialog = false"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import FormInfo from "./FormInfo.vue";
import { DETAIL_USER_PARTNER, STATE_HANDLING_FLAG } from "@/constants/PORTAL_CHART.js";
import { Store } from "@/store/Store.js";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import FormEducation from "./FormEducation.vue";
import FormPosition from "./FormPosition.vue";
import _ from "lodash";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";


/**
 * (共通)
 * タイトル
 */
const TITLE = {
  NEW: "現場作業員情報",
  EDIT: "社員情報",
};

/**
 * フォームとタブの設定
 */
const FORMS = {
  BasicInfo: { id: 1, title: "基本情報" },
  SendEucation: { id: 2, title: "送り出し教育" },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      formValues: DETAIL_USER_PARTNER,
      beforeUpdateItem: null,
      isSubmitted: false,
      timeout: null,
      isEndWorked: false,
      listPosition: []
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FormInfo,
    FormEducation,
    FormPosition
  },
  props: {
    isNewItem: Boolean,
    checkFieldTreeDate: Boolean,
    state_handling_flag: Number,
    dataDetail: Object,
    IS_OBAYASHI: Boolean,
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.checkFieldTreeDate,
      (flag) => {
        if (flag) {
          this.FORMS = {
            BasicInfo: { id: 1, title: "基本情報" },
          };
          let formValues = {...this.formValues};
          formValues.field_tree_user_details = this.dataDetail;
          formValues.user_experiences = this.dataDetail['user_experiences'];
          formValues.field_tree_user_experiences = this.dataDetail['field_tree_user_experiences'];
          this.formValues = formValues;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.state_handling_flag,
      (flag) => {
        if (flag == STATE_HANDLING_FLAG.FINISHED) {
          this.FORMS = {
            BasicInfo: { id: 1, title: "基本情報" },
          };
          this.isEndWorked = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["PortalChart/getDetailUserPartner"],
      (data) => {
        if(!this.checkFieldTreeDate) {
          let _data = _.cloneDeep(data);
          if (data?.field_tree_user_details) {
            this.formValues = _data;
          } else {
            _data.field_tree_user_details.required_qualifications_confirmation = 0;
            _data.field_tree_user_details.protective_cap_flag = 0;
            _data.field_tree_user_details.safety_shoes_flag = 0;
            _data.field_tree_user_details.safety_belt_flag = 0;
            _data.field_tree_user_details.welding_helmet_flag = 0;
            _data.field_tree_user_details.welding_gloves_flag = 0;
            _data.field_tree_user_details.protective_eyewear_flag = 0;
            _data.field_tree_user_details.safety_and_health_manager_flag = 0;
            _data.field_tree_user_details.mask_with_electric_fan_flag = 0;
            _data.field_tree_user_details.earplug_flag = 0;
            _data.field_tree_user_details.reflective_vest_flag = 0;
            this.formValues = _data;
          }
          this.beforeUpdateItem = _data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["CmnMst/get"],
      (data) => {
        if (data) {
          this.listPosition = [...data.entries.cmn_mst_positions]
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
    if(this.IS_OBAYASHI) {
      FORMS.Position = { id: 3, title: "立場" }
    }
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return this.editable ? TITLE.EDIT : TITLE.NEW;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    onCancel() {
      this.isClickCancelBtn = true;
      this.isShowConfirmDialog = true;
    },

    /**
     * (共通)
     * 登録
     */
    async onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      const formValues = { ...this.formValues };
      delete formValues.field_tree_user_details["female_worker_flag"];
      delete formValues.field_tree_user_details["age_under_18_flag"];
      // add field_tree_id
      formValues.field_tree_user_details["field_tree_id"] =
        Store.state.PortalChart.selectedCompany.chartId;
      if (ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT) {
        formValues['is_oba'] = 1;
      }
      const result = await Store.dispatch(
        "PortalChart/updateDetailUserPartner",
        formValues
      );
      //レスポンスエラーがある場合はフォームを閉じない
      //エラーメッセージは、api.jsが表示
      if (result.hasError) {
        return;
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.$emit("resetForm");
        this.$emit("cancel");
      }
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.editable = false;
      } else {
        this.isShowConfirmDialog = false;
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
  },

  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
