import { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
/**
 * 早出/残業
 */
export const SCHEDULE_ATTENDANCE = {
  early_flag: { id: 1, name: "早出", class: "blue" },
  over_flag: { id: 1, name: "残業", class: "purple" },
};

/**
 * 火/酸欠
 */
export const SCHEDULE_WARNING = {
  hot_work_flag: { id: 1, name: "火", class: "circle-red" },
  oxygen_lack_flag: { id: 1, name: "酸欠", class: "circle-blue" },
};

/**
 * 承認/未承認
 */
export const SCHDULE_APPROVE = {
  APPROVED: { id: 1, name: "承認済" },
  REJECT: { id: 0, name: "未承認" },
};

/**
 * 契約
 */
export const SCHEDULE_CONTRACT = {
  UNDERTAKE: { id: 0, name: "請負" },
  REGULAR: { id: 1, name: "常傭" },
};

/**
 * 点検
 */
export const SCHEDULE_INSPECTION = {
  UNCHECKED: { id: 1, name: "未点検" },
  CHECKED: { id: 2, name: "点検済み" },
  APPROVED: { id: 3, name: "点検承認" },
  REJECT: { id: 4, name: "未承認" },
};

// テーブル設定
export const SCHDULE_TABLE_LABELS = [
  {
    text: "ステータス",
    value: "approve_name",
    align: "left",
  },
  {
    text: "協力会社",
    value: "name",
    align: "left",
  },
  {
    text: "日付",
    value: "work_date",
    align: "left",
  },
  {
    text: "職種",
    value: "job_name",
    align: "left",
  },
  {
    text: "作業場所",
    value: "children_description",
    align: "left",
  },
  {
    text: "作業内容",
    value: "descriptin",
    width: '20%',
    align: "left",
  },
  ...(process.env.VUE_APP_CLIENT == ENV_CLIENT.OBAYASHI ? [{
    text: "元請担当者",
    value: "user_name_sei",
    align: "left"
  }] : []),
  ...(process.env.VUE_APP_CLIENT == ENV_CLIENT.OBAYASHI ? [{
    text: "契約",
    value: "dayworker_type",
    align: "left"
  }] : []),
  {text: "早残", value: "attendance_name", align: "left", width: '10%',},
  {text: "人工（小計）", value: "work_time", align: "left"},
];

export const SCHEDULE_RECORDS_TABLE_LABELS = [
  {
    text: "ステータス",
    value: "approve_name",
    align: "left",
  },
  {
    text: "協力会社",
    value: "name",
    align: "left",
  },
  {
    text: "日付",
    value: "work_date",
    align: "left",
  },
  {
    text: "職種",
    value: "job_name",
    align: "left",
  },
  {
    text: "作業場所",
    value: "children_description",
    align: "left",
  },
  {
    text: "作業内容",
    value: "descriptin",
    width: '20%',
    align: "left",
  },
  ...(process.env.VUE_APP_CLIENT == ENV_CLIENT.OBAYASHI ? [{
    text: "元請担当者",
    value: "main_company_name",
    align: "left"
  }] : []),
  ...(process.env.VUE_APP_CLIENT == ENV_CLIENT.OBAYASHI ? [{
    text: "契約",
    value: "dayworker_type",
    align: "left"
  }] : []),
  {text: "早残", value: "attendance_name", align: "left"},
  {text: "予定", value: "dayworker_result", align: "left", width: '10%',},
  {text: "実績（小計）", value: "dayworker_result", align: "left"},
];

// ソート項目
export const SCHDULE_SORT_ITEMS = [
  { id: "approve_name", name: "ステータス" },
  { id: "name", name: "協力会社" },
  { id: "job_name", name: "職種" },
];

/**
 * テーブルのタブ
 */
export const SCHEDULE_TABLE_TAB_ITEMS = {
  ALL: { id: 1, name: "全体" },
  WORK_GROUP: { id: 2, name: "作業グループ" },
};

/**
 * 予定表示
 */
export const SCHEDULE_TYPE = {
  OCCUPY: { id: 1, name: "占有" },
  SPOT: { id: 2, name: "スポット" },
  POSESSION: { id: 3, name: "占有" },
  DISABLE: { id: 100, name: "禁止" },
  OVERLAP: { id: 101, name: "重複" },
};

export const DIALOG_TEXT = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "中止または未承認のものは承認解除できません。",
  REJECT_TEXT2: "承認解除してもよろしいですか。",
  REJECT_ERROR_TEXT1: "中止または未承認のものは承認解除できません。",
  REJECT_BTN_SUBMIT: "解除",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "中止または承認済みのものは承認できません。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_TEXT_OBA: "承認確認",
  APPROVE_TEXT1_OBA: "中止または人工未入力のものは承認されません。",
  APPROVE_TEXT2_OBA: "承認してもよろしいですか。",
  APPROVE_TEXT3_OBA: "承認されたものは編集できません。",
  APPROVE_BTN_SUBMIT: "承認",
  ALL_APPROVE_TTTLE: "承認確認",
  ALL_APPROVE_TEXT: "中止または承認済みのものは承認できません。",
  BTN_OK: "OK",
};

export const DIALOG_TEXT_RECORDS = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "中止または未承認のものは承認解除できません。",
  REJECT_TEXT2: "承認解除してもよろしいですか。",
  REJECT_ERROR_TEXT1: "中止または未承認のものは承認解除できません。",
  REJECT_BTN_SUBMIT: "解除",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "中止または承認済みのものは承認できません。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_TEXT_OBA: "承認確認",
  APPROVE_TEXT1_OBA: "中止または人工未入力のものは承認されません。",
  APPROVE_TEXT2_OBA: "承認してもよろしいですか。",
  APPROVE_TEXT3_OBA: "承認されたものは編集できません。",
  APPROVE_BTN_SUBMIT: "承認",
  ALL_APPROVE_TTTLE: "承認確認",
  ALL_APPROVE_TEXT: "中止または承認済みのものは承認できません。",
  BTN_OK: "OK",
};

export const DIALOG_REMOVE_TEXT = {
  TITLE: "削除確認",
  TEXT_ALL_APPROVE_1: "選択された作業予定は全て承認済みです。",
  TEXT_ALL_APPROVE_2: "削除できません。",
  TEXT_ALL_APPROVE_RECORDS: "選択された作業実績は全て承認済みです。",
  BTN_OK: "OK",
  TEXT_1: "選択したデータを削除します。",
  TEXT_2: "未承認のデータのみ削除できます。",
  TEXT_3: "この処理を元に戻すことはできません。",
  TEXT_4: "本当に削除してもよろしいですか？",
  BTN_SUBMIT: "削除",
};

export const USER_TYPE = {
  TYPE_1: "1", // 元請担当者
  TYPE_2: "2", // 火元責任者
  TYPE_3: "3", // 火気使用責任者
  TYPE_4: "4", // 酸素欠乏危険作業主任者
  TYPE_5: "5", // 特別教育修了者
};

export const SCHEDULE_FLAG = {
  RECORD: 0, // 作業実績
  WORK: 1, // 作業予定
};

import { format } from "date-fns";

export const SCHEDULE_WORKS_INITIAL_ITEM_OBAYASHI = {
  work_group_plans: {
    field_construction_id: null,
    work_date_start: null,
    work_date_end: null,
    work_hour_zone: null,
  },
  work_plans: {
    field_tree_id_1: null,
    field_tree_id: null,
    field_item_tree_id: null,
    job_id: null,
    work_user_plans_manager_id: null,
    field_tree_user_id: null,
    hot_work_flag: 0,
    oxygen_lack_flag: 0,
    display_fifteen_minute_flg: 0,
    work_details: null,
    dayworker_type: 0,
    woman: 0,
    non_japanese: 0,
    predicted_risk: null,
    create_user: undefined,
    created_at: undefined,
    update_user: undefined,
    updated_at: undefined,
    contents: null,
    remarks: null
  },
  work_keyplan_image_plans: [
    {
      image: null,
    },
  ],
  work_classification_plans: [],
  work_safety_instruction_plans: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],

  work_user_plans: {
    "1": [
      {
        user_id: null,
        used_type: 1,
      },
    ],
    "2": [
      {
        user_id: null,
        used_type: 2,
      },
    ],
    "3": [
      {
        user_id: null,
        used_type: 3,
      },
    ],
    "4": [
      {
        user_id: null,
        used_type: 4,
      },
      {
        user_id: null,
        used_type: 4,
      },
    ],
    "5": [
      {
        user_id: null,
        used_type: 5,
      },
      {
        user_id: null,
        used_type: 5,
      },
    ],
  },
  work_dayworker_plans: [
    {
      working_temp_person: 1,
      working_temp_hour: "1.00",
      dayworker: 0,
    },
  ],
  work_early_over_time_plans: [
    {
      early_over_type: 0,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
    {
      early_over_type: 1,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
  ],
  work_hot_plans: [
    {
      cmn_mst_fire_purpose_use_id: null,
      cmn_mst_fire_type_id: null,
      cmn_mst_fire_management_method_id: null,
      hot_work_start: null,
      hot_work_end: null,
    },
  ],
  work_predicted_risk_plans: [
    {
      predicted_risk: null,
      cmn_mst_predicted_risk_magnitude_id: null,
      cmn_mst_predicted_risk_frequency_id: null,
    }
  ],
  work_qualifications: []
};


export const SCHEDULE_WORKS_INITIAL_ITEM = {
  work_group_plans: {
    field_construction_id: null,
    work_date_start: null,
    work_date_end: null,
    work_hour_zone: null,
  },
  work_plans: {
    field_tree_group_id: null,
    field_tree_id: null,
    foreman_user_id: null,
    field_item_tree_id: null,
    hot_work_flag: 0,
    oxygen_lack_flag: 0,
    display_fifteen_minute_flg: 0,
    work_details: null,
    dayworker_type: 0,
    woman: 0,
    non_japanese: 0,
    predicted_risk: null,
    create_user: undefined,
    created_at: undefined,
    update_user: undefined,
    updated_at: undefined,
    contents: null,
    remarks: null
  },
  work_keyplan_image_plans: [
    {
      image: null,
    },
  ],
  work_classification_plans: [
    // {
    //   cmn_mst_work_classification_id: 1,
    // },
  ],
  work_safety_instruction_plans: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],

  work_user_plans: {
    "1": [
      {
        user_id: null,
        used_type: 1,
      },
    ],
    "2": [
      {
        user_id: null,
        used_type: 2,
      },
    ],
    "3": [
      {
        user_id: null,
        used_type: 3,
      },
    ],
    "4": [
      {
        user_id: null,
        used_type: 4,
      },
      {
        user_id: null,
        used_type: 4,
      },
    ],
    "5": [
      {
        user_id: null,
        used_type: 5,
      },
      {
        user_id: null,
        used_type: 5,
      },
    ],
  },
  work_dayworker_plans: [
    {
      working_temp_person: 1,
      working_temp_hour: "1.00",
      dayworker: 0,
    },
  ],
  work_early_over_time_plans: [
    {
      early_over_type: 0,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
    {
      early_over_type: 1,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
  ],
  work_hot_plans: [
    {
      cmn_mst_fire_purpose_use_id: null,
      cmn_mst_fire_type_id: null,
      cmn_mst_fire_management_method_id: null,
      hot_work_start: null,
      hot_work_end: null,
    },
  ],
  work_predicted_risk_plans: [
    {
      predicted_risk: null,
      cmn_mst_predicted_risk_magnitude_id: null,
      cmn_mst_predicted_risk_frequency_id: null,
    }
  ],
};


export const SCHEDULE_RECORDS_INITIAL_ITEM = {
  work_group_results: {
    field_construction_id: null,
    work_date_start: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
    work_date_end: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
    work_hour_zone: null,
  },
  work_results: {
    field_construction_id: null,
    field_tree_group_id: null,
    field_tree_id_1: null,
    field_tree_id: null,
    foreman_user_id: null,
    work_hour_zone: null,
    field_item_tree_id: null,
    hot_work_flag: 0,
    oxygen_lack_flag: 0,
    display_fifteen_minute_flg: 0,
    work_details: null,
    dayworker_type: 0,
    woman: 0,
    non_japanese: 0,
    predicted_risk: null,
    create_user: undefined,
    created_at: undefined,
    update_user: undefined,
    updated_at: undefined,
    contents: null,
    remarks: null
  },
  work_keyplan_image_results: [
    {
      image: null,
    },
  ],
  work_classification_results: [
    // {
    //   cmn_mst_work_classification_id: 1,
    // },
  ],
  work_safety_instruction_results: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],

  work_user_results: {
    "1": [
      {
        user_id: null,
        used_type: 1,
      },
    ],
    "2": [
      {
        user_id: null,
        used_type: 2,
      },
    ],
    "3": [
      {
        user_id: null,
        used_type: 3,
      },
    ],
    "4": [
      {
        user_id: null,
        used_type: 4,
      },
      {
        user_id: null,
        used_type: 4,
      },
    ],
    "5": [
      {
        user_id: null,
        used_type: 5,
      },
      {
        user_id: null,
        used_type: 5,
      },
    ],
  },
  work_dayworker_results: [
    {
      working_temp_person: 1,
      working_temp_hour: "1.00",
      dayworker: 0,
    },
  ],
  work_early_over_time_results: [
    {
      early_over_type: 0,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
    {
      early_over_type: 1,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
  ],
  work_hot_results: [
    {
      cmn_mst_fire_purpose_use_id: null,
      cmn_mst_fire_type_id: null,
      cmn_mst_fire_management_method_id: null,
      hot_work_start: null,
      hot_work_end: null,
    },
  ],
  work_predicted_risk_results: [
    {
      predicted_risk: null,
      cmn_mst_predicted_risk_magnitude_id: null,
      cmn_mst_predicted_risk_frequency_id: null,
    }
  ],
};

export const SCHEDULE_RECORDS_INITIAL_ITEM_OBAYASHI = {
  work_group_results: {
    field_construction_id: null,
    work_date_start: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
    work_date_end: format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),
    work_hour_zone: null,
  },
  work_results: {
    field_construction_id: null,
    field_tree_id_1: null,
    field_tree_id: null,
    job_id: null,
    work_user_results_manager_id: null,
    field_tree_user_id: null,
    work_hour_zone: null,
    field_item_tree_id: null,
    hot_work_flag: 0,
    oxygen_lack_flag: 0,
    display_fifteen_minute_flg: 0,
    work_details: null,
    dayworker_type: 0,
    woman: 0,
    non_japanese: 0,
    predicted_risk: null,
    create_user: undefined,
    created_at: undefined,
    update_user: undefined,
    updated_at: undefined,
    contents: null,
    remarks: null
  },
  work_keyplan_image_results: [
    {
      image: null,
    },
  ],
  work_classification_results: [
    // {
    //   cmn_mst_work_classification_id: 1,
    // },
  ],
  work_safety_instruction_results: [
    {
      cmn_mst_safety_instruction_id: null,
    },
  ],

  work_user_results: {
    "1": [
      {
        user_id: null,
        used_type: 1,
      },
    ],
    "2": [
      {
        user_id: null,
        used_type: 2,
      },
    ],
    "3": [
      {
        user_id: null,
        used_type: 3,
      },
    ],
    "4": [
      {
        user_id: null,
        used_type: 4,
      },
      {
        user_id: null,
        used_type: 4,
      },
    ],
    "5": [
      {
        user_id: null,
        used_type: 5,
      },
      {
        user_id: null,
        used_type: 5,
      },
    ],
  },
  work_dayworker_results: [
    {
      working_temp_person: 1,
      working_temp_hour: "1.00",
      dayworker: 0,
    },
  ],
  work_early_over_time_results: [
    {
      early_over_type: 0,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
    {
      early_over_type: 1,
      working_temp_person: 0,
      working_temp_hour: "0.00",
      earlytime_end: null,
      overtime_start: null,
    },
  ],
  work_hot_results: [
    {
      cmn_mst_fire_purpose_use_id: null,
      cmn_mst_fire_type_id: null,
      cmn_mst_fire_management_method_id: null,
      hot_work_start: null,
      hot_work_end: null,
    },
  ],
  work_predicted_risk_results: [
    {
      predicted_risk: null,
      cmn_mst_predicted_risk_magnitude_id: null,
      cmn_mst_predicted_risk_frequency_id: null,
    }
  ],
};

export const CONTRACT_CLASSIFICATION = [
  { id: 0, name: "請負" },
  { id: 1, name: "常傭" },
];

const createNumberOfPeople = () => {
  let result = [];
  for (let i = 0; i <= 200; i++) {
    result.push({ id: i, name: i });
  }
  return result;
};

export const NUMBER_OF_PEOPLE = createNumberOfPeople();
const createNumberOfPeopleNinzu = () => {
  let result = [];
  for (let i = 1; i <= 200; i++) {
    result.push({ id: i, name: i });
  }
  return result;
};

export const NUMBER_OF_PEOPLE_NINZU = createNumberOfPeopleNinzu();

const createNumberOfDay = () => {
  let result = [];
  for (let i = 0; i <= 24; i += 0.25) {
    result.push({ id: `${Number(i).toFixed(2)}`, name: Number(i).toFixed(2) });
  }
  return result;
};

export const NUMBER_OF_DAYS = createNumberOfDay();

const createNumberWorkTimes = () => {
  let result = [];
  for (let i = 0; i <= 1; i += 0.25) {
    result.push({ id: `${Number(i).toFixed(2)}`, name: Number(i).toFixed(2) });
  }
  return result;
};

export const NUMBER_OF_TIMES = createNumberWorkTimes();

export const time_convert = (num) => {
  var hours = Math.floor(num / 60);
  var minutes = num % 60;
  minutes = (minutes == 0) ? (minutes + "0") : minutes;
  return hours + ":" + minutes;
};

const MINS_ON_HOUR = ["00", "15", "30", "45"];
export const getProhibitTimes = (isAll, isEnd) => {
  let prohibitTimes = [{ id: null, name: "" }];
  let i = 0;
  while (i < 24) {
    for (let m of MINS_ON_HOUR) {
      if (isEnd && i === 0 && m === "00") {
        continue;
      }
      let hour = i < 10 ? "0" + i : i;
      let element = {
        id: hour + ":" + m,
        name: hour + ":" + m,
      };
      prohibitTimes.push(element);
    }
    i++;
  }
  if (isEnd || isAll) {
    prohibitTimes.push({ id: "24:00", name: "24:00" });
  }
  return prohibitTimes;
};

export const cmn_mst_time_start = getProhibitTimes(false, false);
export const cmn_mst_time_end = getProhibitTimes(false, true);

export const HISTORY_TYPE = {
  contents: 0,
  safety_instructions: 1
};
