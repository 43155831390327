<template>
  <div>
    <v-container>
      <v-list-item v-if="!editable">
        <v-list-item-content>
          <v-col cols="12" sm="3" md="3"><span>人数</span></v-col>
          <v-col cols="12" sm="3" md="3"><span>日数</span></v-col>
          <v-col cols="12" sm="6" md="6"><span>合計</span></v-col>
        </v-list-item-content>
      </v-list-item>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col cols="12" sm="3" md="3">
            <v-list-item>
              <v-list-item-content>
                <Label :label="editable ? '人数' : ''" :editable="editable" required>
                  <Select
                    name="working_temp_person"
                    :values="item"
                    :items="NUMBER_OF_PEOPLE_NINZU"
                    :editable="editable"
                    validation_label="人数"
                    validation_rules="required"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-list-item>
              <v-list-item-content>
                <Label
                  :label="editable ? '日数' : ''"
                  :editable="editable"
                  v-if="display_fifteen_minute_flg == 0"
                  required
                >
                  <Select
                    name="working_temp_hour"
                    :values="item"
                    :items="NUMBER_OF_TIMES"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
                <Label
                  :label="editable ? '時間' : ''"
                  :editable="editable"
                  v-if="display_fifteen_minute_flg == 1"
                  required
                >
                  <Select
                    name="working_temp_hour"
                    :values="item"
                    :items="NUMBER_OF_DAYS"
                    :editable="editable"
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="5" md="5" :class="editable ? 'mt-2' : ''">
            <v-list-item>
              <v-list-item-content>
                <Label label="" :editable="editable">
                  <InputText
                    name="dayworker"
                    :values="item"
                    :editable="false"
                    placeholder=""
                    @onInput="onInput"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            cols="12"
            sm="1"
            md="1"
            class="pt-8 pb-0"
            v-if="editable && index == 0"
          >
            <v-btn icon @click="addEmpty"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="1"
            md="1"
            class="pt-8 pb-0"
            v-if="index >= row && editable"
          >
            <v-btn icon @click="remove(index)"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>
/**
 * これは可変長な入力フォームのサンプルです。
 * キー名や必要なフォーム要素は共通化できないので、必要に応じて作成します。
 */

import Label from "../../../elements/Label.vue";
import InputText from "../../../elements/InputText.vue";
import Select from "../../../elements/Select.vue";
import { Store } from "@/store/Store.js";
import { NUMBER_OF_PEOPLE,NUMBER_OF_TIMES,NUMBER_OF_PEOPLE_NINZU } from "@/constants/SCHEDULE_WORKS";
//初期値を設定します
const INITIAL_VALUES = {
  working_temp_person:1,
  working_temp_hour: "1.00",
  dayworker: 0,
};

export default {
  data: () => {
    return {
      items: [],
      formValues: {},
      NUMBER_OF_PEOPLE,
      NUMBER_OF_PEOPLE_NINZU,
      NUMBER_OF_DAYS: [],
      NUMBER_OF_TIMES,
      display_fifteen_minute_flg: 0,
      dataTimeMax: Store.state.PortalChart.workingTime,
    };
  },
  components: {
    Label,
    InputText,
    Select,
  },
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    selectItems: {
      type: Array,
    },
    row: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.initData();
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        if (newValue[0][newValue[1]]?.length == 0) {
          this.addEmpty();
        }
        if (newValue[0][newValue[1]]?.length) {
          this.items = newValue[0][newValue[1]];
          this.display_fifteen_minute_flg =
            newValue[1] == "work_dayworker_plans"
              ? newValue[0]?.work_plans?.display_fifteen_minute_flg
              : newValue[0]?.work_results?.display_fifteen_minute_flg;
          this.items.forEach(item => {
            this.calculate(item);
          })
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      // 必ずコピーして変更をします
      const itemEdited = { ...this.items[index] };
      itemEdited[value.name] = value.value;
      const items = [...this.items];
      this.calculate(itemEdited);
      items[index] = itemEdited;
      this.$emit("onInput", { name: this.name, value: items });
    },
    remove(index) {
      const items = this.items.filter((item, i) => {
        return index != i;
      });
      this.$emit("onInput", { name: this.name, value: items });
    },
    addEmpty() {
      const items = [...this.items];
      const initial_values = { ...INITIAL_VALUES };
      if (this.display_fifteen_minute_flg == 1)
        initial_values.working_temp_hour = this.dataTimeMax;
      items.push({ ...initial_values });
      this.items = items;
      this.$emit("onInput", { name: this.name, value: items });
    },
    initData() {
      for (let i = 0; i <= this.dataTimeMax; i += 0.25) {
        this.NUMBER_OF_DAYS.push({
          id: `${Number(i).toFixed(2)}`,
          name: Number(i).toFixed(2),
        });
      }
    },
    calculate(itemEdited) {
      let dayworker = 0;
      if (
        this.display_fifteen_minute_flg == 0 &&
        itemEdited.working_temp_person > 0
      ) {
        dayworker =
          itemEdited.working_temp_person * itemEdited.working_temp_hour;
      } else if (
        this.display_fifteen_minute_flg == 1 &&
        itemEdited.working_temp_person > 0
      ) {
        dayworker =
          (itemEdited.working_temp_person * itemEdited.working_temp_hour) /
          this.dataTimeMax;
      }
      if (isNaN(dayworker)) {
        itemEdited.dayworker = 0;
      } else {
        itemEdited.dayworker =
          itemEdited.working_temp_person != 0
            ? `${parseFloat(Number(dayworker).toFixed(this.display_fifteen_minute_flg == 0 ? 2 : 5)) + 0}`
            : 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/forms.scss";
.work-dayworker-results .v-list-item {
  padding: 0 8px;
}
.work_dayworker_plans .v-list-item {
  padding: 0 6px;
}
</style>
