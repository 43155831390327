<template>
  <div style="position: relative;">
    <v-row>
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        style="padding-left: 0px; padding-right: 0px !important "
      >
        <v-data-table
          :headers="REMARKS_LABELS"
          :items="items"
          hide-default-footer
          disable-sort
          :items-per-page="itemsPerPage"
          class="elevation-0 v-data-table__wrapper schedule_hidescroll"
          noDataText=""
          outlined
          item-key="field_tree_id"
        >
          <template v-slot:body="{ items }">
            <template
              style="align-items: center"
              v-if="items && items.length > 0"
            >
              <tbody v-for="item in items" :key="item.field_tree_id">
                <tr style="background-color: #dcdcdc;">
                  <td >
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <!-- toggle icon -->
                        <v-icon
                          @click.stop="toggleShowChildren(item.field_tree_id)"
                        >
                          {{
                            isShowChildren[item.field_tree_id]
                              ? "mdi-chevron-down"
                              : "mdi-chevron-right"
                          }}
                        </v-icon>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-simple-checkbox
                          v-show="!IS_OBAYASHI"
                          :value="isSelectedParent(item.field_tree_id) && checkSelectedParents(item)"
                          @input="updateCheckbox(item, $event, true)"
                        ></v-simple-checkbox>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-company-name">
                      <div style="height: 15px"></div>
                      {{ item.company_name }}
                  </td>
                  <td></td>
                </tr>
                <!-- child table template -->
                <template>
                  <tr
                    v-show="isShowChildren[item.field_tree_id]"
                    v-for="itemChild in item.users"
                    :key="itemChild.user_id"
                    class="td-bg pointer"
                  > 
                    <td class="center" style="max-width:50px">
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-simple-checkbox
                            v-show="!IS_OBAYASHI"
                            :value="isSelected(item.field_tree_id,itemChild.user_id)"
                            @input="updateCheckbox(itemChild, $event, null, item)"
                          ></v-simple-checkbox>
                        </v-col>
                      </v-row>
                    </td>
                    <td @click="$emit('openItemForm', itemChild, item.field_tree_id)">
                      <v-row>
                        <v-col cols="12" sm="7" md="9">
                          <div class="text-user-name">
                          {{ itemChild.user_name }}
                          </div>
                        </v-col>
                      <v-col cols="12" sm="5" md="3">
                      <v-chip
                        v-show="!IS_OBAYASHI"
                        style="min-width: 55px;"
                        small
                        :color="`${
                          itemChild.approval_flag == 1 ? 'primary' : 'warning'
                        }`"
                        dark
                      >
                        {{ convertApprovalName(itemChild.approval_flag) }}
                      </v-chip>
                      </v-col>
                      </v-row>
                    </td>
                    <td class="center" @click="$emit('openItemForm', itemChild, item.field_tree_id, item.company_name)" style="color:green">
                      {{ itemChild.total ? itemChild.total : '0h'}}
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="6"
        lg="8"
        style="padding-left: 0px !important ; padding-right: 0px"
      >
        <v-data-table
          :headers="tableLabels"
          :items="items"
          hide-default-footer
          disable-sort
          :items-per-page="itemsPerPage"
          class="elevation-0 v-data-table__wrapper"
          noDataText="データがありません。"
          outlined
          item-key="field_tree_id"
        >
          <template v-slot:body="{ items }">
            <template
              style="align-items: center"
              v-if="items && items.length > 0"
            >
              <tbody v-for="item in items" :key="item.field_tree_id">
                <!-- label parent -->
                <tr style="background-color: #dcdcdc">
                  <td>
                    <v-row> </v-row>
                  </td>
                  <td v-for="index in tableLabels.length - 1" :key="index"></td>
                </tr>
                <!-- child table template -->
                <template>
                  <tr
                    v-show="isShowChildren[item.field_tree_id]"
                    v-for="itemChild in item.users"
                    :key="itemChild.user_id"
                    class="td-bg"
                  >
                    <td
                      class="center"
                      v-for="(item,index) in itemChild.childrens"
                      :key="index"
                      :style="item.invalid_data_flag == 1 ? 'color:red' : ''"
                    >
                      {{ item.time ? item.time : '0h'}}
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="table_footer" v-if="isShowChildren[item.field_tree_id] && item.field_tree_id">
      <v-btn
        v-if="
          item.total_children_item > 3 &&
            childTableDisplayMoreNumber(
              item.total_children_item,
              item.users.length
            ) > 0
        "
        x-small
        depressed
        class="mr-4 display-more"
        color="#E5E5E5"
        :disabled="isDisable"
        @click="getChildren(item)"
        >さらに表示する
        <v-badge
          class="display-more-number"
          inline
          color="#767676"
          :content="
            childTableDisplayMoreNumber(
              item.total_children_item,
              item.users.length
            )
          "
        />
      </v-btn>
    </div>
  </div>
    
</template>
<script>
import { APPROVAL_STATUS , REMARKS_LABELS } from "@/constants/ENTRANCE_RECORD";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";

export default {
  data() {
    return {
      isShowChildren : {},
      items : [],
      APPROVAL_STATUS,
      isDisable: false,
      time_out: null,
      REMARKS_LABELS
    };
  },
  props: {
    tableLabels: Array,
    itemsPerPage: Number,
    tableHeight: Number,
    item : Object,
    isSelectedParent : Function,
    isSelected: Function,
    updateCheckbox: Function,
    checkSelectedParents:Function
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        let items = [];
        items.push(data);
        this.items = [...items];
        this.initIsShowChildren(items);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    }
  },
  methods: {
    getChildren(item) {
      this.isDisable = true;
      // Re-enable after submit
      this.time_out = setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      this.$emit(
        "getChildItems",
        item.field_tree_id,
        Math.floor((item.users.length - 3) / 5) + 2
      );
    },
    childTableDisplayMoreNumber(totalNum, displayNum) {
      const _number = totalNum - displayNum;
      return _number > 0 ? _number : "0";
    },

    initIsShowChildren(items) {
      let _isShowChildren = {};
      items.forEach((item) => {
        _isShowChildren[item.field_tree_id] = true;
      });
      this.isShowChildren = _isShowChildren;
    },

    toggleShowChildren(id) {
      this.isShowChildren[id] = !this.isShowChildren[id];
    },

    convertApprovalName(flag) {
      if (flag === APPROVAL_STATUS[1].approval_status) {
        return APPROVAL_STATUS[1].approval_name;
      }
      return APPROVAL_STATUS[0].approval_name;
    }
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.time_out);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

button.display-more {
  height: 20px !important;
}

.display-more-number ::v-deep .v-badge__badge {
  font-size: 10px;
}

.display-more-number ::v-deep .v-badge__badge {
  padding: 1px;
  height: 14px;
}

.center {
  text-align: center;
  vertical-align: middle;
}

.table {
  width: 100%;
  height: auto;
  display: flex;
}

.table_footer {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.td-bg:hover{
  background: white !important; 
}
.pointer {
  cursor: pointer;
}

.text-user-name {
  max-width: 15vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-company-name {
  position: absolute;
  max-width: 85vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}
</style>
