import { format } from "date-fns";
const date = new Date();
const yesterday = date.setDate(date.getDate() -1);
const ENTRANCE_RECORD_INITAL_ITEM = {
  start_date: format(yesterday, "yyyy/MM/dd"),
  end_date: format(yesterday, "yyyy/MM/dd"),
  field_construction_id: null,
  field_tree_id: null,
  user_ids: [],
  group_id: null,
  field_entrance_times: []
};
const SWITCH_APPROVAL_STATUS = {
  APPROVED: { id: 1, name: "承認済" },
  REJECT: { id: 0, name: "未承認" },
};

const MODE_ACTION = {
  APPROVED: 1,
  REJECT: 0,
  DELETE: 2,
  APPROVE_ALL : 3
};

const REMARKS_LABELS = [
  {
    text: "",
    value: "",
    align: "center",
    width: "50px",
  },
  {
    text: "",
    value: "",
    align: "center",
    width: "250px",
  },
  {
    text: "合計時間",
    value: "",
    align: "center",
    width: "100px",
  },
];

// テーブル設定
const ENTRANCE_RECORD_TABLE_LABELS = [
  {
    text: "",
    value: "approval_status",
    align: "center",
    width: "10%",
  },
  {
    text: "",
    value: "admission_date",
    align: "center",
    width: "5%",
  },
  {
    text: "承認者",
    value: "approval_user",
    align: "center",
    width: "17%",
  },
  {
    text: "編集者",
    value: "update_user",
    align: "center",
    width: "18%",
  },
  {
    text: "合計時間",
    value: "date_total_worktime",
    align: "center",
    width: "10%",
  },
  {
    text: "",
    value: "invalid_data_flag",
    align: "center",
    width: "10%",
  },
  {
    text: "時間",
    value: "total",
    align: "center",
    width: "10%",
  },
  { text: "入場", value: "admission", align: "center", width: "10%" },
  { text: "退場", value: "edit", align: "center", width: "10%" },
];
// テーブル設定
const ENTRANCE_RECORD_TABLE_LABELS_OBAYASHI = [
  {
    text: "",
    value: "approval_status",
    align: "center",
    width: "10%",
  },
  {
    text: "",
    value: "admission_date",
    align: "center",
    width: "5%",
  },
  {
    text: "",
    value: "approval_user",
    align: "center",
    width: "12%",
  },
  {
    text: "編集者",
    value: "update_user",
    align: "center",
    width: "13%",
  },
  {
    text: "合計時間",
    value: "date_total_worktime",
    align: "center",
    width: "10%",
  },
  {
    text: "CCUS連携結果",
    value: "career_link_ccus_flg",
    align: "center",
    width: "10%",
  },
  {
    text: "",
    value: "invalid_data_flag",
    align: "center",
    width: "10%",
  },
  {
    text: "時間",
    value: "total",
    align: "center",
    width: "10%",
  },
  { 
    text: "入場", 
    value: "admission", 
    align: "center", 
    width: "10%" 
  },
  { 
    text: "退場", 
    value: "edit", 
    align: "center", 
    width: "10%" 
  },
];
const APPROVAL_STATUS = [
  {
    approval_status: 0,
    approval_name: "未承認",
  },
  {
    approval_status: 1,
    approval_name: "承認済",
  },
];

const ADMINSSION_TIME_TYPE = [
  {
    id: 1,
    name: "スマホ顔認証",
  },
  {
    id: 2,
    name: "手入力",
  },
];

const EXIT_TIME_TYPE = [
  {
    id: 1,
    name: "スマホ顔認証",
  },
  {
    id: 2,
    name: "手入力",
  },
  { 
    id: 3,
    name: "強制退場"
  },
  { 
    id: 4,
    name: "キャリアリンク"
  }
];


const getCounts = (max) => {
  let i = 0;
  return [...new Array(max)].map(() => {
    let el = {
      id: i < 10 ? "0" + i : "" + i,
      name: i < 10 ? "0" + i : "" + i,
    };
    i++;
    return el;
  });
};

const HOURS_ARRAY = getCounts(48);
const MINS_ARRAY = getCounts(60);

const DIALOG_TEXT = {
  REJECT_TITLE: "承認解除確認",
  REJECT_TEXT1: "選択した入退場実績の承認を解除します。",
  REJECT_TEXT2: "承認を解除してもよろしいですか。",
  REJECT_ERROR_TEXT1: "選択したデータは全て承認されていないため、承認解除出来ません。",
  REJECT_BTN_SUBMIT: "解除",
  APPROVE_TITLE: "承認確認",
  APPROVE_TEXT1: "選択した入退場実績を承認します。",
  APPROVE_TEXT2: "承認してもよろしいですか。",
  APPROVE_BTN_SUBMIT: "承認",
  ALL_APPROVE_TTTLE: "承認確認",
  ALL_APPROVE_TEXT: "選択された入退場実績は全て承認済みです。",
  BTN_OK: "OK",
};

const DIALOG_REMOVE_TEXT = {
  TITLE: "削除確認",
  TEXT_ALL_APPROVE_1: "選択された入退場実績は全て承認済みです。",
  TEXT_ALL_APPROVE_2: "削除できません。",
  BTN_OK: "OK",
  TEXT_1: "選択したデータを削除します。",
  TEXT_2: "未承認のデータのみ削除できます。",
  TEXT_3: "この処理を元に戻すことはできません。",
  TEXT_4: "本当に削除してもよろしいですか？",
  BTN_SUBMIT: "削除",
};

const DIALOG_ALL_APPROVAL_TEXT = {
  TITLE: "一括承認確認",
  TEXT_ALL_APPROVE: "選択された入退場実績は全て承認済みです。",
  BTN_OK: "OK",
  TEXT_1: "一覧にある全ての入退場実績を承認します。",
  TEXT_2: "承認してもよろしいですか。",
  BTN_SUBMIT: "承認",
};

const DIALOG_NOT_IN_HISTORY_TEXT = {
  TITLE: "警告",
  TEXT: "入退場実績がないため、操作することができません。",
};
const CCUS_LINK_STATUS = {
  REGISTERERROR2: {id: 4, name: "失敗", style: "color: #ff0000;"},
  REGISTERERROR: {id: 3, name: "失敗", style: "color: #ff0000;"},
  REGISTERED: {id: 2, name: "成功", style: "color: #1B9C4F;"},
  REGISTERING: {id: 1, name: "登録中...", style: "color: #B00020;"},
  UNREGISTERED: {id: 0, name: "", style: "color: #E5951D;"},
};

export {
  ENTRANCE_RECORD_INITAL_ITEM,
  ENTRANCE_RECORD_TABLE_LABELS,
  ENTRANCE_RECORD_TABLE_LABELS_OBAYASHI,
  APPROVAL_STATUS,
  ADMINSSION_TIME_TYPE,
  EXIT_TIME_TYPE,
  HOURS_ARRAY,
  MINS_ARRAY,
  DIALOG_TEXT,
  DIALOG_REMOVE_TEXT,
  DIALOG_ALL_APPROVAL_TEXT,
  REMARKS_LABELS,
  SWITCH_APPROVAL_STATUS,
  MODE_ACTION,
  DIALOG_NOT_IN_HISTORY_TEXT,
  CCUS_LINK_STATUS
};
