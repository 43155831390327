<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-data-table
        :headers="tableLabels"
        :items="items"
        :height="mainHeight - 10"
        :items-per-page="items.length"
        fixed-header
        hide-default-footer
        disable-sort
        :show-select="false"
        class="elevation-0 v-data-table__wrapper"
      >
        <!-- parent table template -->
        <template v-slot:body="{ items }">
          <tr class="header">
            <td colspan="3" v-if="!ENV_OBAYASHI">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="2" md="2" style="padding-right: 0px;text-align-last: left">
                  <!-- checkbox -->
                  <v-simple-checkbox
                    :value="forchChecked"
                    @input="onChangeForceChecked">
                  </v-simple-checkbox>
                </v-col>
                <v-col cols="12" sm="10" md="10">
                  <div class="text_parent_content">{{ content }}</div>
                </v-col>
              </v-row>
            </td>
            <td colspan="4"  v-if="ENV_OBAYASHI">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="2" md="2" style="padding-right: 0px;text-align-last: left">
                </v-col>
                <v-col cols="12" sm="10" md="10">
                  <div class="text_parent_content">{{ content }}</div>
                </v-col>
              </v-row>
            </td>
            <td colspan="4"></td>
            <td>
              <v-btn
                v-if="editable && !ENV_OBAYASHI"
                depressed
                small
                outlined
                @click="handleBack()"
                color="primary"
              >
                やめる
              </v-btn>
            </td>
            <td>
              <v-btn
                v-if="!editable && !ENV_OBAYASHI"
                depressed
                small
                color="primary"
                @click="onEditable()"
              >
                編集
              </v-btn>
              <v-btn
              v-if="editable && !ENV_OBAYASHI"
                depressed
                small
                color="primary"
                :disabled="!valid"
                @click="onSubmit()"
              >
                保存
              </v-btn>
            </td>
          </tr>
          <template v-if="items && items.length > 0">
            <tr v-for="(item, index) in items" :key="index">
              <!-- parent -->
              <td class="border_bottom" v-if="item.field_entrance_history_id">
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <!-- checkbox -->
                    <v-simple-checkbox
                    v-show="!ENV_OBAYASHI"
                      class="ml-1"
                      :value="isSelected(item.field_entrance_history_id)"
                      @input="onChange(item.field_entrance_history_id, $event)">
                    </v-simple-checkbox>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" v-show="!ENV_OBAYASHI">
                    <v-chip
                      style="display: flex; align-items: center; justify-content: center; width: fit-content"
                      small
                      :color="`${item.approval_status == 1 ? 'primary' : 'warning'}`"
                      dark
                      >{{ APPROVAL_STATUS.find(element => element.approval_status == item.approval_status).approval_name }}</v-chip>
                  </v-col>
                </v-row>
              </td>
              <td class="border_bottom" style="padding-left: 5px;" v-if="item.field_entrance_history_id"> {{ item.admission_date }} </td>
              <td class="border_bottom" style="padding-left: 5px;" v-if="item.field_entrance_history_id"> <div class="center_text"> <div class="text_child_content">{{ item.approval_user }} </div></div></td>
              <td class="border_bottom" style="padding-left: 5px;" v-if="item.field_entrance_history_id"><div class="center_text"><div class="text_child_content"> {{ item.update_user }}</div></div> </td>
              <td class="border_bottom" v-if="item.field_entrance_history_id"> {{ item.date_total_worktime }} </td>
              <td class="border_bottom" v-if="item.field_entrance_history_id && !ENV_OBAYASHI" colspan="4"></td>
              <td class="border_bottom" v-if="item.field_entrance_history_id && ENV_OBAYASHI" colspan="5"></td>
              <!-- user time -->
              <td v-if="!item.field_entrance_history_id" colspan="5"></td>
              <td class="border_bottom" v-if="!item.field_entrance_history_id && ENV_OBAYASHI" :style="getColor(item.career_link_ccus_flg)">
                <template>
                  <span v-if="item.career_link_ccus_flg != 3">
                    {{ convertStatusText(item.career_link_ccus_flg)}}
                  </span>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ convertStatusText(item.career_link_ccus_flg)}}
                      </span>
                    </template>
                    <span v-html="item.career_link_response_error.replaceAll(',','<br/>').replaceAll('、','<br/>')"></span>
                  </v-tooltip>
                </template>
              </td>
              <td class="border_bottom" v-if="!item.field_entrance_history_id">
                <MarkValid v-if="item.invalid_data_flag == 0" />
                <MarkInValid v-else />
              </td>
              <td class="border_bottom" v-if="!item.field_entrance_history_id"> {{ item.total }} </td>
              <td class="border_bottom" v-if="!item.field_entrance_history_id">
                <v-row v-if="editable" class="mt-4">
                  <v-list-item>
                    <v-list-item-content>
                      <InputText
                        name="admission_time"
                        placeholder="HH:mm"
                        :values="item"
                        :validation_label="`入場時間 [${index}]`"
                        :validation_rules="`required-admission-time|time-format|${getTimeRules(item)}|${getStartHour(item,index)}`"
                        :editable="editable && !item.approval_status"
                        @onInput="onInput({ index, value: $event })"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
                <Label 
                  v-if="!editable && item.admission_time" 
                  :label="item.admission_time_type"
                  :warning="checkWarning(item.admission_time_type)"
                  > 
                  {{ item.admission_time }}
                </Label>
              </td>
              <td class="border_bottom ml-2" v-if="!item.field_entrance_history_id">
                <v-row v-if="editable" class="mt-4">
                  <v-list-item>
                    <v-list-item-content>
                      <InputText
                        name="exit_time"
                        placeholder="HH:mm"
                        :values="item"
                        :validation_label="`退場時間 [${index}]`"
                        :validation_rules="`required-exit-time|time-format|${getTimeRules(item)}|${getEndHour(item,index)}`"
                        :editable="editable && !item.approval_status"
                        @onInput="onInput({ index, value: $event })"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
                <Label 
                  v-if="!editable && item.exit_time" 
                  :label="item.exit_time_type"
                  :warning="checkWarning(item.exit_time_type)"
                  > 
                  {{ item.exit_time }}
                </Label>
              </td>
            </tr>
          </template>
          <div v-else-if="isNoDataMessage" class="schedule_nodata mt-4">
            <span>{{ NO_DATA_MESSAGE }}</span>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "../../elements/InputText.vue";
import MarkValid from "./components/MarkValid.vue";
import MarkInValid from "./components/MarkInValid.vue";
import { APPROVAL_STATUS, CCUS_LINK_STATUS } from "@/constants/ENTRANCE_RECORD";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";


export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      APPROVAL_STATUS,
      forchChecked: false,
      listEdit: [],
      timeValidate: {
        start: null,
        end: null
      }
    };
  },
  components: {
    Label,
    InputText,
    MarkValid,
    MarkInValid,
  },
  props: {
    valid: Boolean,
    content: String,
    items: Array,
    tableLabels: Array,
    enterDayTime: String,
    selectedList: {
      type: Array,
      default: [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNoDataMessage: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.enterDayTime,
      (newValue) => {
        if(newValue) {
          this.caculateHourArray(newValue);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.selectedList,
      (newValue) => {
        if(newValue) {
          if(newValue && newValue.length < this.listParents.length) {
            this.forchChecked = false;
          }
          if(newValue && newValue.length > 0 && newValue.length == this.listParents.length) {
            this.forchChecked = true;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    CCUS_LINK_STATUS() {
      return Object.keys(CCUS_LINK_STATUS).map((key) => {
        return CCUS_LINK_STATUS[key];
      });
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px`;
      return style;
    },
    listParents() {
      return this.items.filter(item => typeof(item.field_entrance_history_id) != "undefined").map(item => item.field_entrance_history_id);
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    }
  },
  methods: {
    convertStatusText(face_reco_status) {
      if(!face_reco_status) face_reco_status = 0;
      const status = this.CCUS_LINK_STATUS.find(item => item.id === face_reco_status);
      return status?.name;
    },
    getColor(face_reco_status) {
      if(!face_reco_status) face_reco_status = 0;
      const status = this.CCUS_LINK_STATUS.find(item => item.id === face_reco_status);
      return status?.style;
    },
    isSelected(id) {
      return this.selectedList.includes(id);
    },

    handleBack() {
      this.$emit("handleBack");
    },

    onEditable() {
      this.$emit("onEditable");
    },

    onSubmit() {
      this.$emit("onSubmit");
    },

    caculateHourArray(enterDayTime){
      const start = enterDayTime.split(":")[0];
      const end = Number(start) + 24 + "";
      this.timeValidate = {
        start: enterDayTime,
        end: end + ":" + enterDayTime.split(":")[1]
      }
    },

    onChangeForceChecked(value) {
      this.forchChecked = value;
      this.$emit("onChangeForceChecked", value);
    },

    onChange(id, value) {
      this.$emit("onChange", id, value);
    },

    onInput({ index, value }) {
      let _items = [...this.items];
      if(value.value.match(/^([0-4][0-9])[0-5][0-9]$/)) {
        _items[index][value.name] = value.value.substring(0, 2)+ ":" + value.value.substring(2);
      } else {
        _items[index][value.name] = value.value;
      }
      const listEdit = [...this.listEdit];
      listEdit.push(_items[index].field_entrance_time_id);
      this.listEdit = [...new Set(listEdit)];
      this.$emit("formUpdate", _items);
    },

    getTimeRules(item) {
      let rule = "";
      if(item.admission_time && item.exit_time) {
        rule = `compare-entrance-time:${item.admission_time},${item.exit_time}`;
      }
      return rule;
    },

    getStartHour(item, index) {
      let results = "";
      if (this.items[index - 1]?.exit_time) {
        results = `${results}|compare-entrance-record-end-date-text:${this.items[index - 1].exit_time}`;
      }
      if(item.admission_time) results = `${results}|check-start-hour:${item.admission_time},${this.timeValidate.start},${this.timeValidate.end}`;
      return results;
    },

    getEndHour(item, index) {
      let results = "";
      if (this.items[index + 1]?.admission_time) {
        results = `${results}|compare-entrance-record-start-date-text:${this.items[index + 1].admission_time}`;
      }
      if(item.exit_time) results = `${results}|check-end-hour:${item.exit_time},${this.timeValidate.start},${this.timeValidate.end}`;
      return results;
    },

    checkWarning(text) {
      return text == '手入力' || text == '強制退場';
    }

  },
};
</script>
<style lang="scss" scoped>
.header {
  background-color: #D3D3D3;
  height: 45px;
}
.schedule_nodata {
  position: absolute;
  left: 50%;
}

td {
  height: 40px;
  text-align: center;
  vertical-align: middle;
}

.border_bottom {
  border-bottom: 1px solid #E8E8E8;
}

.text_parent_content{
  text-overflow:ellipsis;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
}

.text_child_content{
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
}
.center_text{
  display: flex;
  justify-content: center;
}
::v-deep .v-chip__content {
  justify-content: center;
}
::v-deep .label {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
